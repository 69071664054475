import { createReducer, on } from '@ngrx/store';

import { ThemingConfig } from '@app/data/models';
import * as themingActions from './theming.actions';
import { defaultSiloLogoPath } from '@app/core/constants/defaultValues';

export interface ThemingState {
    themingConfig?: ThemingConfig;
    themeName?: string;
    isInitialThemeSet: boolean;
}

const initialThemingState: ThemingState = { isInitialThemeSet: false };

export const themingReducer = createReducer(
    initialThemingState,
    on(themingActions.setThemeName, (state, { themeName }): ThemingState => ({ ...state, themeName })),
    on(themingActions.getThemingConfigSuccess, (state, { themingConfig }): ThemingState => {
        const newThemingConfig = { defaultSiloLogoPath, ...themingConfig };
        return {
            ...state,
            themingConfig: newThemingConfig,
            isInitialThemeSet: true,
        };
    }),
);
