<div class="modal-component">
    <div class="modal-header">
        @if (failureMessage) {
            <div class="failure_alert_container">
                @if (failureMessage) {
                    <app-alert (closed)="failureMessage = null">{{ failureMessage }}</app-alert>
                }
            </div>
        }
        <div class="header_details">
            <div class="title">Add post comment</div>
        </div>
    </div>

    <div class="modal-body">
        <div class="info">
            <form novalidate #createChannelForm="ngForm" id="createChannel_form">
                <mat-form-field class="form-group app-mat-form-field" appearance="outline">
                    <mat-label>Comment text *</mat-label>
                    <textarea
                        class="category_name"
                        type="text"
                        placeholder="Write a comment"
                        name="name"
                        rows="5"
                        [ngClass]="{ error: !comment.valid && (comment.touched || formChecked) }"
                        [(ngModel)]="postComment"
                        #comment="ngModel"
                        matInput
                    ></textarea>
                    @if (!comment.valid && formChecked) {
                        <mat-error class="error-msg"> Comment is required </mat-error>
                    }
                </mat-form-field>
                <hr />
            </form>

            <div class="form_btns">
                <button class="cancel-button btn" [mat-dialog-close]="false" mat-flat-button>Cancel</button>
                <button class="btn primary_btn" (click)="save()" mat-raised-button>Save</button>
            </div>
        </div>
    </div>
</div>
