import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChannelService } from '@app/data/services/channel.service';

@Component({
    selector: 'app-newsletter-preview-dialog',
    templateUrl: './newsletter-preview-dialog.component.html',
    styleUrls: ['./newsletter-preview-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class NewsletterPreviewDialogComponent implements OnInit {
    clearHtml = signal<SafeHtml>(null);

    constructor(
        private channelService: ChannelService,
        private sanitizer: DomSanitizer,
        private dialogRef: MatDialogRef<NewsletterPreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { channelId: string },
    ) {}

    ngOnInit() {
        this.getNewsLetter();
    }

    getNewsLetter(): void {
        this.channelService.getNewsLetter(this.data.channelId).subscribe({
            next: (data) => {
                this.clearHtml.set(this.sanitizer.bypassSecurityTrustHtml(data.data));
            },
        });
    }

    close() {
        this.dialogRef.close();
    }
}
