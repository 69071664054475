<div>
    <button
        class="primary_btn btn"
        [ngClass]="class"
        [attr.data-testid]="dataTestid"
        type="{{ type }}"
        (click)="errorScrollService.scrollToFirstInvalidControl(form, nativeElement)"
        mat-raised-button="mat-raised-button"
        [disabled]="isSubmitting"
    >
        @if (isSubmitting) {
            <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
        } @else {
            <span>{{ buttonText }}</span>
        }
    </button>
</div>
