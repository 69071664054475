<div class="content">
    <div class="header">
        <span>Send newsletter?</span>
    </div>

    <div class="buttons-container">
        <button class="ms-button" [mat-dialog-close]="false" mat-flat-button>Cancel</button>
        <button class="ms-button" [mat-dialog-close]="true" mat-raised-button color="primary">Send</button>
    </div>
</div>
