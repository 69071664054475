import { Component, computed, inject } from '@angular/core';
import { AiTemplateConfig, ModalType } from '../ai-analytics.interfaces';
import { AIAnalyticsService } from '../ai-analytics.service';
import { AIEvaluationStore } from '../analytics-modals-template/ai-modals-template.store';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-score-evaluation',
    templateUrl: './score-evaluation.component.html',
    styleUrls: ['./score-evaluation.component.scss'],
    providers: [
        {
            provide: AIAnalyticsService,
            useFactory: () => new AIAnalyticsService(ModalType.SCORE),
        },
        AIEvaluationStore,
        DecimalPipe,
    ],
    standalone: false,
})
export class ScoreEvaluationComponent {
    private store = inject(AIEvaluationStore);
    private decimalPipe = inject(DecimalPipe);

    readonly templateConfig = computed<AiTemplateConfig>(() => {
        const reportTypes = this.store.$reportTypes();
        const formattedCreditRate = this.decimalPipe.transform(reportTypes.risk_evaluation.credit_rate, '1.0-2');
        return {
            staticText: {
                title: 'Risk evaluation',
                actionDescription: `Clicking evaluate below will automatically assign risk scores on included posts (up to 
                <b>${reportTypes.risk_evaluation.max_posts}</b> posts). Each post processed will spend <b>${formattedCreditRate}</b> credits from your account. 
                <br>Once the button is clicked the risks will be generated and assigned as manual scores within 5 minutes. This
                will override previously assigned manual scores. Note: This is an alpha version of this feature`,
                submitButton: 'Evaluate',
                confirmation: `The generated risk scores will be assigned within 5 minutes. You will be notified via mail upon
                completion.`,
            },
            submitTrackingName: 'evaluate-risk',
            maxPosts: reportTypes.risk_evaluation.max_posts,
            creditRate: reportTypes.risk_evaluation.credit_rate,
        };
    });
}
