@if (postData.ai_score != null && scoreWording$ | async; as scoreWording) {
    <div class="details-row">
        <span class="label section-header"> AI {{ scoreWording.word }} details: </span>
        <span class="edit" (click)="openAiDialog()">Edit</span>
    </div>
    @if (postData.ai_score.entity; as entity) {
        <div class="details-row">
            <span class="label"> Entity: </span>
            <span>{{ entity }}</span>
        </div>
    }
    @if (postData.ai_score.description; as description) {
        <div class="details-row">
            <span class="label"> Description: </span>
            <span>{{ description }}</span>
        </div>
    }
    @if (postData.ai_score.source_of_score; as source_of_score) {
        <div class="details-row">
            <span class="label"> Source: </span>
            <span>{{ source_of_score }}</span>
        </div>
    }
    @if (postData.ai_score.category; as category) {
        <div class="details-row">
            <span class="label"> Category: </span>
            <span>{{ category }}</span>
        </div>
    }
}
