import { Component, Input } from '@angular/core';
import { DateService } from '@app/core/services';

@Component({
    selector: 'app-news-post',
    templateUrl: './news-post.component.html',
    styleUrls: ['../../../reader-modal/reader-modal.component.scss', './news-post.component.scss'],
    providers: [DateService],
    standalone: false,
})
export class NewsPostComponent {
    @Input() selectedPostReader;

    constructor(public dateService: DateService) {}
}
