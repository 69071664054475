import { Component, inject, Input } from '@angular/core';
import { DateService } from '@app/core/services';

@Component({
    selector: 'app-forum-header',
    templateUrl: './forum-header.component.html',
    styleUrls: ['./forum-header.component.scss'],
    standalone: false,
})
export class ForumHeaderComponent {
    @Input() post;
    @Input() channel;
    @Input() curatorMode;
    @Input() mode;
    @Input() isActiveSearch;
    @Input() flags;
    @Input() hasAdminRights;

    readonly dateService = inject(DateService);
}
