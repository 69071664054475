<div class="search_help_component modal-component">
    <div class="header">
        <div class="button-clear">
            <button class="close_modal" aria-label="Close" mat-icon-button mat-dialog-close="">
                <mat-icon class="size-auto"><i class="fal fa-times"></i></mat-icon>
                esc
            </button>
        </div>
    </div>

    <div class="content_wrapper">
        <ng-container *msLoadingSpinner="isLoading; diameter: 30">
            <markdown [data]="markdown"></markdown>
        </ng-container>
    </div>
</div>
