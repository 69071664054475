<div class="modal-component">
    <div class="modal-header">
        @if (failureMessage) {
            <div class="failure_alert_container">
                @if (failureMessage) {
                    <app-alert (closed)="failureMessage = null">{{ failureMessage }}</app-alert>
                }
            </div>
        }
        <div class="header_details">
            <div class="title">{{ title }}</div>
        </div>
    </div>

    <div class="modal-body">
        <div class="info">
            <form novalidate class="score-form">
                <app-score-filter [withLabels]="true" [formControl]="scoreControl"></app-score-filter>
            </form>

            <div class="form_btns">
                <button class="cancel-button btn" [mat-dialog-close]="false" mat-flat-button>Cancel</button>
                <button
                    class="btn primary_btn"
                    (click)="save()"
                    mat-raised-button
                    [disabled]="initialScoreValue === scoreControl.value || isSubmitting"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
