import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../core/services/utils.service';

@Pipe({
    name: 'truncate',
    standalone: false,
})
export class TruncatePipe implements PipeTransform {
    constructor(private utilsService: UtilsService) {}
    transform(value: string, maxLength = 48): string {
        return this.utilsService.truncateString(value, maxLength);
    }
}
