import { Component, inject, Input } from '@angular/core';
import { DateService } from '@app/core/services';
import { Channel, Post } from '@app/data/models';

@Component({
    selector: 'app-breach-header',
    templateUrl: './breach-header.component.html',
    styleUrls: ['./breach-header.component.scss'],
    standalone: false,
})
export class BreachHeaderComponent {
    @Input() post: Post;
    @Input() channel: Channel;
    @Input() curatorMode: boolean;
    @Input() reader: boolean;

    readonly dateService = inject(DateService);
}
