import { Component, Input, inject } from '@angular/core';
import { DateService } from '@app/core/services';
import { LeaksViewPost, Post, Channel } from '@app/data/models';

@Component({
    selector: 'app-leaks-header',
    templateUrl: './leaks-header.component.html',
    styleUrls: ['./leaks-header.component.scss'],
    standalone: false,
})
export class LeaksHeaderComponent {
    @Input() set post(post: Post) {
        this.leaksViewPost = post as any as LeaksViewPost;
    }
    @Input() channel: Channel;
    @Input() curatorMode: boolean;
    @Input() reader: boolean;

    leaksViewPost: LeaksViewPost;

    public dateService = inject(DateService);
}
