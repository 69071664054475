@if (!(post.provider && post.provider == 'lab')) {
    <div class="post_brief_actions">
        <div class="dates_actions">
            <div class="action_btns post-actions">
                <ng-container [formGroup]="form">
                    @if (post.data.id && (!post.provider || post.provider == 'internal')) {
                        <div class="flex-row">
                            <button mat-stroked-button class="open_reader btn" (click)="openReaderModal(post.data.id)">
                                <mat-icon class="size-auto"><i class="fak fa-crop-free"></i></mat-icon>Open in reader
                            </button>
                        </div>
                    }
                    @if (post.data.url != null) {
                        <div class="flex-row">
                            <a [href]="post.data.url" target="_blank">
                                <button mat-stroked-button class="go_source btn">
                                    <mat-icon class="size-auto"><i class="fal fa-link"></i></mat-icon>
                                    Go to source
                                </button>
                            </a>
                        </div>
                    }
                    @if (hasAdminRights && curatorMode && !isActiveSearch) {
                        <div class="flex-row">
                            <button mat-stroked-button class="btn delete-post" (click)="deletePost(post)">
                                <mat-icon class="size-auto"><i class="fal fa-trash-alt"></i></mat-icon>Delete
                            </button>
                        </div>
                    }
                    @if (hasAdminRights && curatorMode && enabledChannelDistribution()) {
                        <div class="flex-row">
                            <button mat-stroked-button class="add_post btn">
                                <mat-checkbox class="watch_this btn_checkbox" formControlName="selected">
                                    {{ form.value.selected ? 'Exclude' : 'Include' }}
                                </mat-checkbox>
                            </button>
                        </div>
                    }
                    @if (hasAdminRights && curatorMode && !isActiveSearch && (metadataEnabled$ | async)) {
                        <div class="flex-row">
                            <button mat-stroked-button class="btn metadata" (click)="showMetadata(post)">
                                <mat-icon><i class="fas fa-chart-bar"></i></mat-icon>Metadata
                            </button>
                        </div>
                    }
                    @if (
                        isActiveSearch &&
                        hasAdminRights &&
                        (post.type === 'news' || post.type === 'social_media' || post.type === 'forum')
                    ) {
                        <button mat-stroked-button class="save btn" (click)="addPost(post)">
                            <mat-icon class="size-auto"><i class="fal fa-layer-plus"></i></mat-icon> Save post
                        </button>
                    }
                    @if (showEditButton) {
                        <button mat-stroked-button class="edit btn" (click)="editPost(post)">
                            <mat-icon class="size-auto"><i class="fal fa-edit"></i></mat-icon> Edit post
                        </button>
                    }
                </ng-container>
            </div>
        </div>
    </div>
}
