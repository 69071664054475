@if (vm$ | async; as vm) {
    <div class="modal-component">
        @if (vm.errorMessage) {
            <app-alert (closed)="resetErrorMessage()">{{ vm.errorMessage }}</app-alert>
        }
        <div class="modal-header">
            <h2 class="title">{{ modalTitle }}</h2>
        </div>

        <div class="modal-body">
            @if (form) {
                <form [formGroup]="form">
                    <mat-form-field class="app-mat-form-field" appearance="outline">
                        <mat-label> Entity </mat-label>
                        <input type="text" formControlName="entity" matInput />
                    </mat-form-field>
                    <mat-form-field class="app-mat-form-field" appearance="outline">
                        <mat-label> Description </mat-label>
                        <input type="text" formControlName="description" matInput />
                    </mat-form-field>
                    <mat-form-field class="app-mat-form-field" appearance="outline">
                        <mat-label> Source </mat-label>
                        <input type="text" formControlName="source_of_score" matInput />
                    </mat-form-field>
                    <mat-form-field class="app-mat-form-field" appearance="outline">
                        <mat-label> Category </mat-label>
                        <input type="text" formControlName="category" matInput />
                    </mat-form-field>
                </form>
            }
            <div class="form_btns">
                <button class="cancel-button btn" [mat-dialog-close]="false" mat-flat-button>Cancel</button>
                <button class="btn primary_btn" (click)="onSave()" mat-raised-button [disabled]="vm.saveScoreLoading">
                    <ng-container *msLoadingSpinner="vm.saveScoreLoading; diameter: 20">Save</ng-container>
                </button>
            </div>
        </div>
    </div>
}
