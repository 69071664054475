import { Component, Input, inject } from '@angular/core';

import { DateService } from '@app/core/services';

@Component({
    selector: 'app-credit-card-body',
    templateUrl: './credit-card-body.component.html',
    styleUrls: ['./credit-card-body.component.scss'],
    standalone: false,
})
export class CreditCardBodyComponent {
    @Input() post;

    cardImg = '/assets/images/stopper.png';
    _DateService = inject(DateService);
}
