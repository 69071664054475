import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '@env/environment';

type Options = {
    observe?: 'body';
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any | null;
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    params?:
        | HttpParams
        | {
              [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
};

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    url = environment.host;

    constructor(private http: HttpClient) {}

    get<T>(path: string, options?: Options): Observable<T> {
        return this.http.get<T>(`${this.url}${path}`, options);
    }

    getFile(path: string, options?: Options): Observable<Blob> {
        return this.http.get(`${this.url}${path}`, {
            ...options,
            responseType: 'blob',
        });
    }

    post<T>(path: string, body: any | null, options?: Options): Observable<T> {
        return this.http.post<T>(`${this.url}${path}`, body, options);
    }

    put<T>(path: string, body: any | null, options?: Options): Observable<T> {
        return this.http.put<T>(`${this.url}${path}`, body, options);
    }

    patch<T>(path: string, body: any | null, options?: Options): Observable<T> {
        return this.http.patch<T>(`${this.url}${path}`, body, options);
    }

    delete<T>(path: string, options?: Options): Observable<T> {
        return this.http.delete<T>(`${this.url}${path}`, options);
    }

    getLocalResource<T>(path: string, options?: Options): Observable<T> {
        return this.http.get<T>(path, options);
    }

    getApiErrorMessage(error: any): string {
        return error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    }
}
