<div class="post_brief_actions row-content">
    @if (post.data.images && post.data.images.length) {
        <app-product-images-slider class="app-product-images-slider" [postData]="post.data"></app-product-images-slider>
    }

    <div class="dates_actions">
        @if (post.data.is_manual) {
            <div class="post_source">
                <span class="label">Added manually</span>
            </div>
        }

        <div class="post_dates">
            @if (post.data.publish_date) {
                <div class="date">
                    <span class="label">Date of Origin: </span>
                    @if (dateService.setDateISO8601(post.data.publish_date)) {
                        <span
                            >{{ dateService.getDateDiff('' + post.data.publish_date) }} &nbsp; / &nbsp;
                            {{
                                dateService.setDateISO8601(post.data.publish_date) | date: dateService.getDateFormat()
                            }}</span
                        >
                    }
                </div>
            }
        </div>
        @if (curatorMode) {
            @if (post.data.language) {
                <div class="post_source">
                    <span class="label">Language: </span>
                    <span>{{ post.data.language }}</span>
                </div>
            }
            @if (post.data.country; as country) {
                <div class="post_source">
                    <span class="label">Country: </span>
                    <span>{{ country }}</span>
                </div>
            }
            @if (post.data.domain; as domain) {
                <div class="post_source">
                    <span class="label">Domain: </span>
                    <span>{{ domain }}</span>
                </div>
            }
            @if (post.data.url; as url) {
                <div class="post_source">
                    <span class="label">URL: </span>
                    <span class="url">{{ url }}</span>
                </div>
            }
            @if (post.data.categories; as categories) {
                <div class="post_source">
                    <span class="label">Categories: </span>
                    <span>{{ categories.join(', ') }}</span>
                </div>
            }
        }
    </div>
</div>
