@if (selectedPostReader) {
    <div class="card_actions_content">
        @if (selectedPostReader.data.images && selectedPostReader.data.images.length) {
            <app-product-images-slider
                class="app-product-images-slider"
                [postData]="selectedPostReader.data.images"
            ></app-product-images-slider>
        }
        @if (!selectedPostReader.data.images?.length) {
            <img class="card_image" [src]="cardImg" />
        }

        <div class="dates_actions_content clearfix">
            <div class="dates_action_left">
                <div class="post_dates">
                    @if (selectedPostReader.data.publish_date) {
                        <div class="date post_source">
                            <span class="label">Date of Origin: </span>
                            <span
                                >{{ dateService.getDateDiff('' + selectedPostReader.data.publish_date) }} &nbsp; /
                                &nbsp;
                                {{
                                    dateService.setDateISO8601(selectedPostReader.data.publish_date)
                                        | date: dateService.getDateFormat()
                                }}</span
                            >
                        </div>
                    }
                    @if (selectedPostReader.data.publish_date) {
                        <div class="last_action post_source">
                            <span class="label">Last action: </span>
                            <span
                                >{{ dateService.getDateDiff('' + selectedPostReader.data.publish_date) }} &nbsp; /
                                &nbsp;
                                {{
                                    dateService.setDateISO8601(selectedPostReader.data.publish_date)
                                        | date: dateService.getDateFormat()
                                }}</span
                            >
                        </div>
                    }
                </div>
                @if (selectedPostReader.data.source) {
                    <div class="post_source">
                        <span class="label">Source: </span>
                        <span>{{ selectedPostReader.data.source }}</span>
                    </div>
                }
                @if (selectedPostReader.data.source) {
                    <div class="post_source">
                        <span class="label">Bank: </span>
                        <span>{{ selectedPostReader.data.bank }}</span>
                    </div>
                }
                @if (selectedPostReader.data.type) {
                    <div class="post_source">
                        <span class="label">Type: </span>
                        <span>{{ selectedPostReader.data.type }}</span>
                    </div>
                }
                @if (selectedPostReader.data.subtype) {
                    <div class="post_source">
                        <span class="label">Subtype: </span>
                        <span>{{ selectedPostReader.data.subtype }}</span>
                    </div>
                }
                @if (selectedPostReader.data.expM) {
                    <div class="post_source">
                        <span class="label">Exp: </span>
                        <span>{{ selectedPostReader.data.expM }}/{{ selectedPostReader.data.expY }}</span>
                    </div>
                }
                @if (selectedPostReader.data.name) {
                    <div class="post_source">
                        <span class="label">Name: </span>
                        <span>{{ selectedPostReader.data.name }}</span>
                    </div>
                }
            </div>

            @if (selectedPostReader.data.bin) {
                <div class="dates_action_right">
                    <div class="post_source">
                        <span class="label">BIN: </span>
                        <span>{{ selectedPostReader.data.bin }}</span>
                    </div>

                    @if (selectedPostReader.data.mark) {
                        <div class="post_source">
                            <span class="label">Mark: </span>
                            <span>{{ selectedPostReader.data.mark }}</span>
                        </div>
                    }
                    @if (selectedPostReader.data.city) {
                        <div class="post_source">
                            <span class="label">City: </span>
                            <span>{{ selectedPostReader.data.city }}</span>
                        </div>
                    }
                    @if (selectedPostReader.data.state) {
                        <div class="post_source">
                            <span class="label">State: </span>
                            <span>{{ selectedPostReader.data.state }}</span>
                        </div>
                    }
                    @if (selectedPostReader.data.country) {
                        <div class="post_source">
                            <span class="label">Country: </span>
                            <span>{{ selectedPostReader.data.country }}</span>
                        </div>
                    }
                    @if (selectedPostReader.data.zip) {
                        <div class="post_source">
                            <span class="label">Zip: </span>
                            <span>{{ selectedPostReader.data.zip }}</span>
                        </div>
                    }
                    @if (selectedPostReader.data.price) {
                        <div class="post_source">
                            <span class="label">Price: </span>
                            <span>{{ selectedPostReader.data.price }}</span>
                        </div>
                    }
                    @if (selectedPostReader.data.reseller) {
                        <div class="post_source">
                            <span class="label">Reseller: </span>
                            <span>{{ selectedPostReader.data.reseller }}</span>
                        </div>
                    }

                    <div class="post_source">
                        <span class="label">Phone: </span>
                        <span>{{ selectedPostReader.data.phone == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">AC: </span>
                        <span>{{ selectedPostReader.data.ac == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">D: </span>
                        <span>{{ selectedPostReader.data.d == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">DOB: </span>
                        <span>{{ selectedPostReader.data.dob == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">MMN: </span>
                        <span>{{ selectedPostReader.data.mmn == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">Address: </span>
                        <span>{{ selectedPostReader.data.address == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">IP: </span>
                        <span>{{ selectedPostReader.data.ip == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">R: </span>
                        <span>{{ selectedPostReader.data.r == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">SSN4: </span>
                        <span>{{ selectedPostReader.data.ssn4 == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">SSN: </span>
                        <span>{{ selectedPostReader.data.ssn == 1 ? 'Yes' : 'No' }}</span>
                    </div>

                    <div class="post_source">
                        <span class="label">Email: </span>
                        <span>{{ selectedPostReader.data.email == 1 ? 'Yes' : 'No' }}</span>
                    </div>
                    @if (selectedPostReader.data.analyst_comment) {
                        <div class="post_source">
                            <span class="label">Analyst comment: </span>
                            <span>{{ selectedPostReader.data.analyst_comment }}</span>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
}
