<header>
    <div class="left-block">
        <div class="title">
            <mat-icon class="size-auto"><i class="fal fa-tag"></i></mat-icon>
            <h2>Tags</h2>
        </div>
        @if (tagsCount && !readOnlyMode) {
            <app-bulk-operations-buttons
                [selectionMode]="bulkSelectionModeActive"
                [selectedItemsCount]="this.tagsForBulkOperation?.length"
                [sortMode]="sortModeActive"
                (toggleSelectMode)="onToggleSelectionMode()"
                (toggleSortMode)="onToggleSortMode()"
                (saveSort)="onSaveSort()"
                (move)="onChaneTagsCategory()"
                (delete)="onDeleteTags()"
            ></app-bulk-operations-buttons>
        }
    </div>

    @if (!isEmptyTags) {
        <div class="search-block">
            <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <mat-icon class="size-auto" matPrefix><i class="fal fa-search"></i></mat-icon>
                <input type="text" (input)="onSearch($event)" matInput />
            </mat-form-field>
        </div>
    }
</header>
<ng-container *msLoadingSpinner="isLoading">
    @if (!isEmptyTags) {
        <main>
            <div class="info">
                <form novalidate #tagCategoryForm="ngForm" id="createChannel_form">
                    <app-category-items-selection
                        [type]="'tags'"
                        [isAddedClass]="true"
                        [categories]="tagsCategoryList"
                        [isActiveClassHidden]="!selectionMode && !sortModeActive"
                        [isEditButtonVisible]="!selectionMode && !sortModeActive"
                        [isAddButtonVisible]="!selectionMode && !sortModeActive"
                        [isSelectionMode]="selectionMode"
                        [isSortMode]="sortModeActive"
                        (editItem)="openTagFormDialog($event)"
                        (editCategoryEvent)="openTagCategoryFormDialog($event)"
                        (addItem)="openTagFormDialog(null, $event)"
                        (toggleItemEvent)="onToggleTag($event)"
                        (selectAllItems)="selectAllCategoryItems($event)"
                        (unselectAllItems)="unSelectAllCategoryItems($event)"
                        (sortCategories)="onSortCategories($event)"
                        (sortCategoryItems)="onSortCategoryItems($event)"
                        [tags]="selectedTags"
                        [selectedItem]="selectedTags"
                    >
                    </app-category-items-selection>
                    @if (!readOnlyMode && !selectionMode && !sortModeActive) {
                        <div class="form_btns">
                            <button
                                class="primary_btn btn"
                                type="button"
                                (click)="openTagCategoryFormDialog()"
                                data-testid="open-tag-category-form-button"
                                mat-raised-button
                            >
                                <mat-icon class="size-auto"><i class="fal fa-plus"></i></mat-icon
                                ><span>Add category</span>
                            </button>
                        </div>
                    }
                </form>
            </div>
        </main>
    } @else {
        @if (!readOnlyMode) {
            <div class="empty-container">
                <app-empty-page
                    matIconClass="fal fa-tag"
                    appTitle="Tags are empty"
                    description="<b>Tags</b> — connecting elements between posts. Each tag must have a category.<br>
          To create a new tag please create a <b>new category</b> first."
                    buttonName="Create category"
                    buttonTestId="open-tag-category-form-button"
                    (buttonClickEvent)="openTagCategoryFormDialog()"
                ></app-empty-page>
            </div>
        } @else {
            <div class="empty-container">
                <app-empty-page
                    matIconClass="fal fa-tag"
                    appTitle="Tags are empty"
                    description="<b>Tags</b> — connecting elements between posts.<br>"
                    linkName="Manage tags"
                    (linkClickEvent)="onGoToTagsPageClick()"
                ></app-empty-page>
            </div>
        }
    }
</ng-container>
