import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlagsState } from './feature-flags.reducer';

const featureFlagsStateFeatureKey = 'featureFlags';

export const selectFeatureFlagsFeature = createFeatureSelector<FeatureFlagsState>(featureFlagsStateFeatureKey);

export const selectFeatureFlags = createSelector(
    selectFeatureFlagsFeature,
    (state: FeatureFlagsState) => state.featureFlags,
);

export const selectCreditCardFeatureFlag = createSelector(
    selectFeatureFlagsFeature,
    (state: FeatureFlagsState) => state.featureFlags?.creditcard_enabled,
);
