import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Post } from '@app/data/models';

@Component({
    selector: 'app-post-score-icon',
    templateUrl: './post-score-icon.component.html',
    styleUrls: ['./post-score-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class PostScoreIconComponent {
    @Input() postData: Post;

    get wrapperClass(): string {
        const score = this.postData.score?.dominant_score;
        const isAi = this.postData.ai_score != null ? 'ai' : '';
        return `score-${score} ${isAi}`;
    }

    get iconClass(): string {
        return this.postData.ai_score ? 'fad fa-flask' : 'fas fa-exclamation-triangle';
    }
}
