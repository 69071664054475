import { createReducer, on } from '@ngrx/store';

import { Channel, ChannelUnreadInfo, CollectionChannel, ChannelCategories, Collection } from '@app/data/models';
import * as ChannelsListActions from './channels-list.actions';

export enum LoadingState {
    NOT_LOADED = 'NOT_LOADED',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    LOADED_UNREAD_INFO = 'LOADED_UNREAD_INFO',
}
export interface ChannelsListState {
    channelsList?: ChannelCategories[];
    channelCollectionIds?: Record<string, string>;
    channelIds: string[];
    loadingState: LoadingState;
}

function updateChannelWithUnreadInfo(channel: CollectionChannel | Channel, unreadInfo: ChannelUnreadInfo): boolean {
    if (unreadInfo) {
        channel.has_unread = unreadInfo.has_unread;
        channel.last_read_at = unreadInfo.last_read_at;
        channel.unread_count = unreadInfo.unread_count;
        channel.score = unreadInfo.score;
    }
    return channel.has_unread;
}

function updateCollectionWithUnreadInfo(
    channel: CollectionChannel,
    unreadInfo: ChannelUnreadInfo,
    collection: Collection,
) {
    const hasUnreadChannels = updateChannelWithUnreadInfo(channel, unreadInfo);
    collection.has_unread = hasUnreadChannels;
}

const initialChannelsListState: ChannelsListState = {
    loadingState: LoadingState.NOT_LOADED,
    channelCollectionIds: {},
    channelIds: [],
};

export const channelsListReducer = createReducer(
    initialChannelsListState,
    on(ChannelsListActions.loadChannelsListSuccess, (state, { channelsList }) => {
        const channelIds = [];
        const channelCollectionIds = {};

        channelsList?.forEach((item) => {
            item.collections.forEach((collectionItem) => {
                collectionItem.channels.forEach((channelItem) => {
                    channelCollectionIds[channelItem.id] = collectionItem.id;
                    channelIds.push(channelItem.id);
                });
            });
            item.channels.forEach((channelItem) => {
                channelIds.push(channelItem.id);
            });
        });

        return {
            ...state,
            loadingState: LoadingState.LOADED,
            channelsList,
            channelCollectionIds,
            channelIds,
        };
    }),
    on(ChannelsListActions.loadChannelsUnreadInfoSuccess, (state, { channelsUnreadInfo }) => {
        const channelsList = JSON.parse(JSON.stringify(state.channelsList));
        const channelsUnreadInfoMap = {};

        channelsUnreadInfo.forEach((channelUnreadInfo) => {
            channelsUnreadInfoMap[channelUnreadInfo.id] = channelUnreadInfo;
        });

        channelsList.forEach((categoryItem: ChannelCategories) => {
            categoryItem.channels?.forEach((c) => updateChannelWithUnreadInfo(c, channelsUnreadInfoMap[c.id]));
            categoryItem.collections?.forEach((col) => {
                col.channels?.forEach((c) => updateCollectionWithUnreadInfo(c, channelsUnreadInfoMap[c.id], col));
            });
        });
        return {
            ...state,
            loadingState: LoadingState.LOADED_UNREAD_INFO,
            channelsList,
        };
    }),
    on(ChannelsListActions.loadChannelsUnreadInfo, (state) => ({ ...state, loadingState: LoadingState.LOADED })),
    on(ChannelsListActions.loadChannelsList, (state) => ({ ...state, loadingState: LoadingState.LOADING })),
    on(ChannelsListActions.resetChannelsList, (state) => ({
        ...state,
        loadingState: LoadingState.NOT_LOADED,
        channelsList: [],
    })),
    on(ChannelsListActions.updateChannelUnreadStatus, (state, { channelId, hasUnread }) => {
        if (!state.channelsList) return state;

        const updatedChannelsList = state.channelsList.map((categoryItem) => ({
            ...categoryItem,
            // Update channels in category
            channels: categoryItem.channels?.map((channel) =>
                channel.id === channelId ? { ...channel, has_unread: hasUnread } : channel,
            ),
            // Update channels in collections
            collections: categoryItem.collections?.map((collection) => {
                const updatedChannels = collection.channels?.map((channel) =>
                    channel.id === channelId ? { ...channel, has_unread: hasUnread } : channel,
                );

                return {
                    ...collection,
                    channels: updatedChannels,
                    // Update collection has_unread status based on its channels
                    has_unread: updatedChannels?.some((ch) => ch.has_unread),
                };
            }),
        }));

        return {
            ...state,
            channelsList: updatedChannelsList,
        };
    }),
);
