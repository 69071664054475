import { Injectable } from '@angular/core';
import { ShodanDynamicData, ShodanPostDataModel, ShodanPostViewModel } from './shodan.model';

@Injectable({
    providedIn: 'root',
})
export class ShodanService {
    getPostViewData(post: ShodanPostDataModel): ShodanPostViewModel {
        const dynamicPostData = this.dynamicPostData(post);
        return {
            location: this.locationFieldValue(post),
            ip: post.data.ip_str,
            isp: post.data.isp,
            asn: post.data.asn,
            data: post.data.data,
            meta: post.data.meta,
            os: post.data.os,
            version: post.data.version,
            product: post.data.product,
            port: post.data.port,
            domains: this.fallbackArray(post.data.domains).join(','),
            hostnames: this.fallbackArray(post.data.hostnames).join(','),
            tags: (dynamicPostData.tags as string[]).join(' / '),
            transport: post.data.transport,
            cpe: (dynamicPostData.cpe as string[]).join(' / '),
            cpe23: (dynamicPostData.cpe23 as string[]).join(' / '),
            published_at: post.data.published_at || post.data.timestamp,
        };
    }

    private dynamicPostData(post: ShodanPostDataModel): ShodanDynamicData {
        const dynamicPostData = post.data?.dynamic_data || {};
        dynamicPostData.cpe = this.fallbackArray(dynamicPostData.cpe);
        dynamicPostData.cpe23 = this.fallbackArray(dynamicPostData.cpe23);
        dynamicPostData.tags = this.fallbackArray(dynamicPostData.tags);
        return dynamicPostData;
    }

    private locationFieldValue(post: ShodanPostDataModel): string {
        const location = this.dynamicPostData(post).location;
        if (!location) return;
        return `${location.country_name}, ${location.city}`;
    }

    private fallbackArray(array: any): any[] {
        return Array.isArray(array) ? array : [];
    }
}
