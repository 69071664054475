import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiService, ErrorHandlerService } from '@app/core/services';
import { Template, Channel } from '@app/data/models';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ChannelService {
    constructor(
        private apiService: ApiService,
        private errorHandlerService: ErrorHandlerService,
    ) {}

    getChannels(): Observable<any[]> {
        return this.apiService.get('/me/channels', { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getChannelsUnreadInfo(): Observable<any[]> {
        return this.apiService.get('/me/channels-unread', { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getChannel(channelId: string): Observable<Channel> {
        return this.apiService.get('/channel/' + channelId, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getFilteredChannels(categoryId: string, collectionId: string): Observable<any[]> {
        let query = '';
        if (categoryId != 'all' && !isNaN(+categoryId)) {
            if (categoryId == '-1') {
                query = '?category_id__isnull=True';
            } else {
                query = '?category_id__isnull=False&category_id=' + categoryId;
            }
        }
        if (collectionId != 'all' && !isNaN(+collectionId)) {
            const paramPrefix = query == '' ? '?' : '&';
            if (collectionId == '-1') {
                query += `${paramPrefix}collection_id__isnull=True`;
            } else {
                query += `${paramPrefix}collection_id__isnull=False&collection_id=${collectionId}`;
            }
        }

        return this.apiService.get('/channels' + query, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getChannelFeedPagination(
        channelId: string,
        offset: string,
        count: string,
        filters: HttpParams = null,
    ): Observable<any[]> {
        return this.apiService
            .get('/channel/' + channelId + '/feed/' + offset + '/' + count + '/reader', {
                withCredentials: true,
                params: filters,
            })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    getChannelFeedCuratorMode(
        channelId: string,
        offset: string,
        count: string,
        filters: HttpParams = null,
    ): Observable<any[]> {
        return this.apiService
            .get(`/channel/${channelId}/feed/${offset}/${count}/curator`, {
                withCredentials: true,
                params: filters,
            })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    getPostInfo(postId: string): Observable<any> {
        return this.apiService.get('/post/' + postId, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getTags(): Observable<any[]> {
        return this.apiService.get('/tag', { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getCollectionCategories(): Observable<any[]> {
        return this.apiService.get('/collection/categories', { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getAllChannels(): Observable<any[]> {
        return this.apiService.get('/me/channels?all=true', { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    createChannel(newChannel): Observable<any[]> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService.post('/channel', newChannel, { headers: headers, withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    createArticle(data, tagIds: Array<number>) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        const body = {
            post: data,
            tag_ids: tagIds,
        };
        return this.apiService.post('/post', body, { headers: headers, withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    // update channel
    editChannel(newChannel): Observable<any[]> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService
            .put('/channel/' + newChannel.id, newChannel, { headers: headers, withCredentials: true })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    getChannelTemlate(): Observable<Template[]> {
        return this.apiService.get('/channel/templates', { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    createCollection(newCollection): Observable<any[]> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService.post('/collection', newCollection, { headers: headers, withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    updateCollection(newCollection): Observable<any[]> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService
            .post('/collection/' + newCollection.collection.id, newCollection, {
                headers: headers,
                withCredentials: true,
            })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    getCollectionById(collectionId) {
        return this.apiService.get('/collection/' + collectionId, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    createCategory(newCategory): Observable<any[]> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService
            .post('/collection/categories', newCategory, { headers: headers, withCredentials: true })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    // edit category
    editCategory(editCategory): Observable<any[]> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService
            .post('/collection/categories/' + editCategory.category.id, editCategory, {
                headers: headers,
                withCredentials: true,
            })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    // set post as read
    setPostAsRead(postId: string, channelId): Observable<any> {
        return this.apiService
            .post('/post/' + postId + '/' + channelId + '/read', null, { withCredentials: true })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    setChannelPostsAsRead(channelId): Observable<any> {
        return this.apiService.post(`/channel/${channelId}/read`, null, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    setChannelPostsAsUnread(channelId): Observable<any> {
        return this.apiService.post(`/channel/${channelId}/unread`, null, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    deleteCategory(id) {
        return this.apiService.delete(`/collection/categories/${id}`, { withCredentials: true });
    }

    deleteCollection(id) {
        return this.apiService.delete(`/collection/${id}`, { withCredentials: true });
    }

    deleteChannel(id) {
        return this.apiService.delete(`/channel/${id}`, { withCredentials: true });
    }

    addTagsToPost(postId, tagIds: { added: number[]; removed: number[] }) {
        return this.apiService.post('/post/' + postId + '/tags', tagIds, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    includeChannel(postIds: string[], channelId: string): Observable<any> {
        return this.apiService
            .post(`/channel/${channelId}/posts/include`, { post_ids: postIds }, { withCredentials: true })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    excludeChannel(postIds: string[], channelId: string): Observable<any> {
        return this.apiService
            .post(`/channel/${channelId}/posts/exclude`, { post_ids: postIds }, { withCredentials: true })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    excludeAllIncluded(channelId: string) {
        return this.apiService.delete(`/channel/${channelId}/exclude`, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    saveAnalystComment(postId: string, postComment: string): Observable<any> {
        return this.apiService
            .post(`/post/${postId}/analyst_comment`, { comment_text: postComment }, { withCredentials: true })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    getNewsLetter(channelId: string) {
        return this.apiService.get(`/channel/${channelId}/newsletter`, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    distributeNewsletter(channelId: string) {
        return this.apiService.post(`/channel/${channelId}/newsletter`, {}, { withCredentials: true });
    }

    getTagsId(tagId: number): Observable<any[]> {
        return this.apiService.get('/tag/' + tagId, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    updateTag(tagId: number, data) {
        return this.apiService.put('/tag/' + tagId, data, { withCredentials: true });
    }

    createTag(data) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService.post('/tag', data, { headers: headers, withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    updateTagCategory(id: string, data) {
        return this.apiService.put('/tag/category/' + id, data, { withCredentials: true });
    }

    updateTagsCategory(data) {
        return this.apiService.put('/bulk/tags', data, { withCredentials: true });
    }

    createTagCategory(data) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService.post('/tag/category', data, { headers: headers, withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    deleteTag(tagId: number) {
        return this.apiService.delete(`/tag/${tagId}`, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    deleteTags(data) {
        return this.apiService.delete('/bulk/tags', { withCredentials: true, body: data }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    deleteTagCategory(id: string) {
        return this.apiService.delete(`/tag/category/${id}`, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    setTagsOrder(searchIds: number[]) {
        const requestData = searchIds.map((id) => ({ id }));
        return this.apiService
            .put('/tag/order', requestData, { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    setTagssCategoriesOrder(searchCategoryIds: number[]) {
        const requestData = searchCategoryIds.map((id) => ({ id }));
        return this.apiService
            .put('/tag/category/order', requestData, { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    deletePost(id, channelId) {
        return this.apiService.delete(`/post/${id}/channel/${channelId}/delete-post`, { withCredentials: true });
    }

    updatePostScore(id: string, score: number) {
        return this.apiService.put(`/post/${id}/score/${score}`, null, { withCredentials: true });
    }

    deletePostScore(id: string, score: number) {
        return this.apiService.delete(`/post/${id}/score/${score}`, { withCredentials: true });
    }

    addPostScore(id: string, score: number) {
        return this.apiService.post(`/post/${id}/score/${score}`, null, { withCredentials: true });
    }

    export(channelId, offset, count, type) {
        return this.apiService
            .getFile(`/channel/${channelId}/feed/${offset}/${count}/${type}`, {
                withCredentials: true,
            })
            .pipe(
                map(this.extractData),
                catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            );
    }

    getCollections(categoryId): Observable<any[]> {
        let params = '';
        if (categoryId) {
            if (categoryId == '-1') {
                params = '?category_id__isnull=True';
            } else {
                params = `?category_id__isnull=False&category_id=${categoryId}`;
            }
        }

        return this.apiService.get('/channels/collections' + params, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    getCategory(categoryId): Observable<any> {
        return this.apiService.get('/collection/categories/' + categoryId, { withCredentials: true }).pipe(
            map(this.extractData),
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
        );
    }

    putCollectionSubscription(userId, categoryId) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');

        return this.apiService.put('/user/' + userId + '/subscriptions/collection/' + categoryId, '', {
            headers: headers,
            withCredentials: true,
        });
    }

    deleteCollectionSubscription(userId, categoryId) {
        return this.apiService.delete('/user/' + userId + '/subscriptions/collection/' + categoryId, {
            withCredentials: true,
        });
    }

    putChannelSubscription(userId, channelId) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        return this.apiService.put('/user/' + userId + '/subscriptions/channel/' + channelId, '', {
            headers: headers,
            withCredentials: true,
        });
    }

    deleteChannelSubscription(userId, channelId) {
        return this.apiService.delete('/user/' + userId + '/subscriptions/channel/' + channelId, {
            withCredentials: true,
        });
    }

    private extractData(res) {
        return res || {};
    }
}
