import { createAction, props } from '@ngrx/store';

import { FeatureFlagsResponse } from '@app/data/models';

export enum FeatureFlagsActionTypes {
    GetFeatureFlags = '[Feature Flags] Get feature flags',
    GetFeatureFlagsSuccess = '[Feature Flags] Get feature flags success',
}

export const getFeatureFlags = createAction(FeatureFlagsActionTypes.GetFeatureFlags);
export const getFeatureFlagsSuccess = createAction(
    FeatureFlagsActionTypes.GetFeatureFlagsSuccess,
    props<{ featureFlags: FeatureFlagsResponse }>(),
);
