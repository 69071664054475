export interface PromptsDTO {
    title: string;
    variables: PromptVariables[];
}

export type PromptVariables = {
    possible_values: string[] | null;
    key: string;
    default_value: string;
    type: string;
    placeholder_text: string;
    help_text: string;
};

export enum ReportType {
    summary_report = 'summary-report',
    digital_report = 'digital-report',
}
