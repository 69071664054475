import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    getErrorMessage(error: unknown): Observable<never> {
        if (error['status'] === 500) {
            return throwError(
                () => 'An unexpected error has occurred. Please contact your administrator for more information',
            );
        }
        const stringError = this.getStringError(error);
        const predefinedError = error['error']['message'] || error['error']['query'] || stringError || error['message'];
        const errMsg = predefinedError
            ? predefinedError
            : error['status']
              ? `${error['status']} - ${error['statusText']}`
              : 'Server error';

        return throwError(errMsg);
    }

    private getStringError(error: unknown) {
        if (error['error'] && error['error']['transaction']) return error['error']['transaction'];
        if (error['error'] && error['error']['detail']) return error['error']['detail'];
        if (typeof error['error'] !== 'string') return null;
        try {
            const errorMessage = JSON.parse(error['error'])?.message;
            return errorMessage;
        } catch (e) {
            return 'Oops! Something went wrong! \n Help us improve your experience by sending an error report';
        }
    }
}
