<div class="image_preview_component modal-component">
    <div class="modal-header">
        <div class="button-clear">
            <div class="close_modal btn" (click)="close()">
                <mat-icon><i class="fal fa-times"></i></mat-icon>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="image-content">
            <img [src]="screenshotUrl" (click)="close()" />
        </div>
    </div>
</div>
