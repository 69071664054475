@if (type) {
    <div class="form-group" [class.container_item]="isAddedClass" [class.sort-mode]="isSortMode">
        @if (loading) {
            <mat-spinner class="spinner" color="accent" strokeWidth="2" diameter="26"></mat-spinner>
        }
        <ul class="category-list draggable-container">
            @for (category of categories; track category) {
                @if (activeCategory === undefined || (activeCategory && activeCategory.id != category.id)) {
                    <li [attr.tagCategory-id]="category.id" class="category draggable-item" [ngClass]="type">
                        <a
                            class="category_anchor draggable-item-handle"
                            data-testid="items-category"
                            [attr.data-target]="category.id"
                            [class.disabled]="category[type].length == 0"
                            [class.read-only]="isReadOnlyMode"
                            (click)="editCategory($event, category)"
                        >
                            <div class="row-wrapper">
                                <div class="title_wrapper">
                                    <span class="title" data-testid="title" [matTooltip]="category.name">
                                        {{ category.name | truncate: 15 }}

                                        @if (isEditButtonVisible) {
                                            <mat-icon class="size-auto"><i class="far fa-edit"></i></mat-icon>
                                        }
                                    </span>

                                    @if ((!isSelectionMode && !isSortMode) || isReadOnlyMode) {
                                        <span class="title_category" data-testid="category-title"
                                            >{{ category[type].length }} {{ itemTypesMap.title }}</span
                                        >
                                    }
                                    @if (isSelectionMode && !isReadOnlyMode && !isSortMode) {
                                        <span class="title_category" data-testid="category-title"
                                            >Select<span class="active" (click)="onClickSelectAllItems(category)"
                                                >All</span
                                            ><span class="active" (click)="onClickUnSelectAllItems(category)"
                                                >None</span
                                            >
                                        </span>
                                    }
                                </div>
                            </div>
                        </a>
                        <div>
                            <ul class="items-list draggable-container" [attr.category-container-id]="category.id">
                                @for (item of category[type]; track item) {
                                    <li
                                        (click)="onClickCategoryItem(item)"
                                        [attr.tag-id]="item.id"
                                        class="category-item-outline draggable-item"
                                        [matTooltip]="
                                            isTagLocked(item.id)
                                                ? 'This tag is set by default and can\'t be changed'
                                                : null
                                        "
                                        data-testid="category-item"
                                    >
                                        <app-category-item
                                            [item]="item"
                                            [itemType]="type"
                                            [size]="'size-lg'"
                                            [selected]="!isActiveClassHidden && isAddedItem(item.id)"
                                            [locked]="isTagLocked(item.id)"
                                            [editable]="isActiveClassHidden"
                                            [interactable]="true"
                                            [draggable]="isSortMode"
                                            class="draggable-item-handle"
                                        ></app-category-item>
                                    </li>
                                }
                                @if (isAddButtonVisible) {
                                    <li
                                        class="category-item-outline add-item"
                                        (click)="onClickAddItem(category)"
                                        data-testid="add-item"
                                    >
                                        <mat-icon class="outline-item-icon size-auto"
                                            ><i class="fal fa-plus"></i
                                        ></mat-icon>
                                        {{ itemTypesMap.addItem }}
                                    </li>
                                }
                            </ul>
                        </div>
                    </li>
                }
            }
        </ul>
        @if (categories && categories.length == 0) {
            <div class="categories_empty">
                {{ itemTypesMap.emptyCtegory }}
            </div>
        }
    </div>
}
