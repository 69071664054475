<mat-list-item class="item-header" [ngClass]="{ expanded: leaksViewPost.isExpanded, 'curator-mode': channel }">
    <div class="leaks_wrap">
        @if (leaksViewPost.data.is_read !== true) {
            <span class="read"></span>
        }
        @if (!reader) {
            <app-post-type-image [post]="leaksViewPost"></app-post-type-image>
        }
        @if (!leaksViewPost.isExpanded) {
            <div class="header-description">
                <div class="header-title">
                    @if (leaksViewPost.data?.line) {
                        <span class="leaks_span_item">{{ leaksViewPost.data.line }}</span>
                    }
                </div>
                @if (!reader) {
                    <div class="leaks_text_wrap">
                        <div class="info-wrapper">
                            <div class="info-wrapper-row details">
                                @if (leaksViewPost.data?.indexed_at) {
                                    <span class="leaks_text_item action index-date"
                                        >Indexed:
                                        <span class="leaks_span_item">
                                            {{
                                                dateService.setDateISO8601(leaksViewPost.data.indexed_at)
                                                    | date: dateService.getDateFormat()
                                            }}
                                        </span>
                                    </span>
                                }
                                @if (leaksViewPost.data?.published_at) {
                                    <span class="leaks_text_item action leaks-date"
                                        >Posted:
                                        <span class="leaks_span_item">
                                            {{
                                                dateService.setDateISO8601(leaksViewPost.data.published_at)
                                                    | date: dateService.getDateFormat(true)
                                            }}
                                        </span>
                                    </span>
                                }
                                @if (leaksViewPost.data.bucket) {
                                    <span [matTooltip]="leaksViewPost.data.bucket">
                                        Filename: <b>{{ leaksViewPost.data.file_name }}</b>
                                    </span>
                                }
                                @if (leaksViewPost.data.is_manual) {
                                    <span>Added manually</span>
                                }
                                @if (leaksViewPost.data.previously_distributed && curatorMode) {
                                    <span
                                        ><b>Distributed</b>
                                        <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                                    ></span>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
        @if (leaksViewPost.isExpanded || reader) {
            <div
                class="leaks_text_wrap"
                [class.reader]="reader"
                [ngClass]="leaksViewPost.isExpanded || reader ? 'expanded' : ''"
            >
                <div
                    class="header_wrapper"
                    [ngClass]="leaksViewPost.isExpanded ? 'expanded' : ''"
                    [class.distributed]="
                        leaksViewPost.data.previously_distributed && leaksViewPost.isExpanded && curatorMode
                    "
                >
                    @if (leaksViewPost.isExpanded) {
                        <app-post-type-label [post]="leaksViewPost"></app-post-type-label>
                    }

                    @if (leaksViewPost.data.previously_distributed && leaksViewPost.isExpanded && curatorMode) {
                        <span class="item_distributed"
                            ><b>Distributed</b>
                            <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                        ></span>
                    }
                </div>
            </div>
        }
    </div>
</mat-list-item>
