<div class="modal-component">
    <div class="modal-header">
        <div class="button-clear">
            <button class="close_modal" aria-label="Close" mat-icon-button (click)="close()">
                <mat-icon class="size-auto"><i class="fal fa-times"></i></mat-icon>
                esc
            </button>
        </div>
    </div>

    <div class="modal-body">
        <div [innerHTML]="clearHtml()" *msLoadingSpinner="!clearHtml()"></div>
    </div>
</div>
