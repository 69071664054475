import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { AllowedDomain, ContentProvider } from '@app/data/models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AdminApiService {
    constructor(private http: HttpClient) {}

    getContentProviders(): Observable<ContentProvider[]> {
        return this.http.get<ContentProvider[]>(`${environment.host}/admin/content_providers`, {
            withCredentials: true,
        });
    }

    /**
     * @param collection_ids - array of all collection ids enabled in silo
     * @returns
     * @description Enable content collection
     **/
    enableContentCollection(collection_ids: number[]) {
        return this.http.put(
            `${environment.host}/admin/content_collection/enable`,
            { collection_ids },
            {
                withCredentials: true,
            },
        );
    }

    /**
     * @param collection_ids - array of collection ids that need to be disabled
     * @returns
     * @description Disable content collection
     **/
    disableContentCollection(collection_ids: number[]) {
        return this.http.put(
            `${environment.host}/admin/content_collection/disable`,
            { collection_ids },
            {
                withCredentials: true,
            },
        );
    }

    updateSilo(data) {
        return this.http.patch(`${environment.host}/admin/silo`, data, { withCredentials: true });
    }

    createSilo(data) {
        return this.http.post(`${environment.host}/admin/silo`, data, { withCredentials: true });
    }

    deleteSilo() {
        return this.http.delete(`${environment.host}/admin/silo`, { withCredentials: true });
    }

    getDomainsInfo() {
        return this.http.get(`${environment.host}/admin/domain/info`, { withCredentials: true });
    }

    createDomain(data) {
        return this.http.post(`${environment.host}/admin/domain/info`, data, { withCredentials: true });
    }

    deleteDomain(id) {
        return this.http.delete(`${environment.host}/admin/domain/info/${id}`, { withCredentials: true });
    }

    updateDomain(id, data) {
        return this.http.patch(`${environment.host}/admin/domain/info/${id}`, data, { withCredentials: true });
    }

    getAllowedDomains() {
        return this.http.get(`${environment.host}/admin/allowed_domains`, { withCredentials: true }).pipe(
            catchError((error) => {
                console.error('admin.service => getAllowedDomains error => ', error);
                return [];
            }),
        ) as Observable<AllowedDomain[]>;
    }

    getAPITokenInfo() {
        return this.http.get(`${environment.host}/token/get`, { withCredentials: true });
    }

    regenerateAPIToken() {
        return this.http.get(`${environment.host}/token/generate`, { withCredentials: true });
    }

    createAllowedQuery(collectionId: number, query: string) {
        return this.http.post(
            `${environment.host}/admin/content_collection/${collectionId}/whitelist`,
            { query },
            {
                withCredentials: true,
            },
        );
    }

    editAllowedQuery(collectionId: number, requestData: { query: string; whitelistquery_id: number }) {
        return this.http.put(`${environment.host}/admin/content_collection/${collectionId}/whitelist`, requestData, {
            withCredentials: true,
        });
    }

    deleteAllowedQuery(collectionId: number, queryId: number) {
        return this.http.delete(`${environment.host}/admin/content_collection/${collectionId}/whitelist`, {
            withCredentials: true,
            body: { whitelistquery_id: queryId },
        });
    }
}
