<div class="content">
    @if (data.header) {
        <div class="header">
            <p [innerHTML]="data.header"></p>
        </div>
    }
    <p class="message">{{ data.message }}</p>
    <div class="buttons-container">
        @if (data.preferencesKey) {
            <mat-checkbox (change)="onDoNotAskChanged($event)">Do not ask again</mat-checkbox>
        }
        <button class="ms-button button-cancel" data-testid="decline-dialog" [mat-dialog-close]="false" mat-flat-button>
            Cancel
        </button>
        <button
            class="ms-button primary_btn btn"
            data-testid="confirm-dialog"
            [mat-dialog-close]="true"
            mat-raised-button="mat-raised-button"
            color="primary"
        >
            {{ data.confirm || 'Yes' }}
        </button>
    </div>
</div>
