import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';

import { Channel, FeedPost } from '@app/data/models';

@Injectable()
export class PostsFeedService {
    private document = inject(DOCUMENT);

    getFeedPaginationParmas(readerMode: boolean, postOffset: number, postsCount: number, initialPostsCount: number) {
        /**
         * Handels posts offset when its not the 1st request. For example. if the count of posts
         * per request is 20, the first request gets posts from number 0 to 19 then the 2nd should get
         * from post 20 to 40 and 3rd from 41 to 61
         */
        let count: string;
        let offset: string;
        const methodName = readerMode ? 'getChannelFeedPagination' : 'getChannelFeedCuratorMode';
        if (postOffset > 0) {
            offset = String(postOffset * +postsCount + (initialPostsCount - +postsCount));
            count = String(postsCount);
        } else {
            count = String(initialPostsCount);
            offset = '0';
        }
        return { count, offset, methodName };
    }

    getInitialPostsCount(postsCount: number) {
        const minPostsCount = postsCount;
        const screenHeight = this.document.defaultView.innerHeight;
        const postHeight = 56;
        const scrollOffsetPadding = postHeight * 2;
        const dashboardHeaderHeight = 132;
        const initialPostsCount = (screenHeight - dashboardHeaderHeight + scrollOffsetPadding) / postHeight;

        if (initialPostsCount > minPostsCount) {
            return Math.round(initialPostsCount);
        } else {
            return minPostsCount;
        }
    }

    getTotalNumberOfPostsText(totalNumberOfPosts: number): string {
        if (totalNumberOfPosts >= 10000) {
            return 'More than 10000';
        }
        return `${totalNumberOfPosts}`;
    }

    showLastReadLine(post: FeedPost, posts: FeedPost[], channelInfo: Channel, currentIndex = null) {
        if (currentIndex === 0) {
            return false;
        }

        const firstItemIsUnread =
            posts.length && posts[0] && new Date(channelInfo.last_read_at) > new Date(posts[0].data.indexed_at);
        if (firstItemIsUnread && channelInfo.last_read_at) {
            return false;
        }

        if (currentIndex && currentIndex !== 0) {
            const prevPost = posts[currentIndex - 1];
            if (this.showLastReadLine(prevPost, posts, channelInfo)) {
                return false;
            }
        }

        return new Date(channelInfo.last_read_at) > new Date(post.data.indexed_at);
    }

    isAllPostsAreShown(posts: FeedPost[], channelInfo: Channel, endOfPosts: boolean, postsCount: number) {
        const allPostsOnOnePage = posts.length < postsCount;
        if ((allPostsOnOnePage || endOfPosts) && this.lastPostIsUnread(posts, channelInfo)) {
            return true;
        }
        return false;
    }

    updateFeedPostById(posts: FeedPost[], id: string, modifierFn: (post: FeedPost) => FeedPost): FeedPost[] {
        return posts.map((post) => {
            if (post.data.id === id) {
                return modifierFn(post);
            } else {
                return post;
            }
        });
    }

    private lastPostIsUnread(posts: FeedPost[], channelInfo: Channel) {
        if (posts?.length) {
            const lastPost = posts.slice(-1)[0];
            return new Date(channelInfo.last_read_at) < new Date(lastPost.data.indexed_at);
        }
        return false;
    }
}
