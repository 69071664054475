import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';
import { AppStateService } from '@app/core/services/app-state.service';
import { ScoreWording } from '@app/data/models';
import { Observable } from 'rxjs';
import { map, pairwise, startWith, tap } from 'rxjs/operators';

@Component({
    selector: 'app-score-filter',
    templateUrl: './score-filter.component.html',
    styleUrls: ['./score-filter.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ScoreFilterComponent,
            multi: true,
        },
        TitleCasePipe,
    ],
    standalone: false,
})
export class ScoreFilterComponent implements OnInit, ControlValueAccessor {
    @Input() withLabels = false;
    @Input() multiple = false;
    @Input() onlyIncludedPostsFilterEnabled = false;
    @Output() changeScore = new EventEmitter<number[]>();

    scoreWording$: Observable<ScoreWording>;
    scoreControl: FormControl<number[] | number>;
    private appStateService = inject(AppStateService);
    private titleCasePipe = inject(TitleCasePipe);
    private valueBeforeClick: number[] | number | null;
    private untilDestroy = untilDestroyed();

    ngOnInit(): void {
        this.initControl();
        this.scoreWording$ = this.appStateService.siloScoreWording$.pipe(
            map((scoreWording: ScoreWording) => {
                if (!scoreWording) return null;
                return {
                    word: `${scoreWording.word} score`,
                    levels: scoreWording.levels,
                };
            }),
        );
        this.scoreControl.valueChanges
            .pipe(
                this.untilDestroy(),
                startWith(<number>null),
                pairwise(),
                tap(([oldValue, newValue]) => {
                    this.valueBeforeClick = oldValue;
                    this.onChange(newValue);
                    this.onChangeScoreFilter(newValue);
                    this.onTouched();
                }),
            )
            .subscribe();
    }

    onChange: (value: number[] | number) => void = () => {};
    onTouched: () => void = () => {};
    writeValue(obj: any): void {
        this.scoreControl.setValue(obj);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.scoreControl.disable();
        } else {
            this.scoreControl.enable();
        }
    }

    onChangeScoreFilter(newVal) {
        this.changeScore.emit(newVal);
    }

    resetScoreFilter() {
        this.scoreControl.reset([], { emitEvent: false });
    }

    onClick(score: number) {
        if (!this.multiple) this.unselectIfSameValue(score);
    }

    private unselectIfSameValue(clickedScore: number) {
        if (this.valueBeforeClick === clickedScore) {
            this.scoreControl.setValue(null);
        } else {
            this.valueBeforeClick = clickedScore;
        }
    }

    private initControl() {
        if (this.multiple) {
            this.scoreControl = new FormControl<number[]>([]);
        } else {
            this.scoreControl = new FormControl<number>(null);
        }
    }

    get isDisabled() {
        return !!this.onlyIncludedPostsFilterEnabled;
    }

    get tooltipText$() {
        return this.scoreWording$.pipe(
            map((wording) => {
                if (this.onlyIncludedPostsFilterEnabled) {
                    return "Functionality can't be used with Show Only Included Posts filter";
                } else {
                    return this.titleCasePipe.transform(wording?.word);
                }
            }),
        );
    }
}
