<mat-list-item class="item-header" [ngClass]="post.isExpanded ? 'expanded' : ''">
    <div class="breach_wrap">
        @if (post.data.is_read !== true) {
            <span class="read"></span>
        }
        @if (!reader) {
            <app-post-type-image [post]="post"></app-post-type-image>
        }
        @if (!post.isExpanded && !reader) {
            <div class="breach_text_wrap">
                <div class="info-wrapper">
                    <div class="info-wrapper-row">
                        <span class="breach_text_item bank">{{ post.data.identifier }}</span>
                    </div>
                    <div class="info-wrapper-row details">
                        @if (post.data.breach?.name) {
                            <span class="breach_text_item name"
                                >Breach:
                                <span class="breach_span_item">{{ post.data.breach.name }}</span>
                            </span>
                        }
                        @if (post.type == 'breach' && post.data?.indexed_at) {
                            <span class="breach_text_item action index-date"
                                >Index date:
                                <span class="breach_span_item">
                                    {{
                                        dateService.setDateISO8601(post.data.indexed_at)
                                            | date: dateService.getDateFormat()
                                    }}
                                </span>
                            </span>
                        }
                        @if (post.data.breach?.breach_date) {
                            <span class="breach_text_item action breach-date"
                                >Breach date:
                                <span class="breach_span_item">
                                    {{
                                        dateService.setDateISO8601(post.data.breach.breach_date)
                                            | date: dateService.getDateFormat(true)
                                    }}
                                </span>
                            </span>
                        }
                        @if (post.data.url) {
                            <span [matTooltip]="post.data.url">
                                URL: <b>{{ post.data.url }}</b>
                            </span>
                        }
                        @if (post.data.is_manual) {
                            <span>Added manually</span>
                        }
                        @if (post.data.previously_distributed && curatorMode) {
                            <span
                                ><b>Distributed</b>
                                <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                            ></span>
                        }
                    </div>
                </div>
            </div>
        }
        @if (post.isExpanded || reader) {
            <div
                class="breach_text_wrap"
                [class.reader]="reader"
                [ngClass]="post.isExpanded || reader ? 'expanded' : ''"
            >
                <div
                    class="header_wrapper"
                    [ngClass]="post.isExpanded ? 'expanded' : ''"
                    [class.distributed]="post.data.previously_distributed && post.isExpanded && curatorMode"
                >
                    @if (post.isExpanded) {
                        <app-post-type-label [post]="post"></app-post-type-label>
                    }

                    <div class="breach_title_container row">
                        <span class="breach_text_item">{{ post.data.identifier }}</span>
                        <!--<span class="breach_text_item">Breach name: <span class="breach_span_item">{{ post.data.breach.name }}</span></span>-->
                    </div>

                    @if (post.data.previously_distributed && post.isExpanded && curatorMode) {
                        <span class="item_distributed"
                            ><b>Distributed</b>
                            <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                        ></span>
                    }
                </div>
            </div>
        }
    </div>
</mat-list-item>
