<div
    class="uploader"
    ondragover="return false;"
    [class.loaded]="loaded"
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (drop)="handleDrop($event)"
>
    <label class="imageUploaderWrapper">
        <div class="imageUploader">
            @if (!imageLoaded) {
                <div class="noCoverImage">
                    <div class="noImgWrapper">
                        <div class="noImg">No image</div>
                        {{ defaultText }}
                    </div>
                </div>
            }
            <img [src]="imageSrc" (load)="handleImageLoad()" [class.loaded]="imageLoaded" />
            @if (imageLoaded) {
                <div class="categoryName">{{ categoryName }}</div>
            }
        </div>
        <div class="error-msg" [ngClass]="{ fadeIn: invalidFile }">{{ failureMessage }}</div>

        <input
            type="file"
            name="file"
            accept="image/*"
            (change)="handleInputChange($event)"
            class="fileUploadInput"
            #fileUploadDrag
        />
    </label>
    <label class="actionBtnsUploader">
        <div class="upload_btns">
            @if (!imageLoaded) {
                <div class="uploadImage btn">
                    <mat-icon class="size-auto"><i class="fas fa-upload"></i></mat-icon>
                    Upload image
                </div>
            }
            @if (imageLoaded) {
                <div class="changeImage btn">
                    <mat-icon class="size-auto"><i class="fas fa-pen"></i></mat-icon>
                    Change image
                </div>
            }
            @if (imageLoaded) {
                <div
                    class="deletImage btn"
                    (click)="removeImage($event); fileUploadInput.value = ''; fileUploadDrag.value = ''"
                >
                    <mat-icon class="size-auto"><i class="fad fa-trash-alt"></i></mat-icon>
                    Delete image
                </div>
            }
        </div>
        <input
            type="file"
            name="file"
            accept="image/*"
            (change)="handleInputChange($event)"
            class="fileUploadInput"
            #fileUploadInput
        />
    </label>
</div>
