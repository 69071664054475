import { Component, computed, inject } from '@angular/core';
import { AiTemplateConfig, ModalType } from '../ai-analytics.interfaces';
import { AIAnalyticsService } from '../ai-analytics.service';
import { AIEvaluationStore } from '../analytics-modals-template/ai-modals-template.store';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-ai-report',
    templateUrl: './ai-report.component.html',
    providers: [
        {
            provide: AIAnalyticsService,
            useFactory: () => new AIAnalyticsService(ModalType.REPORT),
        },
        AIEvaluationStore,
        DecimalPipe,
    ],
    standalone: false,
})
export class AIReportComponent {
    private store = inject(AIEvaluationStore);
    private decimalPipe = inject(DecimalPipe);

    readonly templateConfig = computed<AiTemplateConfig>(() => {
        const reportTypes = this.store.$reportTypes();
        const formattedCreditRate = this.decimalPipe.transform(reportTypes.summary_report.credit_rate, '1.0-2'); // Format the number

        return {
            staticText: {
                title: 'Report Generation',
                actionDescription: `Clicking send below will send you an AI generated report based on the included
                posts (up to <b>${reportTypes.summary_report.max_posts}</b> posts). Each post processed will spend <b>${formattedCreditRate}</b> credits from your account. 
                <br>Once the button is clicked the report will be sent to the email of your user within 5
                minutes. This action will cause the posts to be deselected. Note: This is an alpha version of this
                feature.`,
                submitButton: 'Send',
                confirmation: 'A report will be sent to your email within 5 minutes.',
            },
            submitTrackingName: 'send-report',
            maxPosts: reportTypes.summary_report.max_posts,
            creditRate: reportTypes.summary_report.credit_rate,
        };
    });
}
