<div class="post_brief_actions row-content">
    @if (post.data.images && post.data.images.length) {
        <app-product-images-slider class="app-product-images-slider" [postData]="post.data"></app-product-images-slider>
    }

    <div class="dates_actions">
        @if (post.data.is_manual) {
            <div class="post_source">
                <span class="label">Added manually</span>
            </div>
        }

        <div class="post_dates">
            @if (post.data.publish_date || post.data.published_at) {
                <div class="date">
                    <span class="label">Date of Origin: </span>
                    @if (dateService.setDateISO8601(post.data.publish_date || post.data.published_at)) {
                        <span
                            >{{ dateService.getDateDiff('' + (post.data.publish_date || post.data.published_at)) }}
                            &nbsp; / &nbsp;
                            {{
                                dateService.setDateISO8601(post.data.publish_date || post.data.published_at)
                                    | date: dateService.getDateFormat()
                            }}</span
                        >
                    }
                </div>
            }
        </div>
        @if (post.data.source) {
            <div class="post_source">
                <span class="label">Source: </span>
                <span>{{ post.data.source }}</span>
            </div>
        }
        @if (curatorMode && post.data.author) {
            <div class="post_source">
                <span class="label">Author: </span>
                <span>{{ post.data.author }}</span>
            </div>
        }
        @if (post.data.price) {
            <div class="post_source">
                <span class="label">Price: </span>
                <span>{{ post.data.price }}</span>
            </div>
        }
        @if (post.data.url) {
            <div class="post_source post_url">
                <span class="label">URL: </span>
                <span class="url">{{ post.data.url }}</span>
            </div>
        }
    </div>
</div>
