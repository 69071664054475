import { Component, inject, Input } from '@angular/core';
import { DateService } from '@app/core/services';
import { Post } from '@app/data/models';

@Component({
    selector: 'app-darkweb-header',
    templateUrl: './darkweb-header.component.html',
    styleUrls: ['./darkweb-header.component.scss'],
    standalone: false,
})
export class DarkwebHeaderComponent {
    @Input() post: Post;
    @Input() curatorMode: boolean;
    dateService = inject(DateService);
}
