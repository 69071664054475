import { Component, Input } from '@angular/core';
import { TagItem } from '@app/data/models';

@Component({
    selector: 'app-tag-item',
    templateUrl: './tag-item.component.html',
    styleUrls: ['./tag-item.component.scss'],
    standalone: false,
})
export class TagItemComponent {
    @Input() tag: TagItem;
    @Input() sizeClass: 'size-md' | 'size-lg' = 'size-md';
    @Input() selected: boolean;
    @Input() editable: boolean;
    @Input() locked: boolean;
    @Input() interactable: boolean;
    @Input() draggable: boolean;
}
