import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {
    constructor(private apiService: ApiService) {}

    loadFlags() {
        return this.apiService.get('/get-feature-flags', { withCredentials: true });
    }
}
