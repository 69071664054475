import { Component, OnInit, Input, inject } from '@angular/core';
import { creditCardTypes } from '@app/core/constants/creditCardTypes';
import { DateService } from '@app/core/services';

@Component({
    selector: 'app-credit-card-header',
    templateUrl: './credit-card-header.component.html',
    styleUrls: ['./credit-card-header.component.scss'],
    standalone: false,
})
export class CreditCardHeaderComponent implements OnInit {
    @Input() post;
    @Input() channel;
    @Input() curatorMode;

    creditCardTypes = creditCardTypes;
    cardTypes = [];

    readonly dateService = inject(DateService);

    ngOnInit() {
        this.cardTypes = Object.keys(this.creditCardTypes);
    }
}
