import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-empty-page',
    templateUrl: './empty-page.component.html',
    styleUrls: ['./empty-page.component.scss'],
    standalone: false,
})
export class EmptyPageComponent {
    @Input() matIconClass: string;
    @Input() img;
    @Input() appTitle;
    @Input() description;
    @Input() buttonName;
    @Input() hidePlus: boolean;
    @Input() checkAdminRights;
    @Input() hasAdminRights;
    @Input() canAccessSuperadminSection;
    @Input() linkName;
    @Input() buttonTestId;

    @Output() buttonClickEvent = new EventEmitter();
    @Output() linkClickEvent = new EventEmitter();

    buttonClick() {
        this.buttonClickEvent.emit();
    }

    linkClick() {
        this.linkClickEvent.emit();
    }

    showButton() {
        return this.checkAdminRights ? this.hasAdminRights || this.canAccessSuperadminSection : true;
    }
}
