export default interface User {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    permissions_key: Record<string, boolean>;
    username: string;
    is_superuser: boolean;
    is_silo_admin: boolean;
    is_organisation_admin: boolean;
    is_organisation_user: boolean;
}

export interface UpdatedUserDetails {
    email?: string;
    first_name?: string;
    last_name?: string;
    username?: string;
}

export interface UsersResponce {
    users: User[];
    count: number;
}

export type UsersPreferences = Record<number, UserPreferences>;

export enum UserPreferencesKeys {
    UnselectAllPostsWithoutConfirmation = 'unselectAllPostsWithoutConfirmation',
    HideIncludeRangeHint = 'hideIncludeRangeHint',
}
export interface UserPreferences {
    [UserPreferencesKeys.UnselectAllPostsWithoutConfirmation]?: boolean;
    [UserPreferencesKeys.HideIncludeRangeHint]?: boolean;
}
