import { ElementRef, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorScrollService {
    constructor(public el: ElementRef) {}

    scrollToFirstInvalidControl(form, nativeElement) {
        form.markAllAsTouched();

        setTimeout(() => {
            const firstErrorControl: HTMLElement = nativeElement.querySelector('form .error-msg');
            const errorFormGroup = firstErrorControl?.closest('.form-group');
            if (firstErrorControl !== null && errorFormGroup) {
                errorFormGroup.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }, 0);
    }
}
