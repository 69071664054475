import { environment } from '@env/environment';
import { MatomoConfiguration, MatomoRouterConfiguration } from 'ngx-matomo-client';

//Matomo Configs
const disabled = environment.matomo_enabled !== 'true' && localStorage.getItem('ms4::force_tracking') !== 'true';
export const notTrackableDomains = ['marketscape.com', 'munit.io'];
export const matomoRouterConfig: MatomoRouterConfiguration = { delay: 1000 };
export const matomoTrackingConfig: MatomoConfiguration = {
    trackerUrl: environment.matomo_url,
    siteId: environment.matomo_site_id,
    scriptUrl: `${environment.matomo_url}/matomo.js`,
    disabled,
};

// Pendo Configs
export const pendoApiKey = environment.pendo_api_key;
