import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-product-images-slider',
    templateUrl: './product-images-slider.component.html',
    styleUrls: ['./product-images-slider.component.scss'],
    standalone: false,
})
export class ProductImagesSliderComponent implements OnInit {
    @Input() postData;
    imageIndex = 0;
    data;

    ngOnInit() {
        this.data = this.postData;
    }

    changeProductImageIncrease(imageIndex, imageLength) {
        this.imageIndex = this.imageIndex >= imageLength - 1 ? 0 : this.imageIndex + 1;
    }

    changeProductImageDecrese(imageIndex, imageLength) {
        this.imageIndex = this.imageIndex <= 0 ? imageLength - 1 : this.imageIndex--;
    }

    onImgError(event, image) {
        const index = this.data.images.findIndex((i) => i === image);
        this.data.images.splice(index, 1);
    }
}
