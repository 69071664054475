import { Component, Input } from '@angular/core';
import { MatIconClassByType } from '@app/core/constants/matIconClassByType';

@Component({
    selector: 'app-post-type-label',
    templateUrl: './post-type-label.component.html',
    styleUrls: ['./post-type-label.component.scss'],
    standalone: false,
})
export class PostTypeLabelComponent {
    @Input() post;
    matIconClassByType = MatIconClassByType;
    postTypes = {
        news: 'News',
        social_media: 'Social media',
        forum: 'Forum',
        marketplace: 'Markets',
        datadump: 'Datadump',
        whois: 'Whois',
        breach: 'Data breach',
        creditcard: 'Card',
        exposed_services: 'Exposed Services',
        darkweb: 'Darkweb',
        stealers_leaks: 'Stealers & Leaks',
    };
}
