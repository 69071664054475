<div class="content modal-component">
    <div class="header">
        <div class="title">
            Move <b>{{ items.length }} {{ itemsTypeName }}</b> to Category
        </div>
    </div>
    <div class="content">
        <mat-form-field class="app-mat-form-field" appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select [formControl]="categoryControl" data-testid="category-name">
                @for (category of categories; track category) {
                    <mat-option [value]="category">
                        {{ category.name }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    <div class="form_btns">
        <button class="ms-button btn cancel" data-testid="decline-dialog" [mat-dialog-close]="false" mat-flat-button>
            Cancel
        </button>
        <button
            class="primary_btn btn"
            data-testid="confirm-dialog"
            [mat-dialog-close]="categoryControl.value"
            [disabled]="!categoryControl.value"
            mat-raised-button="mat-raised-button"
            color="primary"
        >
            Move
        </button>
    </div>
</div>
