import { Component, inject, Input } from '@angular/core';
import { DateService } from '@app/core/services';
import { Channel, Post, WhoisPost } from '@app/data/models';

@Component({
    selector: 'app-whois-header',
    templateUrl: './whois-header.component.html',
    styleUrls: ['./whois-header.component.scss'],
    standalone: false,
})
export class WhoisHeaderComponent {
    @Input() post: WhoisPost;
    @Input() channel: Channel;
    @Input() curatorMode: boolean;
    @Input() reader: boolean;

    readonly dateService = inject(DateService);
}
