<mat-list-item class="item-header" [ngClass]="post.isExpanded ? 'expanded' : ''">
    <div class="whois_wrap">
        @if (post.data.is_read !== true) {
            <span class="read"></span>
        }
        @if (!reader) {
            <app-post-type-image [post]="post"></app-post-type-image>
        }

        <!--Collapsed header-->
        @if (!post.isExpanded && !reader) {
            <div class="whois_text_wrap">
                <div class="header_wrapper">
                    <div class="info-wrapper">
                        <span class="whois_text_item name"
                            >{{ post.data.domain
                            }}<span class="whois_span_item">{{ '(' + post.data.action + ')' || '' }}</span></span
                        >
                        <div class="whois_text_item action item-info">
                            @if (post.data.crawled_at) {
                                <span
                                    matTooltip="{{
                                        dateService.setDateISO8601(post.data.crawled_at)
                                            | date: dateService.getDateFormat()
                                    }}"
                                    matTooltipPosition="above"
                                >
                                    Crawled:
                                    <span class="whois_span_item">{{
                                        dateService.getDateDiff('' + post.data.crawled_at)
                                    }}</span>
                                </span>
                            }
                            <span class="event-date"
                                >Event date:
                                <span class="whois_span_item">
                                    {{
                                        post.data.publish_date
                                            ? (dateService.setDateISO8601(
                                                  post.data.publish_date || post.data.crawled_at
                                              ) | date: 'd MMMM y')
                                            : ''
                                    }}
                                </span>
                            </span>

                            @if (post.data.is_manual) {
                                <span class="whois_span_item"></span>
                                <span>Added manually</span>
                            }
                            @if (post.data.previously_distributed && curatorMode) {
                                <span class="whois_span_item"></span>
                                <span
                                    >Distributed
                                    <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                                ></span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }

        <!--Expanded header-->
        @if (post.isExpanded) {
            <div
                class="whois_text_wrap"
                [ngClass]="post.isExpanded ? 'expanded' : ''"
                [class.distributed]="post.data.previously_distributed && post.isExpanded && curatorMode"
            >
                @if (post.isExpanded) {
                    <app-post-type-label [post]="post"></app-post-type-label>
                }

                <div class="header_wrapper">
                    <div class="whois_title_expanded">
                        <span class="whois_text_item"
                            >{{ post.data.domain }}
                            <span class="whois_span_item">{{ '(' + post.data.action + ')' || '' }}</span>
                        </span>

                        @if (post.data.previously_distributed && post.isExpanded && curatorMode) {
                            <span class="item_distributed"
                                ><b>Distributed</b>
                                <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                            ></span>
                        }
                    </div>
                </div>
            </div>
        }

        <!--Reader mode-->
        @if (reader) {
            <div class="whois_text_wrap reader" [ngClass]="reader ? 'expanded' : ''">
                <div class="whois_title_container">
                    <span class="whois_text_item reader">{{ post.data.domain }}</span>
                </div>

                @if (post.data.is_manual) {
                    <div class="whois_title_line_container">
                        <span class="manually">Added manually</span>
                    </div>
                }
            </div>
        }
    </div>
</mat-list-item>
