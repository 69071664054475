import { PostTypes } from '@app/shared/interfaces/post-type.interface';

export const ArticleConfigs: Partial<Record<PostTypes, ArticleConfig>> = {
    [PostTypes.news]: {
        title: 'News',
        addPostMethod: 'createNewsArticle',
    },
    [PostTypes.marketplace]: {
        title: 'Marketplace',
        addPostMethod: 'createMarketplaceArticle',
    },
    [PostTypes.socialMedia]: {
        title: 'Social Media',
        addPostMethod: 'createSocialMediaArticle',
    },
    [PostTypes.forum]: {
        title: 'Forum',
        addPostMethod: 'createForumArticle',
    },
    [PostTypes.whois]: {
        title: 'Whois',
        addPostMethod: 'createWhoisArticle',
    },
    [PostTypes.creditcard]: {
        title: 'Credit card',
        addPostMethod: 'createCreditCardArticle',
    },
};

export interface ArticleConfig {
    title: string;
    addPostMethod: string;
}
