@if (failureMessage) {
    <div class="failure_alert_container">
        @if (failureMessage) {
            <app-alert (closed)="failureMessage = null">{{ failureMessage }}</app-alert>
        }
    </div>
}
@if (selectedType) {
    <div class="forms">
        <app-article-container
            [post]="post"
            [edit]="data?.edit"
            [postType]="selectedType.value"
            (closeModal)="close($event)"
        ></app-article-container>
    </div>
} @else {
    <div class="header_details type-selection-page">
        <div class="title">Add post</div>
    </div>

    <div class="types-container">
        @for (type of articleTypes; track type) {
            <div class="type-selection" (click)="selectArticleType(type)" [attr.data-testid]="type.value">
                <div class="image-container">
                    <mat-icon class="outline-item-icon size-auto"><i [ngClass]="type['icon-class']"></i></mat-icon>
                </div>

                <div class="name">{{ type.title }}</div>
                <div class="plus">
                    <mat-icon class="size-auto"><i class="fal fa-plus-circle"></i></mat-icon>
                </div>
                <div class="supported">{{ type.supported ? '' : 'Not supported yet' }}</div>

                @if (!type.supported) {
                    <div class="overlay"></div>
                }
            </div>
        }
    </div>
}
