import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-category-item',
    templateUrl: './category-item.component.html',
    styleUrls: ['./category-item.component.scss'],
    standalone: false,
})
export class CategoryItemComponent {
    @Input() itemType: 'tags' | 'searches';
    @Input() item;
    @Input() size: 'size-md' | 'size-lg' = 'size-md';
    @Input() selected: boolean;
    @Input() editable: boolean;
    @Input() locked: boolean;
    @Input() interactable: boolean;
    @Input() draggable: boolean;
}
