import { createReducer, on } from '@ngrx/store';
import * as userActions from './user.actions';

import { UserInfo, Silo, UsersPreferences, SiloItem } from '@app/data/models';
import { UsersPreferencesLSKey } from '@app/core/constants/userPreferences';

export enum SwitchSiloStatus {
    INITIAL,
    UPDATING_SILO_INFO,
    DONE,
}
export enum RequestStatus {
    INITIAL,
    PENDING,
    ERROR,
    DONE,
}

export interface UpdateSiloStatus {
    status: RequestStatus;
    error?: unknown;
}
export interface UserState {
    userInfo?: UserInfo;
    signUpEmail?: string;
    setUserSiloErrorMessage?: string;
    trialDays?: string;
    userSilos: SiloItem[];
    switchSiloStatus: SwitchSiloStatus;
    updateSiloStatus: UpdateSiloStatus;
    usersPreferences: UsersPreferences;
    isAuthorized: boolean;
}

const usersPreferencesStringified: string | null = localStorage.getItem(UsersPreferencesLSKey);
const initialUserState: UserState = {
    userSilos: [],
    switchSiloStatus: SwitchSiloStatus.INITIAL,
    updateSiloStatus: { status: RequestStatus.INITIAL },
    usersPreferences: usersPreferencesStringified ? JSON.parse(usersPreferencesStringified) : {},
    isAuthorized: Boolean(localStorage.getItem('ms4::is_logged_in')),
};

export const userReducer = createReducer(
    initialUserState,
    on(userActions.getUserInfoSuccess, (state, { userInfo }): UserState => ({ ...state, userInfo: userInfo })),

    on(userActions.resetUserInfo, (state): UserState => ({ ...state, userInfo: undefined })),

    on(
        userActions.updateUserSilo,
        (state): UserState => ({ ...state, updateSiloStatus: { status: RequestStatus.PENDING } }),
    ),

    on(
        userActions.updateUserSiloError,
        (state, { error }): UserState => ({ ...state, updateSiloStatus: { status: RequestStatus.ERROR, error } }),
    ),

    on(userActions.updateUserSiloSuccess, (state, { updatedSilo }): UserState => {
        const silo = { ...state.userInfo.silo, ...updatedSilo };
        const userInfo = { ...state.userInfo, silo };
        return { ...state, userInfo, updateSiloStatus: { status: RequestStatus.DONE } };
    }),

    on(userActions.setUserSilo, (state): UserState => {
        return { ...state, setUserSiloErrorMessage: undefined };
    }),

    on(userActions.setUserSiloSuccess, (state, { userSilo }): UserState => {
        const userInfo = { ...state.userInfo, silo: userSilo };
        return { ...state, userInfo };
    }),

    on(userActions.setUserSiloError, (state, { errorMessage }): UserState => {
        return { ...state, userInfo: undefined, setUserSiloErrorMessage: errorMessage };
    }),

    on(userActions.logoutUserSuccess, (state): UserState => {
        const purgedState = { ...state, isAuthorized: false };
        delete purgedState.userInfo;
        return purgedState;
    }),

    on(userActions.updateUserDetailsSuccess, (state, { updatedUserDetails }): UserState => {
        const user = { ...state.userInfo.user, ...updatedUserDetails };
        const userInfo = { ...state.userInfo, user };
        return { ...state, userInfo };
    }),

    on(userActions.logoutUserSuccess, (state): UserState => {
        return { ...state, userInfo: undefined, signUpEmail: undefined };
    }),

    on(userActions.setTrialDays, (state, { trialDays }): UserState => {
        return { ...state, trialDays };
    }),

    on(userActions.getUserSilosSuccess, (state, { silos }): UserState => {
        return { ...state, userSilos: silos };
    }),

    on(
        userActions.switchUserSilo,
        (state): UserState => ({ ...state, switchSiloStatus: SwitchSiloStatus.UPDATING_SILO_INFO }),
    ),

    on(userActions.switchSiloCompleted, (state): UserState => ({ ...state, switchSiloStatus: SwitchSiloStatus.DONE })),

    on(
        userActions.updateUsersPreferencesSuccess,
        (state, { preferences }): UserState => ({ ...state, usersPreferences: preferences }),
    ),
);
