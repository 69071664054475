import { Component, Input, inject } from '@angular/core';
import { DateService } from '@app/core/services';
import { ShodanService } from '../shodan.service';
import { ShodanPostViewModel } from '../shodan.model';

@Component({
    selector: 'app-shodan-header',
    templateUrl: './shodan-header.component.html',
    styleUrls: ['./shodan-header.component.scss'],
    standalone: false,
})
export class ShodanHeaderComponent {
    @Input() post;
    @Input() channel;
    @Input() curatorMode;
    @Input() reader;

    postViewData: ShodanPostViewModel;

    readonly dateService = inject(DateService);
    readonly shodanService = inject(ShodanService);

    ngOnInit() {
        this.postViewData = this.shodanService.getPostViewData(this.post);
    }
}
