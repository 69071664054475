import { Component, Input, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoaderService, DateService, UtilsService } from '@app/core/services';
import { LeaksViewPost, Post } from '@app/data/models';
import { ArticleService } from '@app/data/services/article.service';

@Component({
    selector: 'app-leaks-body',
    templateUrl: './leaks-body.component.html',
    styleUrls: ['./leaks-body.component.scss'],
    standalone: false,
})
export class LeaksBodyComponent {
    @Input() set post(post: Post) {
        this.leaksViewPost = post as any as LeaksViewPost;
    }
    @Input() reader: boolean;

    leaksViewPost: LeaksViewPost;
    public dateService = inject(DateService);
    private articleService = inject(ArticleService);
    private utilsService = inject(UtilsService);
    private snackbar = inject(MatSnackBar);
    private loaderService = inject(LoaderService);

    onDownloadFile() {
        this.loaderService.showLoader();
        this.articleService.downloadFile(this.leaksViewPost.data.storage_id, this.leaksViewPost.data.bucket).subscribe({
            next: (data) => {
                this.utilsService.downloadBlob(data, this.getFileName());
            },
            error: () => {
                this.snackbar.open('Error downloading file', 'Close', {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    panelClass: 'error',
                });
            },
            complete: () => {
                this.loaderService.hideLoader();
            },
        });
    }

    private getFileName(): string {
        const fileName = this.leaksViewPost.data.file_name?.split('.')[0];
        return fileName ? `${fileName}.txt` : 'file.txt';
    }
}
