<div class="header_content no_image">
    <div class="post_details">
        <div class="title">
            {{ selectedPostReader.data.title }}
        </div>
    </div>
    <div colspan="10" rowspan="1" class="post_details">
        <div mat-line class="date_author">
            @if (selectedPostReader.data.score?.dominant_score != null) {
                <app-post-score-icon [postData]="selectedPostReader.data" class="reader-score" />
                &nbsp; / &nbsp;
            }
            @if (selectedPostReader.data.publish_date) {
                <span
                    matTooltip="{{
                        dateService.setDateISO8601(selectedPostReader.data.publish_date)
                            | date: dateService.getDateFormat()
                    }}"
                    matTooltipPosition="above"
                >
                    <b>{{ dateService.getDateDiff('' + selectedPostReader.data.publish_date) }}</b>
                </span>
            }
            @if (selectedPostReader.data.publish_date) {
                <span>
                    &nbsp; / &nbsp;
                    {{
                        dateService.setDateISO8601(selectedPostReader.data.publish_date)
                            | date: dateService.getDateFormat()
                    }}</span
                >
            }
            @if (selectedPostReader.data.author) {
                <span>
                    &nbsp; / &nbsp; Author: <b>{{ selectedPostReader.data.author }}</b></span
                >
            }
            @if (selectedPostReader.data.is_manual) {
                <span> &nbsp; / &nbsp;<b>Added manually</b></span>
            }
        </div>
    </div>
</div>
