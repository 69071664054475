@if (selectedPostReader.data?.searches?.length) {
    <div class="post_tags">
        <mat-accordion displayMode="flat">
            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                <mat-expansion-panel-header>
                    <div class="label">
                        <mat-icon class="size-auto"><i class="fal fa-radar"></i></mat-icon> Searches
                    </div>
                </mat-expansion-panel-header>
                <ul class="selected_tags_list">
                    @for (search of selectedPostReader.data.searches; track search) {
                        <li [attr.tag-id]="search.id">
                            <app-search-item [search]="search" [searchType]="selectedPostReader.type"></app-search-item>
                        </li>
                    }
                </ul> </mat-expansion-panel
        ></mat-accordion>
    </div>
}
@if (selectedPostReader.tags && selectedPostReader.tags.length) {
    <div class="post_tags">
        <mat-accordion displayMode="flat"
            ><mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                <mat-expansion-panel-header>
                    <div class="label">
                        <mat-icon class="size-auto"><i class="fal fa-tag"></i></mat-icon> Tags
                    </div>
                </mat-expansion-panel-header>
                <ul class="selected_tags_list">
                    @for (tag of selectedPostReader.tags; track tag) {
                        <li [attr.tag-id]="tag.id">
                            <app-tag-item [tag]="tag"></app-tag-item>
                        </li>
                    }
                </ul> </mat-expansion-panel
        ></mat-accordion>
    </div>
}
@if (currentLocation) {
    <div class="location_item">
        <mat-icon><i class="fal fa-globe"></i></mat-icon>
        <span>Location</span>
    </div>

    <div class="location">
        <div id="location_map" #locationMap>{{ locationMap }}</div>
    </div>
}
