import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStateService } from '@app/core/services';
import { UserPreferencesKeys } from '@app/data/models';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: false,
})
export class ConfirmationDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            header: string;
            message: string;
            confirm?: string;
            preferencesKey?: string;
        },
        private appStateService: AppStateService,
    ) {}

    onDoNotAskChanged(event: MatCheckboxChange) {
        this.appStateService.updateUserPreferences({
            [UserPreferencesKeys.UnselectAllPostsWithoutConfirmation]: event.checked,
        });
    }
}
