import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';
import { Post } from '@app/data/models';
import { ArticleService } from '@app/data/services/article.service';
import { SnackBarComponent } from '@app/shared/components/snack-bar/snack-bar.component';
import { PostTypes } from '@app/shared/interfaces/post-type.interface';
import { of, Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ArticleFields } from './article-fields';
import { ArticleConfig, ArticleConfigs } from './article-configs';

@Component({
    selector: 'app-article-container',
    templateUrl: './article-container.component.html',
    styleUrls: ['./article-container.component.scss'],
    providers: [DatePipe],
    standalone: false,
})
export class ArticleContainerComponent {
    @Input() post: Post;
    @Input() edit: boolean;
    @Input() postType: keyof typeof PostTypes;
    @Output() closeModal = new EventEmitter();

    errorMessage: any;
    isSubmitting = false;
    formErrors: any;

    private untilDestroy = untilDestroyed();

    constructor(
        private articleService: ArticleService,
        public datePipe: DatePipe,
        private snackBar: MatSnackBar,
    ) {}

    onSubmit(data) {
        this.isSubmitting = true;

        for (const [key] of Object.entries(data)) {
            if (key.toLowerCase().includes('date')) {
                data[key] = this.datePipe.transform(data[key], 'yyyy-MM-dd HH:mm');
            }

            if (key == 'crawled_at') {
                data[key] = this.datePipe.transform(data[key], 'yyyy-MM-dd HH:mm');
            }
        }
        this.sendRequest(data)
            .pipe(
                this.untilDestroy(),
                tap((resp) => {
                    this.snackBar.openFromComponent(SnackBarComponent, {
                        duration: 2000,
                        data: { message: `${this.articleConfig.title} post successfully created.` },
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                    });

                    this.closeModal.emit(resp.id);
                }),
                catchError((error) => {
                    this.formErrors = error.error;
                    return of(error);
                }),
                finalize(() => (this.isSubmitting = false)),
            )
            .subscribe();
    }

    onClose() {
        this.closeModal.emit();
    }

    get typeFields() {
        return ArticleFields[this.postType];
    }

    get articleConfig(): ArticleConfig {
        return ArticleConfigs[this.postType];
    }

    private sendRequest(data): Observable<any> {
        if (this.edit) {
            data.tags = this.getTagsDict(data);
            return this.articleService.editArticle(this.post.data.id, data);
        } else {
            return this.articleService[this.articleConfig.addPostMethod](data);
        }
    }

    private getTagsDict(postData) {
        if (!postData.tags) return [];
        return postData.tags.map((tagId: string) => ({ id: tagId }));
    }
}
