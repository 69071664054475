import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    get hideCreateChannelButton(): boolean {
        return (
            environment.items_to_hide != undefined &&
            environment.items_to_hide.create_channels != undefined &&
            typeof environment.items_to_hide.create_channels === 'boolean' &&
            environment.items_to_hide.create_channels
        );
    }

    get showEditCategory(): boolean {
        return environment.show_edit_category;
    }

    get showEditChannel(): boolean {
        return environment.show_edit_channel;
    }

    get showEditCollection(): boolean {
        return environment.show_edit_collection;
    }

    get showReadAndUnreadPosts(): boolean {
        return environment.show_read_and_unread_posts && environment.show_read_and_unread_posts != undefined;
    }

    get showZendeskChat(): boolean {
        return environment.zendesk_enabled === 'true';
    }
}
