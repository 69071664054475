import { PostTypes } from '@app/shared/interfaces/post-type.interface';

export const ArticleFields = {
    [PostTypes.whois]: [
        {
            name: 'domain',
            type: 'text-input',
            display_text: 'Domain',
            required: true,
        },
        {
            name: 'published_at',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Crawled at',
            required: true,
        },
        {
            name: 'action',
            type: 'radio-button',
            display_text: 'Action',
            required: true,
            options: [
                {
                    name: 'Added',
                    value: 'added',
                },
                {
                    name: 'Dropped',
                    value: 'dropped',
                },
            ],
        },
        {
            name: 'uploadImage',
            type: 'upload-single-image',
            display_text: 'Upload Screenshot',
        },
        {
            name: 'record_id',
            type: 'number',
            display_text: 'Record ID',
            required: false,
        },
        {
            name: 'record_registrant_contactId',
            type: 'number',
            display_text: 'Record registrant contact ID',
        },
        {
            name: 'record_registrant_organization',
            type: 'text-input',
            display_text: 'Record registrant organization',
        },
        {
            name: 'record_registrant_state',
            type: 'text-input',
            display_text: 'Record registrant state',
        },
        {
            name: 'record_registrant_country',
            type: 'text-input',
            display_text: 'Record registrant country',
        },
        {
            name: 'record_registrant_parseCode',
            type: 'number',
            display_text: 'Record registrant parse code',
        },
        {
            name: 'record_administrativeContact_contactId',
            type: 'number',
            display_text: 'Record administrative contact ID',
        },
        {
            name: 'record_administrativeContact_organization',
            type: 'text-input',
            display_text: 'Record administrative organization',
        },
        {
            name: 'record_administrativeContact_state',
            type: 'text-input',
            display_text: 'Record administrative state',
        },
        {
            name: 'record_administrativeContact_country',
            type: 'text-input',
            display_text: 'Record administrative country',
        },
        {
            name: 'record_administrativeContact_parseCode',
            type: 'number',
            display_text: 'Record administrative parse code',
        },
        {
            name: 'record_technicalContact_contactId',
            type: 'number',
            display_text: 'Record technical contact ID',
        },
        {
            name: 'record_technicalContact_organization',
            type: 'text-input',
            display_text: 'Record technical organization',
        },
        {
            name: 'record_technicalContact_state',
            type: 'text-input',
            display_text: 'Record technical state',
        },
        {
            name: 'record_technicalContact_country',
            type: 'text-input',
            display_text: 'Record technical country',
        },
        {
            name: 'record_technicalContact_parseCode',
            type: 'number',
            display_text: 'Record technical parse code',
        },
        {
            name: 'record_createdDate',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Created date',
        },
        {
            name: 'record_updatedDate',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Updated date',
        },
        {
            name: 'record_expiresDate',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Expires date',
        },
        {
            name: 'record_status',
            type: 'radio-button',
            display_text: 'Status',
            options: [
                {
                    name: 'Client update prohibited',
                    value: 'clientUpdateProhibited',
                },
                {
                    name: 'Client transfer prohibited',
                    value: 'clientTransferProhibited',
                },
                {
                    name: 'Client delete prohibited',
                    value: 'clientDeleteProhibited',
                },
                {
                    name: 'Server update prohibited',
                    value: 'serverUpdateProhibited',
                },
                {
                    name: 'Server transfer prohibited',
                    value: 'serverTransferProhibited',
                },
                {
                    name: 'Server delete prohibited',
                    value: 'serverDeleteProhibited',
                },
            ],
        },
        {
            name: 'record_header',
            type: 'text-input',
            display_text: 'Record header',
        },
        {
            name: 'record_footer',
            type: 'text-input',
            display_text: 'Record footer',
        },
        {
            name: 'record_customField1Name',
            type: 'text-input',
            display_text: 'Record custom field1 name',
        },
        {
            name: 'record_customField1Value',
            type: 'text-input',
            display_text: 'Record custom field1 value',
        },
        {
            name: 'record_customField2Name',
            type: 'text-input',
            display_text: 'Record custom field2 name',
        },
        {
            name: 'record_customField2Value',
            type: 'text-input',
            display_text: 'Record custom field2 value',
        },
        {
            name: 'record_customField3Name',
            type: 'text-input',
            display_text: 'Record custom field3 name',
        },
        {
            name: 'record_customField3Value',
            type: 'text-input',
            display_text: 'Record custom field3 value',
        },
        {
            name: 'record_registrarName',
            type: 'text-input',
            display_text: 'Record registrar name',
        },
        {
            name: 'record_registrarIANAID',
            type: 'number',
            display_text: 'Record registrar IANAID',
        },
        {
            name: 'record_whoisServer',
            type: 'text-input',
            display_text: 'Record whois server',
        },
        {
            name: 'record_createdDateNormalized',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Created date normalized',
        },
        {
            name: 'record_updatedDateNormalized',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Updated date normalized',
        },
        {
            name: 'record_expiresDateNormalized',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Expires date normalized',
        },
        {
            name: 'record_dataErrorFlag',
            type: 'radio-button',
            display_text: 'Record Data Error Flag',
            options: [
                {
                    name: 'No',
                    value: '0',
                },
                {
                    name: 'Yes',
                    value: '1',
                },
            ],
        },
        {
            name: 'record_parseCode',
            type: 'number',
            display_text: 'Record parse code',
        },
        {
            name: 'record_contactEmail',
            type: 'text-input',
            display_text: 'Record contact email',
        },
        {
            name: 'record_domainNameExt',
            type: 'text-input',
            display_text: 'Record domain Name Ext',
        },
        {
            name: 'record_estimatedDomainAge',
            type: 'number',
            display_text: 'Record estimated Domain Age',
        },
        {
            name: 'record_audit_createdDate',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Record audit created date',
        },
        {
            name: 'record_audit_updatedDate',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Record audit updated date',
        },
    ],
    [PostTypes.socialMedia]: [
        {
            name: 'title',
            type: 'text-input',
            display_text: 'Title',
            required: true,
        },
        {
            name: 'content',
            type: 'formatted-text-input',
            display_text: 'Content',
            required: false,
        },
        {
            name: 'publish_date',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Publish date',
            required: true,
        },
        {
            name: 'source',
            type: 'text-input',
            display_text: 'Source',
            required: true,
        },
        {
            name: 'url',
            type: 'text-input',
            display_text: 'Url',
            required: true,
        },
        {
            name: 'author',
            type: 'text-input',
            display_text: 'Author',
            required: false,
        },
        {
            name: 'uploadImage',
            type: 'upload-image',
            display_text: 'Upload Images',
        },
    ],
    [PostTypes.news]: [
        {
            name: 'title',
            type: 'text-input',
            display_text: 'Title',
            required: true,
        },
        {
            name: 'publish_date',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Date',
            required: true,
        },
        {
            name: 'source',
            type: 'text-input',
            display_text: 'Source',
            required: true,
        },
        {
            name: 'author',
            type: 'text-input',
            display_text: 'Author',
            required: false,
        },
        {
            name: 'url',
            type: 'text-input',
            display_text: 'Url',
            required: true,
        },
        {
            name: 'abstract',
            type: 'formatted-text-input',
            display_text: 'Abstract',
            required: true,
        },
        {
            name: 'content',
            type: 'formatted-text-input',
            display_text: 'Content',
            required: false,
        },
        {
            name: 'uploadImage',
            type: 'upload-image',
            display_text: 'Upload Images',
        },
    ],
    [PostTypes.creditcard]: [
        {
            name: 'name',
            type: 'text-input',
            display_text: 'Name',
            required: true,
        },
        {
            name: 'bank',
            type: 'text-input',
            display_text: 'Bank',
            required: true,
        },
        {
            name: 'bin',
            type: 'number',
            display_text: 'Bin',
            required: true,
        },
        {
            name: 'city',
            type: 'text-input',
            display_text: 'City',
            required: true,
        },
        {
            name: 'zip',
            type: 'number',
            display_text: 'zip',
            required: true,
        },
        {
            name: 'country',
            type: 'text-input',
            display_text: 'Country',
            required: true,
        },
        {
            name: 'expiry_month',
            type: 'number',
            display_text: 'Expiry month',
            required: true,
        },
        {
            name: 'expiry_year',
            type: 'number',
            display_text: 'Expiry year',
            required: true,
        },
        {
            name: 'has_ac',
            type: 'radio-button',
            required: true,
            display_text: 'ac',
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_address',
            type: 'radio-button',
            display_text: 'address',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_d',
            type: 'radio-button',
            display_text: 'd',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_dob',
            type: 'radio-button',
            display_text: 'dob',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_ip',
            type: 'radio-button',
            display_text: 'ip',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_mmn',
            type: 'radio-button',
            display_text: 'mmn',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_phone',
            type: 'radio-button',
            display_text: 'phone',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_r',
            type: 'radio-button',
            display_text: 'r',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_ssn',
            type: 'radio-button',
            display_text: 'ssn',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'has_ssn4',
            type: 'radio-button',
            display_text: 'ssn4',
            required: true,
            options: [
                {
                    name: 'Yes',
                    value: true,
                },
                {
                    name: 'No',
                    value: false,
                },
            ],
        },
        {
            name: 'mark',
            type: 'text-input',
            display_text: 'mark',
            required: true,
        },
        {
            name: 'price',
            type: 'number',
            display_text: 'price',
            required: true,
        },
        {
            name: 'publish_date',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Publish date',
            required: true,
        },
        {
            name: 'reseller',
            type: 'text-input',
            display_text: 'reseller',
            required: true,
        },
        {
            name: 'source',
            type: 'text-input',
            display_text: 'source',
            required: true,
        },
        {
            name: 'state',
            type: 'text-input',
            display_text: 'state',
            required: true,
        },
        {
            name: 'subtype',
            type: 'text-input',
            display_text: 'subtype',
            required: true,
        },
        {
            name: 'type',
            type: 'text-input',
            display_text: 'type',
            required: true,
        },
        {
            name: 'upload_date',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'upload date',
            required: true,
        },
    ],
    [PostTypes.forum]: [
        {
            name: 'section_name',
            type: 'text-input',
            display_text: 'Title',
            required: true,
        },
        {
            name: 'source',
            type: 'text-input',
            display_text: 'Source',
            required: true,
        },
        {
            name: 'section_description',
            type: 'formatted-text-input',
            display_text: 'Section Description',
            required: true,
        },
        {
            name: 'thread_url',
            type: 'text-input',
            display_text: 'Thread URL',
            required: true,
        },
        {
            name: 'thread_title',
            type: 'text-input',
            display_text: 'Thread Title',
            required: true,
        },
        {
            name: 'author',
            type: 'text-input',
            display_text: 'Author',
            required: false,
        },
        {
            name: 'url',
            type: 'text-input',
            display_text: 'URL',
            required: true,
        },
        {
            name: 'content',
            type: 'formatted-text-input',
            display_text: 'Content',
            required: false,
        },
        {
            name: 'publish_date',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Publish date',
            required: true,
        },
    ],
    [PostTypes.marketplace]: [
        {
            name: 'title',
            type: 'text-input',
            display_text: 'Title',
            required: true,
        },
        {
            name: 'price',
            type: 'number',
            display_text: 'Price',
            required: true,
        },
        {
            name: 'content',
            type: 'formatted-text-input',
            display_text: 'Content',
            required: false,
        },
        {
            name: 'publish_date',
            type: 'date-selector',
            date_format: 'MM/DD/YYYY',
            display_text: 'Publish date',
            required: true,
        },
        {
            name: 'uploadImage',
            type: 'upload-image',
            display_text: 'Upload Images',
        },
        {
            name: 'url',
            type: 'text-input',
            display_text: 'Url',
            required: true,
        },
    ],
};
