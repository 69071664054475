import { Injectable, inject } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { TransactionsResponse } from '../models/transactions.model';
import {
    ChangeSubscriptionPlanResponse,
    StartSubscriptionPlanResponse,
    SubscriptionPlanPricing,
    SubscriptionPlanResponse,
} from '@app/data/models';

const basePath = '/admin';
interface ChangeBalancePayload {
    credit_balance_change: number;
    subscription_log_message: string;
}

interface ChangePlanPayload {
    subscription_plan_id: number;
    subscription_end_date?: string;
    subscription_log_message: string;
}
@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {
    private apiService = inject(ApiService);

    getCurrentSubscription(): Observable<unknown> {
        return this.apiService.get(`${basePath}/subscription/`, { withCredentials: true });
    }

    getTransactioinsLogs(page = 1, collection = []) {
        return this.apiService.get(`${basePath}/transactions/`, {
            withCredentials: true,
            params: {
                page,
                collection,
            },
        }) as unknown as Observable<TransactionsResponse>;
    }

    cancelTransaction(id: number): Observable<unknown> {
        return this.apiService.post(`${basePath}/transactions/${id}/cancel`, null, { withCredentials: true });
    }

    getCurrentSubscriptionPlans(): Observable<unknown> {
        return this.apiService.get(`${basePath}/subscription-plans`, { withCredentials: true });
    }

    startSubscriptionPlan(stripePriceId: string, promotionCodes: string[]): Observable<StartSubscriptionPlanResponse> {
        return this.apiService.post(
            `${basePath}/subscription/plan/`,
            {
                stripe_price_id: stripePriceId,
                promotion_codes: promotionCodes,
            },
            { withCredentials: true },
        ) as any as Observable<StartSubscriptionPlanResponse>;
    }

    changeSubscriptionPlan(
        newStripePriceId: string,
        promotionCodes: string[],
    ): Observable<ChangeSubscriptionPlanResponse> {
        return this.apiService.put(
            `${basePath}/subscription/plan/`,
            {
                stripe_price_id: newStripePriceId,
                promotion_codes: promotionCodes,
            },
            { withCredentials: true },
        ) as any as Observable<ChangeSubscriptionPlanResponse>;
    }

    previewChangeSubscriptionPlan(
        newStripePriceId: string,
        promotionCodes: string[],
    ): Observable<SubscriptionPlanResponse> {
        return this.apiService.post(
            `${basePath}/subscription/plan/preview`,
            {
                stripe_price_id: newStripePriceId,
                promotion_codes: promotionCodes,
            },
            { withCredentials: true },
        ) as any as Observable<SubscriptionPlanResponse>;
    }

    getPricing() {
        return this.apiService.get(`${basePath}/pricing`, {
            withCredentials: true,
        }) as any as Observable<SubscriptionPlanPricing[]>;
    }

    getUsageCost(): Observable<unknown> {
        return this.apiService.get(`${basePath}/usage-costs`, { withCredentials: true });
    }

    changeBalance(data: ChangeBalancePayload): Observable<unknown> {
        return this.apiService.post(`${basePath}/subscription/change-balance`, data, { withCredentials: true });
    }

    changePlan(data: ChangePlanPayload): Observable<unknown> {
        return this.apiService.post(`${basePath}/subscription/change-plan`, data, { withCredentials: true });
    }

    openCustomerPortalSession(): Observable<unknown> {
        return this.apiService.post(`${basePath}/customer-portal-session`, null, { withCredentials: true });
    }
}
