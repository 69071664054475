import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { map, tap } from 'rxjs/operators';
import { Silo } from '@app/data/models';
import { Observable } from 'rxjs';
import { LoginResponse } from '../models/auth.model';

@Injectable({
    providedIn: 'root',
})
export class AuthApiService {
    isLoggedIn = false;
    basePath = '/me';
    resetPasswordBasePath = '';

    private _silos: Silo[];

    constructor(private apiService: ApiService) {}

    login(userCredentials): Observable<LoginResponse> {
        const fd = new FormData();
        fd.append('email', userCredentials.email);
        fd.append('password', userCredentials.password);

        return this.apiService
            .post<LoginResponse>('/login', fd, {
                withCredentials: true,
            })
            .pipe(
                tap(() => {
                    this.isLoggedIn = true;
                    window.localStorage.setItem('ms4::is_logged_in', 'true');
                }),
            );
    }

    isAuthorized(): boolean {
        return Boolean(window.localStorage.getItem('ms4::is_logged_in'));
    }

    getSilos(): Observable<Silo[]> {
        return this.apiService.get(`${this.basePath}/silo`, { withCredentials: true }).pipe(
            tap((silos) => {
                this._silos = silos as unknown as Silo[];
            }),
        ) as unknown as Observable<Silo[]>;
    }

    getUserInfo() {
        return this.apiService.get(this.basePath, { withCredentials: true });
    }

    requestPasswordReset(email: string) {
        const fd = new FormData();
        fd.append('email', email);

        return this.apiService.post('/request-password-reset', fd, {
            withCredentials: true,
        });
    }

    requestPasswordChange(token: string, email: string, newPassword: string) {
        const fd = new FormData();
        fd.append('token', token);
        fd.append('email', email);
        fd.append('new_password', newPassword);

        return this.apiService.post('/request-password-change', fd, {
            withCredentials: true,
        });
    }

    validateToken(token: string, email: string) {
        const fd = new FormData();
        fd.append('token', token);
        fd.append('email', email);

        return this.apiService.post('/validate-reset-token', fd, {
            withCredentials: true,
        });
    }

    validateInviteToken(
        token: string,
        email: string,
        resetPassword: boolean,
        siloId?: string,
        organisationId?: string,
    ) {
        const payload = {
            email,
            token,
            reset_password: resetPassword,
            silo_id: siloId,
            organisation_id: organisationId,
        };

        return this.apiService.post('/validate-invite-token', payload, {
            withCredentials: true,
        });
    }

    acceptInvite(
        token: string,
        email: string,
        resetPassword: boolean,
        password?: string,
        passwordConfirm?: string,
        siloId?: string,
        organisationId?: string,
    ) {
        const payload: any = {
            email,
            token,
            reset_password: resetPassword,
            silo_id: siloId,
            organisation_id: organisationId,
        };

        if (resetPassword) {
            payload.password = password;
            if (passwordConfirm) {
                payload.password_confirm = passwordConfirm;
            }
        }

        return this.apiService.post('/accept-invite', payload, {
            withCredentials: true,
        });
    }

    get silos() {
        return this._silos;
    }

    generateOTP(): Observable<{ otpauth_url: string; base32: string }> {
        return this.apiService.post('/auth/otp/generate', {}, { withCredentials: true });
    }

    verifyOTP(token: string): Observable<boolean> {
        return this.apiService.post('/auth/otp/verify', { token }, { withCredentials: true });
    }

    validateOTP(token: string, temp_token: string): Observable<boolean> {
        return this.apiService.post('/auth/otp/validate', { token, temp_token }, { withCredentials: true });
    }

    disableOTP(): Observable<boolean> {
        return this.apiService.post('/auth/otp/disable', {}, { withCredentials: true });
    }

    getTwoFactorStatus(): Observable<{ '2fa_enabled': boolean }> {
        return this.apiService.get('/auth/otp/status', { withCredentials: true });
    }
}
