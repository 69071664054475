@if (urls.length) {
    <div class="preview_container">
        @for (url of urls; track url; let i = $index) {
            <div class="image_row">
                <img class="image_preview" [src]="url" />

                <button mat-icon-button class="button_delete_item" (click)="deleteUrl(i)">
                    <mat-icon><i class="fal fa-times"></i></mat-icon>
                </button>
            </div>
        }
    </div>
}

<input type="file" class="file" (change)="onSelectFile($event)" multiple #file />
<div class="btns">
    <button class="btn primary_btn" type="button" mat-raised-button (click)="file.click()">Choose files</button>
    @if (urls.length) {
        <button class="btn cancel-button" type="button" mat-flat-button (click)="clear()">Clear</button>
    }
</div>
