import { Injectable, inject, signal } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of, pipe } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

import { PostsFeedService } from './posts-feed.service';
import { ChannelService } from '@app/data/services/channel.service';
import { FeedPost, Channel, Post, TagItem, Search, SearchItem } from '@app/data/models';
import { AppStateService, UtilsService } from '@app/core/services';
import { tapResponse } from '@ngrx/operators';

export enum Statuses {
    initial = 'INITIAL',
    loading = 'LOADING',
    loaded = 'LOADED',
    failed = 'FAILED',
}
export interface PostsFeedState {
    readerMode: boolean;
    postOffset: number;
    endOfPosts: boolean;
    emptyPostsList: boolean;
    initialPostsCount: number;
    postsLoadingStatus: Statuses;
    markAsReadRequestStatus: Statuses;
    selectedChannelId: string;
    filterState: HttpParams;
    posts: FeedPost[];
    channelFeedCount: number;
    totalNumberOfPosts: number;
    allPostsCollapsed: boolean;
    allPostsAreShown: boolean;
    newLineIndex: number;
    channelInfo: Channel;
    executedMarkAllPostsAsReadOrUnReadOnceForChannel: string;
    lastIncludedPost: Post | null;
    includeRangeHintShown: boolean;
}
const POSTS_COUNT = 20;
const defaultState: PostsFeedState = {
    readerMode: false,
    postOffset: 0,
    posts: null,
    channelFeedCount: null,
    totalNumberOfPosts: 0,
    endOfPosts: false,
    initialPostsCount: POSTS_COUNT,
    postsLoadingStatus: Statuses.initial,
    markAsReadRequestStatus: Statuses.initial,
    selectedChannelId: null,
    filterState: new HttpParams(),
    emptyPostsList: false,
    allPostsCollapsed: true,
    allPostsAreShown: false,
    newLineIndex: null,
    channelInfo: null,
    executedMarkAllPostsAsReadOrUnReadOnceForChannel: null,
    lastIncludedPost: null,
    includeRangeHintShown: false,
};

@Injectable()
export class PostsFeedStore extends ComponentStore<PostsFeedState> {
    //Selectors
    readonly postsAreLoading$: Observable<boolean> = this.select(
        (state) => state.postsLoadingStatus === Statuses.loading,
    );
    readonly totalNumberOfPosts$: Observable<number> = this.select((state) => state.totalNumberOfPosts);
    readonly totalNumberOfPostsText$: Observable<string> = this.select((state) =>
        this.postsFeedService.getTotalNumberOfPostsText(state.totalNumberOfPosts),
    );
    readonly allPostsAreShown$: Observable<boolean> = this.select((state) => state.allPostsAreShown);
    readonly newLineIndex$: Observable<number> = this.select((state) => state.newLineIndex);
    readonly postsLoadingStatus$: Observable<Statuses> = this.select((state) => state.postsLoadingStatus);
    readonly emptyPostsList$: Observable<boolean> = this.select((state) => state.emptyPostsList);
    readonly endOfPosts$: Observable<boolean> = this.select((state) => state.endOfPosts);
    readonly readerMode$: Observable<boolean> = this.select((state) => state.readerMode);
    readonly initialPostsCount$: Observable<number> = this.select((state) => state.initialPostsCount);
    readonly selectedChannelId$: Observable<string> = this.select((state) => state.selectedChannelId);
    readonly channelFeedCount$: Observable<number> = this.select((state) => state.channelFeedCount);
    readonly channelInfo$: Observable<Channel> = this.select((state) => state.channelInfo);
    readonly filter$: Observable<HttpParams> = this.select((state) => state.filterState);
    readonly scoreFilterEnabled$: Observable<boolean> = this.select(
        (state) => !!state.filterState.keys().includes('scores'),
    );
    readonly onlyIncludedPostsFilterEnabled$: Observable<boolean> = this.select(
        (state) => !!state.filterState.keys().includes('show_only_included'),
    );
    readonly filtersEnabled$: Observable<boolean> = this.select((state) => !!state.filterState.keys().length);
    readonly postOffset$: Observable<number> = this.select((state) => state.postOffset);
    readonly posts$: Observable<FeedPost[]> = this.select((state) => state.posts);
    readonly allPostsCollapsed$: Observable<boolean> = this.select((state) => state.allPostsCollapsed);
    readonly firstPostIsUnread$: Observable<boolean> = this.select((state) => {
        if (state.posts?.length) {
            const firstPost = state.posts[0];
            return new Date(state.channelInfo.last_read_at) < new Date(firstPost.data.indexed_at);
        }
    });
    readonly firstPostIsRead$: Observable<boolean> = this.select(this.firstPostIsUnread$, (isRead) => !isRead);
    readonly executedMarkAllPostsAsReadOrUnReadOnceForChannel$: Observable<string> = this.select(
        (state) => state.executedMarkAllPostsAsReadOrUnReadOnceForChannel,
    );
    readonly isAllPostsAreShown$: Observable<boolean> = this.select((state) => {
        if (!state.posts) return false;
        return this.postsFeedService.isAllPostsAreShown(state.posts, state.channelInfo, state.endOfPosts, POSTS_COUNT);
    });

    readonly canFetchMorePosts$: Observable<boolean> = this.select(
        (state) => !state.endOfPosts && !!state.selectedChannelId && state.postsLoadingStatus !== Statuses.loading,
    );

    readonly getPostIndex = (post: Post) =>
        this.selectSignal((state) => state.posts)().findIndex((p) => p.data.id === post.data.id);

    readonly getPostIdsBetween = (startIndex: number, endIndex: number) => {
        const start = Math.min(startIndex, endIndex);
        const end = Math.max(startIndex, endIndex);
        return this.selectSignal((state) => state.posts)()
            .slice(start, end + 1)
            .map((post) => post.data.id);
    };

    readonly lastIncludedPost = this.selectSignal((state) => state.lastIncludedPost);
    readonly includeRangeHintShown = this.selectSignal((state) => state.includeRangeHintShown);

    readonly vm$ = this.select(
        this.state$,
        this.firstPostIsUnread$,
        this.postsAreLoading$,
        this.totalNumberOfPostsText$,
        (state, firstPostIsUnread, postsAreLoading, totalNumberOfPostsText) => ({
            readerMode: state.readerMode,
            firstPostIsUnread: firstPostIsUnread,
            channelInfo: state.channelInfo,
            posts: state.posts,
            channelFeedCount: state.channelFeedCount,
            allPostsCollapsed: state.allPostsCollapsed,
            endOfPosts: state.endOfPosts,
            showFeedLoader: postsAreLoading,
            totalNumberOfPosts: state.totalNumberOfPosts,
            totalNumberOfPostsText: totalNumberOfPostsText,
            emptyPostsList: state.emptyPostsList,
            newLineIndex: state.newLineIndex,
            allPostsAreShown: state.allPostsAreShown,
            markAsReadRequestStatus: state.markAsReadRequestStatus,
        }),
    );

    //Updaters
    readonly setIncludeRangeHintShown = this.updater(
        (state): PostsFeedState => ({ ...state, includeRangeHintShown: true }),
    );
    readonly setReaderMode = this.updater((state, readerMode: boolean): PostsFeedState => ({ ...state, readerMode }));
    readonly resetPostOffset = this.updater((state): PostsFeedState => ({ ...state, postOffset: 0 }));
    readonly increasPostOffset = this.updater(
        (state): PostsFeedState => ({ ...state, postOffset: state.postOffset + 1 }),
    );
    readonly setChannelId = this.updater<string>(
        (state, selectedChannelId): PostsFeedState => ({ ...state, selectedChannelId }),
    );
    readonly resetPosts = this.updater((state): PostsFeedState => ({ ...state, posts: [] }));
    readonly setFilter = this.updater<HttpParams>((state, filterState): PostsFeedState => ({ ...state, filterState }));
    readonly initPostsCount = this.updater<void>((state): PostsFeedState => {
        const initialPostsCount = this.postsFeedService.getInitialPostsCount(POSTS_COUNT);
        return { ...state, initialPostsCount };
    });
    readonly resetFeedSettingsState = this.updater(
        (state): PostsFeedState => ({
            ...state,
            filterState: new HttpParams(),
            executedMarkAllPostsAsReadOrUnReadOnceForChannel: null,
            allPostsCollapsed: true,
        }),
    );

    readonly setChannelLastReadAt = this.updater<string>(
        (state, lastReadAt): PostsFeedState => ({
            ...state,
            channelInfo: {
                ...state.channelInfo,
                last_read_at: lastReadAt,
            },
        }),
    );

    readonly updateTotalNumberOfPosts = this.updater<void>((state): PostsFeedState => {
        if (state.readerMode) {
            return { ...state, totalNumberOfPosts: state.channelInfo.count_manual_selection };
        } else {
            const totalNumberOfPosts =
                state.channelFeedCount !== null ? state.channelFeedCount : state.channelInfo.count;
            return { ...state, totalNumberOfPosts };
        }
    });

    readonly togglePostExpandedState = this.updater<Post>((state, post): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, post.data.id, (p) => ({
            ...p,
            isExpanded: !post.isExpanded,
        }));
        return {
            ...state,
            posts: updatedPosts,
        };
    });

    readonly setPostAsReadState = this.updater<Post>((state, post): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, post.data.id, (p) => ({
            ...p,
            is_read: true,
        }));
        return {
            ...state,
            posts: updatedPosts,
        };
    });

    readonly updatePostsRead = this.updater<[boolean, string]>((state, [isRead, channelId]): PostsFeedState => {
        if (state.selectedChannelId != channelId) {
            return state;
        }
        return {
            ...state,
            posts: state.posts.map((post) => {
                return { ...post, data: { ...post.data, is_read: isRead } };
            }),
        };
    });

    readonly expandAll = this.updater<void>((state): PostsFeedState => {
        const expandedPosts = state.posts.map((p) => ({ ...p, isExpanded: state.allPostsCollapsed }));
        return { ...state, allPostsCollapsed: !state.allPostsCollapsed, posts: expandedPosts };
    });

    readonly updateEditedPost = this.updater<[Post, Post]>((state, [oldPost, newPost]): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, oldPost.data.id, (post) => ({
            ...post,
            isExpanded: false,
            data: { ...newPost.data, is_selected: oldPost.data.is_selected },
        }));
        return { ...state, posts: updatedPosts };
    });

    readonly updatePostComment = this.updater<[string, string]>((state, [postId, comment]): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, postId, (post) => ({
            ...post,
            data: { ...post.data, analyst_comment: comment },
        }));
        return { ...state, posts: updatedPosts };
    });

    readonly updatePostScore = this.updater<[string, any]>((state, [postId, score]): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, postId, (post) => ({
            ...post,
            data: { ...post.data, score },
        }));
        return { ...state, posts: updatedPosts };
    });

    readonly updatePostAIScore = this.updater<[string, any]>((state, [postId, aiScore]): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, postId, (post) => ({
            ...post,
            data: { ...post.data, ai_score: aiScore },
        }));
        return { ...state, posts: updatedPosts };
    });

    readonly updatePostTags = this.updater<[string, TagItem[]]>((state, [postId, tags]): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, postId, (post) => ({
            ...post,
            tags,
        }));
        return { ...state, posts: updatedPosts };
    });

    readonly updatePostSearches = this.updater<[string, []]>((state, [postId, searches]): PostsFeedState => {
        const updatedPosts = this.postsFeedService.updateFeedPostById(state.posts, postId, (post) => ({
            ...post,
            searches,
        }));

        return { ...state, posts: updatedPosts };
    });

    readonly updateSearchForAllPosts = this.updater<SearchItem>((state, updatedSearch): PostsFeedState => {
        const updatedPosts = state.posts.map((post) => {
            const updatedPostsSearches = post.data.searches.map((postSearch) => {
                if (postSearch.id === updatedSearch.id) {
                    return { ...postSearch, name: updatedSearch.name, score: updatedSearch.score };
                }
                return postSearch;
            });
            return { ...post, data: { ...post.data, searches: updatedPostsSearches } };
        });

        return { ...state, posts: updatedPosts };
    });

    readonly updateIncludedStateForAllPosts = this.updater<boolean>((state, isSelected): PostsFeedState => {
        const posts = state.posts.map((post) => {
            return { ...post, data: { ...post.data, is_selected: isSelected } };
        });

        return { ...state, posts };
    });

    readonly updatePostsSelectionStatus = this.updater(
        (state, { postIds, isSelected }: { postIds: string[]; isSelected: boolean }): PostsFeedState => {
            const updatedPosts = state.posts.map((post) =>
                postIds.includes(post.data.id) ? { ...post, data: { ...post.data, is_selected: isSelected } } : post,
            );
            const lastIncludedPost = updatedPosts.find((post) => post.data.id === postIds[postIds.length - 1]);
            return { ...state, posts: updatedPosts, lastIncludedPost: lastIncludedPost || state.lastIncludedPost };
        },
    );

    //Effects

    readonly changeFilters = this.effect<HttpParams>((filter$) =>
        filter$.pipe(
            withLatestFrom(this.filter$),
            tap(([newFilter, oldFilter]: [HttpParams, HttpParams]) => {
                if (newFilter !== oldFilter) {
                    const filterState = this.utilsService.mergeSearchParams(newFilter, oldFilter);
                    this.setFilter(filterState);
                    this.resetPosts();
                    this.resetPostOffset();
                }
                this.getPosts(false);
            }),
        ),
    );

    readonly markAllPostsAsReadOrUnread = this.effect<boolean>((updateRedLine$) =>
        updateRedLine$.pipe(
            withLatestFrom(this.selectedChannelId$, this.firstPostIsRead$),
            tap(([updateRedLine, channelId, firstPostIsRead]) => {
                this.patchState({ markAsReadRequestStatus: Statuses.loading });
                this.patchState({ executedMarkAllPostsAsReadOrUnReadOnceForChannel: channelId });
                if (firstPostIsRead) {
                    this.markAllPostsAsUnread(updateRedLine);
                } else {
                    this.markAllPostsAsRead(updateRedLine);
                }
            }),
        ),
    );

    readonly updateChannelUnreadStatus = this.effect<boolean>((isRead$) =>
        isRead$.pipe(
            withLatestFrom(this.isAllPostsAreShown$, this.selectedChannelId$),
            tap(([isRead, isAllPostsAreShown, channelId]) => {
                this.patchState({ allPostsAreShown: isAllPostsAreShown });
                this.appStateService.updateChannelUnreadStatus(channelId, !isRead);
                if (isAllPostsAreShown) {
                    this.markAllPostsAsReadOrUnReadOnce();
                }
            }),
        ),
    );

    readonly markAllPostsAsUnread = this.effect<boolean>((updateRedLine$) =>
        updateRedLine$.pipe(
            withLatestFrom(this.selectedChannelId$),
            switchMap(([updateRedLine, channelId]) =>
                this.channelService.setChannelPostsAsUnread(channelId).pipe(
                    tapResponse(
                        (result) => {
                            if (updateRedLine) {
                                this.setChannelLastReadAt(new Date(null).toISOString());
                            } else {
                                this.updatePostsRead([false, result.channel_id]);
                            }
                        },
                        () => {},
                        () => {
                            this.patchState({ markAsReadRequestStatus: Statuses.loaded });
                            this.updateChannelUnreadStatus(false);
                        },
                    ),
                ),
            ),
        ),
    );

    readonly markAllPostsAsRead = this.effect<boolean>((updateRedLine$) =>
        updateRedLine$.pipe(
            withLatestFrom(this.selectedChannelId$),
            switchMap(([updateRedLine, channelId]) =>
                this.channelService.setChannelPostsAsRead(channelId).pipe(
                    tapResponse(
                        (result) => {
                            this.updatePostsRead([true, result.channel_id]);
                            if (updateRedLine) {
                                this.setChannelLastReadAt(new Date().toISOString());
                            }
                        },
                        () => {},
                        () => {
                            this.patchState({ markAsReadRequestStatus: Statuses.loaded });
                            this.updateChannelUnreadStatus(true);
                        },
                    ),
                ),
            ),
        ),
    );

    readonly getPosts = this.effect<boolean>((needReset$) =>
        needReset$.pipe(
            tap(() => {
                this.patchState({ postsLoadingStatus: Statuses.loading });
            }),
            withLatestFrom(this.readerMode$, this.postOffset$, this.initialPostsCount$),
            map(([needReset, readerMode, postOffset, initialPostsCount]) => {
                return {
                    needReset,
                    ...this.postsFeedService.getFeedPaginationParmas(
                        readerMode,
                        postOffset,
                        POSTS_COUNT,
                        initialPostsCount,
                    ),
                };
            }),
            withLatestFrom(this.selectedChannelId$, this.filter$),
            switchMap(([parameters, selectedChannelId, filterState]) => {
                const { needReset, count, offset, methodName } = parameters;
                return this.channelService[methodName](selectedChannelId, offset, count, filterState).pipe(
                    tap((paginationData: any) => {
                        if (needReset) {
                            this.patchState({ posts: [] });
                        }
                        this.patchState({
                            channelFeedCount: paginationData.total_count,
                            totalNumberOfPosts: paginationData.total_count,
                        });
                    }),
                    map((paginationData: any) => paginationData.posts),
                    tap((fetchedPosts: FeedPost[]) => {
                        this.updatePaginationState([fetchedPosts, offset]);
                    }),
                    tapResponse({
                        next: (fetchedPosts: FeedPost[]) => {
                            this.updateFeedPostsValues([fetchedPosts, offset]);
                            this.patchState({ postsLoadingStatus: Statuses.loaded });
                        },
                        error: (error) => {
                            this.patchState({ emptyPostsList: true, postsLoadingStatus: Statuses.failed });
                            return of(error);
                        },
                    }),
                );
            }),
        ),
    );

    readonly updatePaginationState = this.effect<[FeedPost[], string]>((feedParams$) =>
        feedParams$.pipe(
            tap(([feedPosts, offset]) => {
                if (offset == '0') {
                    if (feedPosts?.length > 0) {
                        this.increasPostOffset();
                    }

                    if (feedPosts?.length < POSTS_COUNT) {
                        this.patchState({ endOfPosts: false });
                    }
                } else {
                    if (feedPosts.length == 0) {
                        this.patchState({ endOfPosts: true });
                    }
                }
            }),
        ),
    );

    readonly updateFeedPostsValues = this.effect<[FeedPost[], string]>((feedParams$) =>
        feedParams$.pipe(
            withLatestFrom(this.posts$, this.allPostsCollapsed$),
            tap(([[fetchedPosts, offset], currentFeedPosts, allPostsCollapsed]) => {
                if (offset == '0') {
                    this.patchState({ posts: fetchedPosts });
                } else {
                    const posts = [...currentFeedPosts];
                    for (let i = 0; i < fetchedPosts.length; i++) {
                        if (!allPostsCollapsed) {
                            fetchedPosts[i].isExpanded = true;
                        }
                        posts.push(fetchedPosts[i]);

                        if (i + 1 == fetchedPosts.length) {
                            this.increasPostOffset();
                        }
                    }
                    this.patchState({ posts: posts });
                }
                this.updateEndOfPaginationState(offset);
            }),
        ),
    );

    readonly updateEndOfPaginationState = this.effect<string>((offset$) =>
        offset$.pipe(
            withLatestFrom(this.posts$, this.isAllPostsAreShown$),
            tap(([offset, posts, isAllPostsAreShown]) => {
                this.setNewLineIndex(offset);
                this.patchState({
                    allPostsAreShown: isAllPostsAreShown,
                    emptyPostsList: posts?.length < 1,
                });
                if (isAllPostsAreShown) {
                    this.markAllPostsAsReadOrUnReadOnce();
                }
            }),
        ),
    );

    readonly getChannel = this.effect<string>((channelId$) =>
        channelId$.pipe(
            tap(() => {
                this.resetFeedSettingsState();
            }),
            switchMap((channelId) =>
                this.channelService.getChannel(channelId).pipe(
                    map((data) => {
                        if (data) {
                            data.count = data.count || 0;
                            data.count_manual_selection = data.count_manual_selection || 0;
                        }
                        return data;
                    }),
                    tap((data) => {
                        this.patchState({ channelInfo: data, endOfPosts: false });
                        // reset page/endOfposts/postScroll to initial values feed load
                        this.resetPostOffset();

                        this.refreshFeed();
                        this.updateTotalNumberOfPosts();
                        this.utilsService.setTitleDescriptor(`Channel | ${data.basic.name}`);
                    }),
                ),
            ),
        ),
    );

    readonly onSwitchFeedMode = this.effect<boolean>((readerMode$) =>
        readerMode$.pipe(
            tap((readerMode: boolean) => {
                this.setReaderMode(readerMode);
                this.refreshFeed();
            }),
            withLatestFrom(this.selectedChannelId$, this.channelInfo$),
            switchMap(([_, selectedChannelId, channelInfo]) =>
                this.channelService.getChannel(selectedChannelId).pipe(
                    take(1),
                    map((data) => {
                        if (data) {
                            data.count = data.count || 0;
                            data.count_manual_selection = data.count_manual_selection || 0;
                        }
                        return data;
                    }),
                    tap((data) => {
                        this.patchState({
                            channelInfo: {
                                ...channelInfo,
                                count: data.count,
                                count_manual_selection: data.count_manual_selection,
                            },
                        });
                        this.updateTotalNumberOfPosts();
                    }),
                ),
            ),
        ),
    );

    readonly refreshFeed = this.effect<void>(
        tap(() => {
            this.resetPostOffset();
            this.patchState({ endOfPosts: false, newLineIndex: null });
            this.getPosts(true);
        }),
    );

    readonly togglePostAsRead = this.effect<[boolean, Post]>((postData$) =>
        postData$.pipe(
            tap(([isRead, post]: [boolean, Post]) => {
                this.togglePostExpandedState(post);
                if (!isRead) {
                    this.setPostIsRead(post);
                }
            }),
        ),
    );

    readonly setPostIsRead = this.effect<Post>((post$) =>
        post$.pipe(
            withLatestFrom(this.selectedChannelId$),
            switchMap(([post, channelId]) =>
                this.channelService.setPostAsRead(post.data.id, channelId).pipe(
                    tapResponse({
                        next: () => {
                            this.setPostAsReadState(post);
                        },
                        error: () => {},
                    }),
                ),
            ),
        ),
    );

    readonly deletePost = this.effect<string>((postId$) =>
        postId$.pipe(
            withLatestFrom(this.posts$),
            tap(([deletedPostId, posts]: [string, FeedPost[]]) => {
                const updatedPosts = posts.filter((el: Post) => el.data.id !== deletedPostId);
                this.patchState({ posts: updatedPosts });
            }),
        ),
    );

    readonly loadMorePosts = this.effect<void>(
        pipe(
            withLatestFrom(this.canFetchMorePosts$),
            filter(([_, canFetchMorePosts]) => canFetchMorePosts),
            tap(() => {
                this.getPosts(false);
            }),
        ),
    );

    readonly markAllPostsAsReadOrUnReadOnce = this.effect<void>(
        pipe(
            withLatestFrom(this.selectedChannelId$, this.executedMarkAllPostsAsReadOrUnReadOnceForChannel$),
            tap(([_, channelId, executedMarkAllPostsAsReadOrUnReadOnceForChannel]) => {
                if (executedMarkAllPostsAsReadOrUnReadOnceForChannel !== channelId) {
                    this.patchState({ executedMarkAllPostsAsReadOrUnReadOnceForChannel: channelId });
                    this.markAllPostsAsReadOrUnread(false);
                }
            }),
        ),
    );

    readonly initChannelFeedPosts = this.effect<string>((channelId$) =>
        channelId$.pipe(
            tap((channelId: string) => {
                this.setChannelId(channelId);
                this.getChannel(channelId);
            }),
        ),
    );

    private readonly setNewLineIndex = this.effect<string>((offset$) =>
        offset$.pipe(
            withLatestFrom(this.posts$, this.channelInfo$),
            tap(([offset, posts, channelInfo]) => {
                for (let i = Number(offset); i < posts.length; i++) {
                    if (this.postsFeedService.showLastReadLine(posts[i], posts, channelInfo, i)) {
                        this.patchState({ newLineIndex: i });
                        this.markAllPostsAsReadOrUnReadOnce();
                        break;
                    }
                }
            }),
        ),
    );

    readonly getPostsBetween = (startIndex: number, endIndex: number) => {
        const start = Math.min(startIndex, endIndex);
        const end = Math.max(startIndex, endIndex);
        return this.selectSignal((state) => state.posts)().slice(start, end + 1);
    };

    //Initialization
    private postsFeedService = inject(PostsFeedService);
    private channelService = inject(ChannelService);
    private appStateService = inject(AppStateService);
    private utilsService = inject(UtilsService);

    constructor() {
        super(defaultState);
    }
}
