import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { AppStateService } from '@app/core/services/app-state.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-post-manual-score',
    templateUrl: './post-manual-score.component.html',
    styleUrls: ['./post-manual-score.component.scss'],
    standalone: false,
})
export class PostManualScoreComponent implements OnInit {
    @Input() post: any;
    @Input() labelWidth = 115;
    @Output() openScoreDialogEvent = new EventEmitter<any>();

    scoreWording$: Observable<any>;
    private appStateService = inject(AppStateService);
    ngOnInit(): void {
        this.scoreWording$ = this.appStateService.siloScoreWording$;
    }

    openScoreDialog() {
        this.openScoreDialogEvent.emit();
    }

    get iconClass(): string {
        return this.post.data.ai_score != null ? 'fad fa-flask' : 'fas fa-exclamation-triangle';
    }
}
