<div class="modal-component">
    <div class="modal-header">
        @if (failureMessage) {
            <div class="failure_alert_container">
                @if (failureMessage) {
                    <app-alert (closed)="resetFailureMessage()">{{ failureMessage }}</app-alert>
                }
            </div>
        }
    </div>

    <div class="modal-body">
        <app-tags
            [readOnlyMode]="true"
            [selectedTags]="selectedTags"
            [hideEmptyCategories]="true"
            (toggleTag)="onToggleTag($event)"
            (selectTags)="onSelectItems($event)"
            (unSelectTags)="onUnSelectItems($event)"
        ></app-tags>

        <div class="form_btns">
            <button class="btn cancel-button" mat-flat-button (click)="close()">Cancel</button>
            <button
                class="btn primary_btn"
                mat-raised-button="mat-raised-button"
                color="primary"
                [disabled]="submitDisabled || isSubmitting"
                (click)="save()"
            >
                <span *msLoadingSpinner="isSubmitting; strokeWidth: 2; diameter: 20">Save</span>
            </button>
        </div>
    </div>
</div>
