<div class="metadata_component modal-component">
    <div class="modal-header">
        <div class="title">Metadata</div>
    </div>

    @if (failureMessage) {
        <div class="failure_alert_container">
            @if (failureMessage) {
                <app-alert (closed)="staticAlertClosed = null; failureMessage = ''"
                    ><strong>Error: </strong>{{ failureMessage }}</app-alert
                >
            }
        </div>
    }
    @if (successMessage) {
        <div class="success_alert_container">
            @if (successMessage) {
                <app-alert type="success" (closed)="staticAlertClosed = null; successMessage = ''"
                    ><strong>Success: </strong>{{ successMessage }}</app-alert
                >
            }
        </div>
    }

    <div class="content">
        <div class="content_body">
            <div class="content_title">
                <mat-icon class="size-auto"><i class="fal fa-globe"></i></mat-icon>
                <span>Location</span>
            </div>

            <div class="map_content">
                <p>Drag the map to indicate which location should be associated with the post:</p>

                <div class="map">
                    <input id="pac-input" class="controls" type="text" placeholder="Search address" />
                    <div #map>{{ map }}</div>
                </div>

                <p>
                    The location is shown in the reader view of the post and can be visualized in the Dashboard section.
                </p>
            </div>

            <div class="form_btns">
                <button id="clear-location" [disabled]="!postLocation" class="btn delete" type="button" mat-flat-button>
                    <mat-icon class="size-auto"><i class="fal fa-trash-alt"></i></mat-icon> Clear location
                </button>

                <div class="right-side-group">
                    <button class="cancel-button btn" mat-flat-button (click)="close()">Cancel</button>

                    <button
                        class="btn primary_btn"
                        type="submit"
                        (click)="saveLocation()"
                        mat-raised-button
                        [disabled]="isSubmitting"
                    >
                        @if (isSubmitting) {
                            <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
                        } @else {
                            <span>Save</span>
                        }
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
