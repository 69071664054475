import { FeedPost } from './feed-post.model';

export enum SearchStatus {
    Active = 'active',
    LackOfCredits = 'lack_of_credits',
    InternalError = 'internal_error',
    ExternalError = 'external_error',
}

export interface Search {
    id: number;
    name: string;
    status: SearchStatus;
    error_messages: string[];
    score: Score;
    search_params: Record<string, SearchParamsValue>;
    advanced_options: AdvancedOption[];
    search: {
        collections: string[];
        provider: string;
        query: string;
    };
    category: { id: number; name: string };
    channels: { id: number; name: string }[];
    crawl_frequency?: CrawlFrequency;
}

type SearchParamsValue = string[];
export type AdvancedOption = {
    name: string;
    value: string | boolean | string[];
    internal?: boolean;
};

type CrawlFrequency = {
    crawl_credit_rate: string;
    crawl_credit_rate_30d: string;
    default: boolean;
    id: number;
    name: string;
};

export interface CreateSearchPayload {
    name: string;
    category_id?: number;
    query: {
        provider: string;
        collections: string[];
        query: string;
        refresh_content: number;
    };
    score: Score;
    channel_ids: string[];
    advanced_options: AdvancedOption;
    search_params: AdvancedOption;
    crawl_frequency_id?: number;
}

export interface SearchPostsRequest {
    query: string;
    provider: string;
    collections: string[];
    advanced_options: Record<string, string | boolean | string[]>;
    search_params: Record<string, string | boolean | string[]>;
    score: Score;
    rows: number;
    start: number;
}

export interface ValidateSearchRequest {
    query: string;
    provider: string;
    collections: string[];
    advanced_options: AdvancedOption[];
    search_params: AdvancedOption[];
    score: Score;
}

export interface SearchPostsResponse {
    posts: FeedPost[];
    meta: {
        start: number;
        rows: number;
    };
}

export type ValidateSearchResponse = Record<
    string,
    {
        explanations: Record<string, ValidationResponseItem>[];
        valid: boolean;
    }
>;

export type Score = null | 0 | 1 | 2;
type ValidationResponseItem = {
    valid: boolean;
    explanation: string;
    index: string;
};
