import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: false,
})
export class FilterPipe implements PipeTransform {
    transform(items: string[], filter: string): string[] {
        if (!items || !filter) {
            return items;
        }
        return items.filter((item: string) => this.applyFilter(item, filter));
    }

    applyFilter(item: string, filter: string): boolean {
        if (typeof item === 'string' && typeof filter === 'string') {
            return filter && item && item.toLowerCase().startsWith(filter.toLowerCase());
        } else {
            return filter && item && item.toString().toLowerCase().startsWith(filter.toString().toLowerCase());
        }
    }
}
