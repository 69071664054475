import { Component, OnInit, ElementRef, Inject } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ChannelService } from '@app/data/services/channel.service';
import { Tag, TagItem } from '@app/data/models';
import { AppStateService } from '@app/core/services/app-state.service';

interface CurrentCategory {
    category: {
        id: string;
        name: string;
    };
    name: string;
}

@Component({
    selector: 'app-tag-form',
    templateUrl: './tag-form.component.html',
    styleUrls: ['./tag-form.component.scss'],
    standalone: false,
})
export class TagFormComponent implements OnInit {
    form: FormGroup;
    isSubmitting = false;
    isDeleting = false;
    emptyCategories: boolean;
    errorMessage: any;
    currentCategory: CurrentCategory = {
        category: {
            id: '',
            name: '',
        },
        name: '',
    };
    isLoading = true;

    constructor(
        private channelService: ChannelService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        public el: ElementRef,
        private modalRef: MatDialogRef<TagFormComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { tag: TagItem | null; tagCategoriesList: Tag[]; category? },
        private appStateService: AppStateService,
    ) {}

    ngOnInit() {
        this.createForm();

        if (this.tag) {
            this.getTagCategoryInfo();
        } else {
            this.isLoading = false;
        }
    }

    createForm() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            category: ['', Validators.required],
        });

        if (this.data.category) {
            this.form.get('category').setValue(this.data.category.name);
            this.currentCategory.category = this.data.category;
            this.currentCategory.name = this.data.category.name;
        }
    }

    setFormValues() {
        this.form.reset({
            name: this.currentCategory.name,
            category: this.currentCategory.category.id,
        });
    }

    onSubmit() {
        this.isSubmitting = true;
        const saveData = this.prepareSaveData();
        this.isSubmitting = true;

        if (this.tag) {
            this.updateTag(saveData);
        } else {
            this.createTag(saveData);
        }
    }

    createTag(saveData) {
        this.channelService
            .createTag(saveData)
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe((data) => {
                this.modalRef.close(data);
            });
    }

    updateTag(saveData) {
        this.channelService
            .updateTag(this.tag.id, saveData)
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe((data) => {
                this.modalRef.close(data);
            });
    }

    close() {
        this.modalRef.close();
    }

    prepareSaveData() {
        const formData = this.form.value;

        return {
            name: formData.name,
            category_id: this.currentCategory.category.id,
        };
    }

    deleteTag() {
        this.dialog
            .open(ConfirmationDialogComponent, {
                panelClass: 'modal-sm',
                autoFocus: false,
                restoreFocus: false,
                data: {
                    header: 'Delete tag',
                    message: 'Are you sure you would like to delete this tag?',
                    confirm: 'Delete',
                },
            })
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe(() => {
                this.isDeleting = true;
                this.channelService
                    .deleteTag(this.tag.id)
                    .pipe(finalize(() => (this.isDeleting = false)))
                    .subscribe((data) => {
                        this.appStateService.getChannelsList();
                        this.modalRef.close(data);
                    });
            });
    }

    get tag() {
        return this.data.tag;
    }

    get tagCategoriesList() {
        return this.data.tagCategoriesList;
    }

    private getTagCategoryInfo() {
        this.channelService.getTagsId(this.tag.id).subscribe((tag) => {
            this.currentCategory = {
                ...this.tag,
                category: tag['category'],
            };

            this.setFormValues();
            this.isLoading = false;
        });
    }
}
