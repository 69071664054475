import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-silo-delition-dialog',
    templateUrl: './silo-delition-dialog.component.html',
    styleUrls: ['./silo-delition-dialog.component.scss'],
    standalone: false,
})
export class SiloDelitionDialogComponent {
    siloName = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: { siloName: string }) {}

    get isSiloNameCorrect() {
        return this.siloName === this.data.siloName;
    }
}
