import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-image-preview-dialog',
    templateUrl: './image-preview-dialog.component.html',
    styleUrls: ['./image-preview-dialog.component.scss'],
    standalone: false,
})
export class ImagePreviewDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { screenshotUrl: string },
    ) {}
    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }

    get screenshotUrl() {
        return this.data.screenshotUrl;
    }
}
