import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { defaultThemingConfig } from '@app/core/constants/themingConfigs';

import { ApiService, EnvironmentService, ErrorHandlerService } from '@app/core/services';
import { ThemingOption, ThemingConfig } from '@app/data/models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private previousThemeClassName = '';
    private renderer: Renderer2;

    constructor(
        private apiService: ApiService,
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2,
        private titleService: Title,
        private errorHandlerService: ErrorHandlerService,
        private envService: EnvironmentService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    getAppThemingConfig(themeName: string): Observable<ThemingConfig> {
        if (themeName === 'default') {
            return of(defaultThemingConfig);
        }

        return this.apiService.getLocalResource(`assets/theming-configs/${themeName}.config.json`).pipe(
            map((themingConfigJson: any) => {
                if (themingConfigJson) {
                    return themingConfigJson;
                } else {
                    return defaultThemingConfig;
                }
            }),
            catchError((error) => {
                return throwError(error);
            }),
        );
    }

    onThemeConfigUpdate(themeConfig: ThemingConfig) {
        this.setThemeClassName(themeConfig.themeClassName);
        this.setCustomPageTitle(themeConfig.pageTitle);
        this.setCustomFavicon(themeConfig.customFaviconPath);
        this.setZendesk(themeConfig.enableZendesk);
    }

    getThemingOption(): Observable<ThemingOption> {
        return this.apiService.get('/get-theming-option', { withCredentials: false }).pipe(
            catchError((error) => this.errorHandlerService.getErrorMessage(error)),
            map((data: any) => data as ThemingOption),
        );
    }

    private setThemeClassName(themeClassName: string) {
        if (this.previousThemeClassName) {
            this.removeBodyClass(this.previousThemeClassName);
        }
        if (themeClassName) {
            this.addBodyClass(themeClassName);
        }
        this.previousThemeClassName = themeClassName;
    }

    private addBodyClass(className: string): void {
        this.renderer.addClass(this.document.body, className);
    }

    private removeBodyClass(className: string): void {
        this.renderer.removeClass(this.document.body, className);
    }

    private setCustomPageTitle(pageTitle: string) {
        if (pageTitle) {
            this.titleService.setTitle(pageTitle);
        }
    }

    private setCustomFavicon(customFaviconPath: string) {
        if (customFaviconPath) {
            this.document.getElementById('favicon')?.setAttribute('href', customFaviconPath);
        }
    }

    private setZendesk(zendeskEnabled: boolean) {
        if (zendeskEnabled && this.envService.showZendeskChat) {
            const script = document.createElement('script');
            script.src = 'https://static.zdassets.com/ekr/snippet.js?key=04e22abb-d7f8-459b-b36b-91d57664392b';
            script.id = 'ze-snippet';
            document.head.appendChild(script);
        }
    }
}
