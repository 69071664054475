import { Injectable, inject } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable, of, tap } from 'rxjs';
import { TransactionsResponse } from '../models/transactions.model';
import {
    ChangeSubscriptionPlanResponse,
    ContentProvider,
    OrganisationCreatePayload,
    OrganisationSiloFullInfoResponse,
    OrganisationSiloResponse,
    OrganisationUserDetails,
    OrganisationUserResponse,
    StartSubscriptionPlanResponse,
    SubscriptionPlanPricing,
    SubscriptionPlanResponse,
} from '@app/data/models';
import { HttpParams } from '@angular/common/http';

const basePath = '/admin';
const organisationBasePath = '/admin/organisation';
interface ChangeBalancePayload {
    credit_balance_change: number;
    subscription_log_message: string;
}

interface ChangePlanPayload {
    subscription_plan_id: number;
    subscription_end_date?: string;
    subscription_log_message: string;
}
@Injectable({
    providedIn: 'root',
})
export class OrganisationApiService {
    private contentProviders: ContentProvider[] = [];
    private apiService = inject(ApiService);

    getCurrentSubscription(): Observable<unknown> {
        return this.apiService.get(`${organisationBasePath}/subscription/`, { withCredentials: true });
    }

    getTransactioinsLogs(page = 1, collection = []) {
        return this.apiService.get(`${organisationBasePath}/transactions/`, {
            withCredentials: true,
            params: {
                page,
                collection,
            },
        }) as unknown as Observable<TransactionsResponse>;
    }

    cancelTransaction(id: number): Observable<unknown> {
        return this.apiService.post(`${basePath}/transactions/${id}/cancel`, null, { withCredentials: true });
    }

    getCurrentSubscriptionPlans(): Observable<unknown> {
        return this.apiService.get(`${organisationBasePath}/subscription-plans`, { withCredentials: true });
    }

    startSubscriptionPlan(stripePriceId: string, promotionCodes: string[]): Observable<StartSubscriptionPlanResponse> {
        return this.apiService.post(
            `${organisationBasePath}/subscription/plan/`,
            {
                stripe_price_id: stripePriceId,
                promotion_codes: promotionCodes,
            },
            { withCredentials: true },
        ) as any as Observable<StartSubscriptionPlanResponse>;
    }

    changeSubscriptionPlan(
        newStripePriceId: string,
        promotionCodes: string[],
    ): Observable<ChangeSubscriptionPlanResponse> {
        return this.apiService.put(
            `${organisationBasePath}/subscription/plan/`,
            {
                stripe_price_id: newStripePriceId,
                promotion_codes: promotionCodes,
            },
            { withCredentials: true },
        ) as any as Observable<ChangeSubscriptionPlanResponse>;
    }

    previewChangeSubscriptionPlan(
        newStripePriceId: string,
        promotionCodes: string[],
    ): Observable<SubscriptionPlanResponse> {
        return this.apiService.post(
            `${organisationBasePath}/subscription/plan/preview`,
            {
                stripe_price_id: newStripePriceId,
                promotion_codes: promotionCodes,
            },
            { withCredentials: true },
        ) as any as Observable<SubscriptionPlanResponse>;
    }

    getPricing() {
        return this.apiService.get(`${organisationBasePath}/pricing`, {
            withCredentials: true,
        }) as any as Observable<SubscriptionPlanPricing[]>;
    }

    getUsageCost(): Observable<unknown> {
        return this.apiService.get(`${organisationBasePath}/usage-costs`, { withCredentials: true });
    }

    changeBalance(data: ChangeBalancePayload): Observable<unknown> {
        return this.apiService.post(`${organisationBasePath}/subscription/change-balance`, data, {
            withCredentials: true,
        });
    }

    changePlan(data: ChangePlanPayload): Observable<unknown> {
        return this.apiService.post(`${organisationBasePath}/subscription/change-plan`, data, {
            withCredentials: true,
        });
    }

    openCustomerPortalSession(): Observable<unknown> {
        return this.apiService.post(`${organisationBasePath}/customer-portal-session`, null, { withCredentials: true });
    }

    createOrganisation(organisation: OrganisationCreatePayload) {
        return this.apiService.post(`${organisationBasePath}/`, organisation, {
            withCredentials: true,
        });
    }

    editOrganisation(organisation: Partial<OrganisationCreatePayload>) {
        return this.apiService.patch(`${organisationBasePath}/`, organisation, {
            withCredentials: true,
        });
    }

    getOrganisationSilos(queryParams: { name?: string } = {}) {
        const httpParams = new HttpParams({ fromObject: queryParams });
        return this.apiService.get<OrganisationSiloResponse>(`${organisationBasePath}/silos`, {
            withCredentials: true,
            params: httpParams,
        });
    }

    getOrganisationUsers(queryParams: { name?: string } = {}) {
        const httpParams = new HttpParams({ fromObject: queryParams });
        return this.apiService.get<OrganisationUserResponse>(`${organisationBasePath}/users`, {
            withCredentials: true,
            params: httpParams,
        });
    }

    getOrganisationUser(userId: number) {
        return this.apiService.get<OrganisationUserDetails>(`${organisationBasePath}/users/${userId}`, {
            withCredentials: true,
        });
    }

    createOrganisationUser(user: OrganisationUserDetails) {
        return this.apiService.post(`${organisationBasePath}/users`, user, {
            withCredentials: true,
        });
    }

    updateOrganisationUser(userid: number, user: OrganisationUserDetails) {
        return this.apiService.patch(`${organisationBasePath}/users/${userid}`, user, {
            withCredentials: true,
        });
    }

    deleteOrganisationUser(userId: number) {
        return this.apiService.delete(`${organisationBasePath}/users/${userId}`, {
            withCredentials: true,
        });
    }

    getOrganisationSiloData(siloId: number) {
        return this.apiService.get<OrganisationSiloFullInfoResponse>(`${organisationBasePath}/silos/${siloId}`, {
            withCredentials: true,
        });
    }

    createOrganisationSilo(organisation: OrganisationSiloFullInfoResponse) {
        return this.apiService.post(`${organisationBasePath}/silos`, organisation, {
            withCredentials: true,
        });
    }

    updateOrganisationSilo(siloId: number, organisation: OrganisationSiloFullInfoResponse) {
        return this.apiService.put(`${organisationBasePath}/silos/${siloId}`, organisation, {
            withCredentials: true,
        });
    }

    deleteOrganisationSilo(siloId: number) {
        return this.apiService.delete(`${organisationBasePath}/silos/${siloId}`, {
            withCredentials: true,
        });
    }

    getContentProviders(): Observable<ContentProvider[]> {
        return this.apiService
            .get<ContentProvider[]>(`${organisationBasePath}/content_providers`, {
                withCredentials: true,
            })
            .pipe(tap((data) => (this.contentProviders = data)));
    }

    enableContentCollection(collection_ids: number[]) {
        const enabledCollectionIds = this.getEnabledCollectionIds();
        const collectionsToEnable = new Set([...collection_ids, ...enabledCollectionIds]);
        return this.editContentCollectinos([...collectionsToEnable]);
    }

    disableContentCollection(disabled_collection_ids: number[]) {
        const enabledCollectionIds = this.getEnabledCollectionIds();
        const collectionsToEnable = new Set(
            [...enabledCollectionIds].filter((id) => !disabled_collection_ids.includes(id)),
        );
        return this.editContentCollectinos([...collectionsToEnable]);
    }

    editContentCollectinos(contentCollections: number[]) {
        return this.apiService.post(
            `${organisationBasePath}/content_collections`,
            contentCollections.map((id) => ({ id })),
            {
                withCredentials: true,
            },
        );
    }

    private getEnabledCollectionIds(): number[] {
        return this.contentProviders
            .flatMap((provider) => provider.collections)
            .filter((collection) => collection.enabled)
            .map((collection) => collection.id);
    }

    getSiloConfigurations(siloId: number): Observable<Record<string, 'yes' | 'no'>> {
        return this.apiService.get<Record<string, 'yes' | 'no'>>(
            `${organisationBasePath}/silos/${siloId}/configurations`,
            {
                withCredentials: true,
            },
        );
    }

    updateSiloConfiguration(siloId: number, configId: string, enabled: boolean): Observable<unknown> {
        const method = enabled ? 'put' : 'delete';
        return this.apiService[method](`${organisationBasePath}/silos/${siloId}/configurations/${configId}`, {
            withCredentials: true,
        });
    }

    getOrganisationFeatureFlags() {
        return this.apiService.get(`${organisationBasePath}/feature-flags`, {
            withCredentials: true,
        });
    }
}
