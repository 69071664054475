<div class="dates_actions">
    @if (post.data.is_manual) {
        <div class="info">
            <span class="label">Added manually</span>
        </div>
    }
    @if (postViewData.location) {
        <div class="info">
            <span class="label">Location: </span>
            <span>{{ postViewData.location }}</span>
        </div>
    }
    @if (post.data.published_at) {
        <div class="info">
            <span class="label">Date of Origin: </span>
            @if (dateService.setDateISO8601(post.data.published_at)) {
                <span
                    >{{ dateService.getDateDiff('' + post.data.published_at) }} &nbsp; / &nbsp;
                    {{ dateService.setDateISO8601(post.data.published_at) | date: dateService.getDateFormat() }}</span
                >
            }
        </div>
    }
    @if (postViewData.isp) {
        <div class="info">
            <span class="label">ISP: </span>
            <span>{{ postViewData.isp }}</span>
        </div>
    }
    @if (postViewData.port) {
        <div class="info">
            <span class="label">Port: </span>
            <span>{{ postViewData.port }}</span>
        </div>
    }
    @if (postViewData.domains) {
        <div class="info">
            <span class="label">Domains: </span>
            <span>
                {{ postViewData.domains }}
            </span>
        </div>
    }
    @if (postViewData.hostnames) {
        <div class="info">
            <span class="label">Hostnames: </span>
            <span>
                {{ postViewData.hostnames }}
            </span>
        </div>
    }
    @if (postViewData.transport) {
        <div class="info">
            <span class="label">Transport: </span>
            <span>
                {{ postViewData.transport }}
            </span>
        </div>
    }
    @if (postViewData.cpe) {
        <div class="info">
            <span class="label">CPE: </span>
            <span>
                {{ postViewData.cpe }}
            </span>
        </div>
    }
    @if (postViewData.cpe23) {
        <div class="info">
            <span class="label">CPE23: </span>
            <span>
                {{ postViewData.cpe23 }}
            </span>
        </div>
    }
    @if (postViewData.asn) {
        <div class="info">
            <span class="label">ASN: </span>
            <span>
                {{ postViewData.asn }}
            </span>
        </div>
    }
    @if (postViewData.meta) {
        <div class="info">
            <span class="label">Meta: </span>
            <span>
                {{ postViewData.meta }}
            </span>
        </div>
    }
    @if (postViewData.os) {
        <div class="info">
            <span class="label">OS: </span>
            <span>
                {{ postViewData.os }}
            </span>
        </div>
    }
    @if (postViewData.version) {
        <div class="info">
            <span class="label">Version: </span>
            <span>
                {{ postViewData.version }}
            </span>
        </div>
    }
    @if (postViewData.product) {
        <div class="info">
            <span class="label">Product: </span>
            <span>
                {{ postViewData.product }}
            </span>
        </div>
    }
    @if (postViewData.port) {
        <div class="info">
            <span class="label">Port: </span>
            <span>
                {{ postViewData.port }}
            </span>
        </div>
    }
    @if (postViewData.tags) {
        <div class="info">
            <span class="label">Tags: </span>
            <span>
                {{ postViewData.tags }}
            </span>
        </div>
    }
    @if (postViewData.data && reader) {
        <div class="info">
            <span class="label exposed-data">Data: </span>
            <mat-accordion>
                <mat-expansion-panel
                    class="mat-elevation-z0"
                    (opened)="dataDetailsOpened = true"
                    (closed)="dataDetailsOpened = false"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ dataDetailsOpened ? 'Hide data details' : 'Show data details' }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <span [innerText]="postViewData.data"> </span>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    }
</div>
