@if (selectedPostReader && !noPostDetails) {
    <div
        class="reader_view_container"
        [class.no_mainmenu]="selectedPostReader.data && (!selectedPostReader.tags || selectedPostReader.tags.length)"
    >
        <div class="selected_post_container">
            <div class="modal-header">
                <div class="action_btns">
                    <div class="close_modal btn btn-link" mat-dialog-close>
                        <mat-icon><i class="fal fa-times"></i></mat-icon>
                        <div class="label">esc</div>
                    </div>
                    <div class="collapsed_btn">
                        @if (selectedPostReader.data.url != null) {
                            <div class="go_source btn btn-link">
                                <a [href]="selectedPostReader.data.url" target="_blank">
                                    <div class="icon">
                                        <mat-icon><i class="fak fa-link-simple-regular"></i></mat-icon>
                                    </div>
                                    <div class="label">source</div>
                                </a>
                            </div>
                        }
                        @if (selectedPostReader.type == 'marketplace') {
                            <div class="go_source btn btn-link">
                                <a [href]="selectedPostReader.data.full_html" target="_blank">
                                    <div class="icon">
                                        <mat-icon><i class="fal fa-paperclip"></i></mat-icon>
                                    </div>
                                    <div class="label">full page</div>
                                </a>
                            </div>
                        }
                    </div>
                </div>
                @if (
                    selectedPostReader.type != 'creditcard' &&
                    selectedPostReader.type != 'breach' &&
                    selectedPostReader.type != 'whois' &&
                    selectedPostReader.type != 'exposed_services' &&
                    selectedPostReader.type != 'stealers_leaks'
                ) {
                    <app-news-post [selectedPostReader]="selectedPostReader"></app-news-post>
                }
                @if (selectedPostReader.type === 'creditcard') {
                    <app-credit-card-post [selectedPostReader]="selectedPostReader"></app-credit-card-post>
                }
                @if (selectedPostReader.type == 'breach') {
                    <app-breach-header [post]="selectedPostReader" [reader]="true"></app-breach-header>
                }
                @if (selectedPostReader.type == 'exposed_services') {
                    <app-shodan-header [post]="selectedPostReader" [reader]="true"></app-shodan-header>
                }
                @if (selectedPostReader.type == 'whois') {
                    <app-whois-header [post]="selectedPostReader" [reader]="true"></app-whois-header>
                }
                @if (selectedPostReader.type == 'stealers_leaks') {
                    <h5>{{ selectedPostReader.data?.line }}</h5>
                }
            </div>
            <div class="modal-body">
                <div>
                    @if (
                        selectedPostReader.data.images &&
                        selectedPostReader.data.images.length &&
                        selectedPostReader.type !== 'whois'
                    ) {
                        <app-product-images-slider
                            class="app-product-images-slider"
                            [postData]="selectedPostReader.data"
                        ></app-product-images-slider>
                    }
                    @if (
                        selectedPostReader.type !== 'creditcard' &&
                        selectedPostReader.type !== 'whois' &&
                        selectedPostReader.type !== 'stealers_leaks'
                    ) {
                        <p
                            [innerHTML]="selectedPostReader.data.content || selectedPostReader.data?.full_description"
                        ></p>
                    }
                    @if (isAnalystCommentVisible) {
                        <div class="analyst-comment">
                            <span class="label">Analyst comment: </span>
                            <span>{{ selectedPostReader.data.analyst_comment }}</span>
                        </div>
                    }
                    @if (selectedPostReader.type === 'creditcard') {
                        <app-credit-card-details [selectedPostReader]="selectedPostReader"></app-credit-card-details>
                    }
                    @if (selectedPostReader.type == 'breach') {
                        <app-breach-body [post]="selectedPostReader" [reader]="true"></app-breach-body>
                    }
                    @if (selectedPostReader.type == 'exposed_services') {
                        <app-shodan-body [post]="selectedPostReader" [reader]="true"></app-shodan-body>
                    }
                    @if (selectedPostReader.type == 'whois') {
                        <app-whois-body
                            [post]="selectedPostReader"
                            [reader]="true"
                            (openImagePreviewEvent)="openImagePreview($event)"
                        ></app-whois-body>
                    }
                    @if (selectedPostReader.type == 'stealers_leaks') {
                        <app-leaks-body [post]="selectedPostReader" [reader]="true"></app-leaks-body>
                    }
                </div>
            </div>
        </div>

        <!-- main(left) menu section-->
        @if ((selectedPostReader.tags && selectedPostReader.tags.length) || selectedPostReader.data?.searches?.length) {
            <app-reader-side-menu class="main-menu" [selectedPostReader]="selectedPostReader"></app-reader-side-menu>
        }
    </div>
}
@if (noPostDetails) {
    <div class="reader_view_container">
        <div class="selected_post_container noData">
            <div class="modal-header">
                <div class="action_btns">
                    <div class="close_modal btn btn-link" mat-dialog-close>
                        <mat-icon><i class="fal fa-times"></i></mat-icon>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="noPostData">
                    <mat-icon class="size-auto"><i class="far fa-exclamation-circle"></i></mat-icon> Currently there are
                    no available post details
                </div>
            </div>
        </div>
    </div>
}
