import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss'],
    standalone: false,
})
export class ImageUploaderComponent implements OnInit {
    @Input() categoryName: string;
    @Input() defaultText: string;
    @Input() imageLoaded = false;
    @Input() imageSrc = '';
    @Output() imageupload = new EventEmitter();

    failureMessage = '';
    invalidFile = false;
    dragging = false;
    loaded = false;
    private failure = new Subject<string>();

    ngOnInit() {
        //for alert failure message fading after 10 seconds
        this.failure.pipe(tap((message) => (this.failureMessage = message))).subscribe();
        this.failure
            .pipe(
                debounceTime(8000),
                tap(() => {
                    this.failureMessage = null;
                    this.invalidFile = false;
                }),
            )
            .subscribe();
    }

    handleDragEnter() {
        this.dragging = true;
    }

    handleDragLeave() {
        this.dragging = false;
    }

    handleDrop(e) {
        e.preventDefault();
        this.dragging = false;
        this.handleInputChange(e);
    }

    handleImageLoad() {
        this.imageLoaded = true;
    }

    handleInputChange(e) {
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        const pattern = /image-*/;
        const reader = new FileReader();

        if (file == undefined) {
            return;
        }
        if (!file.type.match(pattern) || file.type.indexOf('x-icon') > -1) {
            this.failure.next('Invalid format');
            this.invalidFile = true;
            return;
        } else {
            this.invalidFile = false;
        }

        this.loaded = false;

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    _handleReaderLoaded(e) {
        const reader = e.target;
        this.imageSrc = reader.result;
        this.imageupload.emit(this.imageSrc);
        this.loaded = true;
    }

    removeImage(e) {
        e.preventDefault();
        this.imageLoaded = false;
        this.loaded = false;
        this.imageSrc = '';
        this.imageupload.emit(this.imageSrc);
    }
}
