<div class="content">
    <div class="header">
        <p>Are you absolutely sure?</p>
        <button class="close" type="button" mat-dialog-close="">
            <mat-icon><i class="fal fa-times"></i></mat-icon>
        </button>
    </div>
    <p class="message alert-message">Unexpected bad things will happen if you don't read this!</p>
    <p class="message">
        This action <b>cannot</b> be undone. This will permanently delete the <b>{{ data.siloName }}</b> silo, tags,
        searches, channels, dashboards and other data associated with the silo. If a user is present in another silo the
        association with this silo will be removed, otherwise the user will be deleted. This process may take a few
        minutes.
    </p>
    <p class="message">
        Please type <b>{{ data.siloName }}</b> to confirm.
    </p>
    <div class="buttons-container">
        <mat-form-field class="form-group app-mat-form-field" appearance="outline">
            <mat-label>Silo name</mat-label>
            <input
                type="text"
                class="name-input"
                name="silo-name"
                id="delition-silo-name"
                [(ngModel)]="siloName"
                matInput
            />
        </mat-form-field>
        <button
            class="ms-button confirm-deletion-button"
            data-testid="confirm-dialog"
            [mat-dialog-close]="true"
            mat-raised-button="mat-raised-button"
            [disabled]="!isSiloNameCorrect"
        >
            I understand the consequences, delete this silo
        </button>
    </div>
</div>
