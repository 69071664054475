import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';
import { Search, Tag } from '@app/data/models';
import { CategoryItemTypesMap } from './category-items.model';
import { Sortable } from '@shopify/draggable';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'app-category-items-selection',
    templateUrl: './category-items-selection.component.html',
    styleUrls: ['./category-items-selection.component.scss'],
    standalone: false,
})
export class CategoryItemsSelectionComponent implements OnChanges {
    @Input() type;
    @Input() tags = [];
    @Input() searches = [];
    @Input() categories;
    @Input() isAddedClass;
    @Input() selectedItem;
    @Input() isEditButtonVisible;
    @Input() isAddButtonVisible: boolean;
    @Input() isActiveClassHidden;
    @Input() activeCategory;
    @Input() assigningItems;
    @Input() isSelectionMode: boolean;
    @Input() isReadOnlyMode: boolean;
    @Input() isSortMode: boolean;

    @Output() editItem = new EventEmitter<Search | Tag>();
    @Output() toggleItemEvent = new EventEmitter<any>();
    @Output() editCategoryEvent = new EventEmitter<Search | Tag>();
    @Output() addItem = new EventEmitter<string>();
    @Output() selectAllItems = new EventEmitter<any>();
    @Output() unselectAllItems = new EventEmitter<any>();
    @Output() sortCategories = new EventEmitter<number[]>();
    @Output() sortCategoryItems = new EventEmitter<number[]>();

    loading = true;
    sortableItems: Sortable;

    ngOnChanges(changes) {
        if (changes.categories?.currentValue !== undefined) {
            this.loading = false;
        }
        if (changes.isSortMode) {
            this.setSortableState(changes.isSortMode.currentValue);
        }
    }

    isAddedItem(itemId: string) {
        return this.tags?.some((el) => el.id === itemId) || this.searches?.some((el) => el.id === itemId);
    }

    toggleItem(items: any) {
        items.type = this.type === 'tags' ? 'tag' : 'search';
        this.toggleItemEvent.emit(items);
    }

    deSelectAllItems() {
        while (this.selectedItem.length) {
            this.toggleItem(this.selectedItem[0]);
        }
    }

    openEditItem(category: Tag | Search) {
        this.editItem.emit(category);
    }

    editCategory(event, category) {
        if (!this.isEditButtonVisible) {
            return;
        }
        event.stopPropagation();
        this.editCategoryEvent.emit(category);
    }

    isTagLocked(tagId: number) {
        return this.selectedItem?.filter((x) => x.id === tagId && x.is_locked).length;
    }

    onClickAddItem(category) {
        this.addItem.emit(category);
    }

    getMatIconClassByCollection(collection) {
        return this.itemTypesMap.matIconClassByCollection && this.itemTypesMap.matIconClassByCollection[collection]
            ? this.itemTypesMap.matIconClassByCollection[collection]['icon-class']
            : this.itemTypesMap.matIconClass;
    }

    onClickSelectAllItems(category) {
        this.selectAllItems.emit(category);
    }

    onClickUnSelectAllItems(category) {
        const itemsWithoutLocked = { ...category };
        if (category.tags) {
            itemsWithoutLocked.tags = category.tags?.filter((categoryItem) => !this.isTagLocked(categoryItem.id));
        }
        this.unselectAllItems.emit(itemsWithoutLocked);
    }

    onClickCategoryItem(item) {
        if (this.isActiveClassHidden) {
            this.openEditItem(item);
        } else {
            if (!this.isTagLocked(item.id)) {
                this.toggleItem(item);
            }
        }
    }

    private setSortableState(isSortingEnabled: boolean) {
        if (isSortingEnabled) {
            if (!this.sortableItems) {
                this.sortableItems = new Sortable(document.querySelectorAll('.draggable-container'), {
                    draggable: '.draggable-item',
                    handle: '.draggable-item-handle',
                    mirror: {
                        constrainDimensions: true,
                    },
                });

                this.setSortableEvents();
                return;
            }
        } else {
            this.sortableItems?.destroy();
            this.sortableItems = null;
        }
    }

    private setSortableEvents() {
        fromEvent(this.sortableItems, 'sortable:sort').subscribe((event: any) => {
            if (event.data.dragEvent.data.sourceContainer !== event.data.dragEvent.data.overContainer) {
                event.cancel();
            }
        });

        fromEvent(this.sortableItems, 'sortable:sorted')
            .pipe(switchMap(() => fromEvent(this.sortableItems, 'drag:stopped').pipe(take(1))))
            .subscribe((event: any) => {
                this.onItemsSorted(event);
            });
    }

    private onItemsSorted(event) {
        const { originalSource } = event.data;
        if (originalSource) {
            const originalSourceIndex = originalSource.getAttribute('tag-id');
            const categoryOriginalSourceIndex = originalSource.getAttribute('tagCategory-id');

            if (originalSourceIndex) {
                this.sortCategoryItems.emit(this.getItemsOrder());
            }
            if (categoryOriginalSourceIndex) {
                this.sortCategories.emit(this.getCategoryItemsOrder());
            }
        }
    }

    private getItemsOrder(): number[] {
        const itemsElements = document.querySelectorAll('.items-list>.draggable-item');
        return Array.from(itemsElements, (element) => Number(element.getAttribute('tag-id')));
    }

    private getCategoryItemsOrder(): number[] {
        const itemsElements = document.querySelectorAll('.category-list>.draggable-item');
        return Array.from(itemsElements, (element) => Number(element.getAttribute('tagCategory-id')));
    }

    get itemTypesMap() {
        return CategoryItemTypesMap[this.type] || {};
    }
}
