import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelService } from '@app/data/services/channel.service';

@Component({
    selector: 'app-post-comment-dialog',
    templateUrl: './post-comment-dialog.component.html',
    styleUrls: ['./post-comment-dialog.component.scss'],
    standalone: false,
})
export class PostCommentDialogComponent implements OnInit {
    postComment: string;
    formChecked = false;
    failureMessage;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<PostCommentDialogComponent>,
        private channelService: ChannelService,
    ) {
        this.postComment = data.post.data.analyst_comment;
    }

    ngOnInit() {
        if (this.data.post && this.data.post.comment) {
            this.postComment = this.data.post.comment;
        }
    }

    save() {
        this.formChecked = true;

        this.channelService.saveAnalystComment(this.data.post.data.id, this.postComment).subscribe();
        this.dialogRef.close(this.postComment);
    }
}
