import { Injectable, inject } from '@angular/core';
import { ApiService } from '@app/core/services';
import { ChannelIntegration } from '../models';

@Injectable({ providedIn: 'root' })
export class IntegrationsApiService {
    private apiService = inject(ApiService);

    getIntegrations() {
        return this.apiService.get<ChannelIntegration[]>('/integrations', { withCredentials: true });
    }
}
