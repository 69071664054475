export const creditCardTypes = {
    VISA: {
        img: '/assets/images/visa.png',
        style: {
            width: '43px',
            'margin-left': ' 6px',
        },
    },
    PAYPAL: {
        img: '/assets/images/paypal.png',
        style: {
            width: '45px',
        },
    },
    WESTERNUNION: {
        img: '/assets/images/payment_icons.png',
        style: {
            'object-position': '-288px 0',
            'object-fit': 'none',
            width: '45px',
        },
    },
    MASTERCARD: {
        img: '/assets/images/mastercard.png',
        style: {
            width: '45px',
        },
    },
    MAESTRO: {
        img: '/assets/images/maestro.png',
        style: {
            width: '45px',
        },
    },
    DANKORT: {
        img: '/assets/images/dankort.jpg',
        style: {
            width: '45px',
        },
    },
};
