import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PostTypes } from '@app/shared/interfaces/post-type.interface';
import { Channel, Post } from '@app/data/models';
import { PostService } from '@app/data/services';

@Component({
    selector: 'app-post-header',
    templateUrl: './post-header.component.html',
    styleUrls: ['./post-header.component.scss'],
    standalone: false,
})
export class PostHeaderComponent {
    @Input() post: Post;
    @Input() readerMode: boolean;
    @Input() channel: Channel;
    @Input() index: number;
    @Input() isActiveSearch = false;
    @Input() hasAdminRights = false;

    @Output() includeInNewsletter = new EventEmitter<Post>();
    @Output() includeInNewsletterRange = new EventEmitter<Post>();
    @Output() addPost = new EventEmitter<Post>();

    postTypes: typeof PostTypes = PostTypes;
    #postService = inject(PostService);

    onIncludeInNewsletter(event: Post) {
        this.includeInNewsletter.emit(event);
    }

    onIncludeInNewsletterRange(event: Post) {
        this.includeInNewsletterRange.emit(event);
    }

    onAddPost(event: Post) {
        this.addPost.emit(event);
    }

    enabledChannelDistribution() {
        return this.#postService.isAutoSelectionDisabled(this.channel);
    }
}
