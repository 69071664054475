@if (vm$ | async; as vm) {
    <div class="modal-component">
        @if (vm.errorMessage) {
            <app-alert (closed)="resetErrorMessage()">{{ vm.errorMessage }}</app-alert>
        }
        <div class="modal-header">
            <h2 class="title">{{ templateConfig.staticText.title }}</h2>
        </div>

        <div class="modal-body" *msLoadingSpinner="!vm.promptsRequestStatusLoaded && !vm.promptsRequestStatusFailed">
            @if (!(evaluationRequestLoaded$ | async)) {
                @if (vm.promptVariables?.length) {
                    <form novalidate class="evaluation-form" [formGroup]="form" *msLoadingSpinner="!form">
                        <mat-form-field class="app-mat-form-field" appearance="outline">
                            <mat-label>Number of included posts to process</mat-label>
                            <input
                                type="number"
                                formControlName="num_posts"
                                matInput
                                [min]="1"
                                [max]="templateConfig.maxPosts"
                            />
                            <mat-error *ngIf="form.get('num_posts').errors?.['required']">
                                Number of posts is required
                            </mat-error>
                            <mat-error *ngIf="form.get('num_posts').errors?.['min']"> Minimum value is 1 </mat-error>
                            <mat-error *ngIf="form.get('num_posts').errors?.['max']">
                                Maximum value is {{ templateConfig.maxPosts }}
                            </mat-error>
                        </mat-form-field>
                        <p>AI role parameters:</p>
                        @for (variable of vm.promptVariables; track variable) {
                            <mat-form-field class="app-mat-form-field" appearance="outline">
                                <mat-label>{{ variable.placeholder_text }}</mat-label>
                                @if (variable.possible_values?.length) {
                                    <mat-select
                                        [formControlName]="variable.key"
                                        [placeholder]="variable.placeholder_text"
                                    >
                                        @for (variable of variable.possible_values; track variable) {
                                            <mat-option [value]="variable">
                                                {{ variable }}
                                            </mat-option>
                                        }
                                    </mat-select>
                                } @else {
                                    <input
                                        type="text"
                                        [formControlName]="variable.key"
                                        [placeholder]="variable.placeholder_text"
                                        matInput
                                    />
                                }
                                @if (variable.help_text) {
                                    <mat-icon matSuffix [matTooltip]="variable.help_text">
                                        <i class="fal fa-question-circle"></i>
                                    </mat-icon>
                                }
                                <mat-error> {{ variable.key | titlecase }} is required </mat-error>
                            </mat-form-field>
                        }
                    </form>
                    <p [innerHTML]="templateConfig.staticText.actionDescription"></p>
                    <div class="form_btns">
                        <button class="cancel-button btn" [mat-dialog-close]="false" mat-flat-button>Cancel</button>
                        <button
                            class="btn primary_btn"
                            (click)="submitEvaluation()"
                            mat-raised-button
                            [disabled]="vm.evaluationRequestLoading"
                            [matomoClickCategory]="trackingCategory"
                            matomoClickAction="click"
                            [matomoClickName]="templateConfig.submitTrackingName"
                        >
                            <ng-container *msLoadingSpinner="vm.evaluationRequestLoading; diameter: 20">{{
                                templateConfig.staticText.submitButton
                            }}</ng-container>
                        </button>
                    </div>
                } @else {
                    <p>Something went wrong. Try again later, or contact support if the issue persists.</p>
                }
            } @else {
                <p>
                    {{ templateConfig.staticText.confirmation }} <br />
                    <b>You can only make one request with same input per 5 minutes.</b>
                </p>
                <div class="form_btns">
                    <button class="cancel-button btn float-right" [mat-dialog-close]="false" mat-flat-button>
                        Close
                    </button>
                </div>
            }
        </div>
    </div>
}
