@if (!selectionMode && !sortMode) {
    <div class="select-mode-actions">
        <div class="actions">
            <button mat-stroked-button (click)="onClickToggleSelectionMode()">Select</button>
        </div>
        <div class="actions">
            <button mat-stroked-button (click)="onClickToggleSortMode()">Sort</button>
        </div>
    </div>
}
@if (selectionMode) {
    <div class="actions">
        <button mat-stroked-button (click)="onClickToggleSelectionMode()">Cancel</button>
        <button mat-stroked-button [disabled]="!selectedItemsCount" (click)="onClickMove()">
            <mat-icon class="size-auto arrow-to-right"><i class="fal fa-arrow-to-right"></i></mat-icon>
            <span
                >Move <span class="items-count">({{ selectedItemsCount }})</span></span
            >
        </button>
        <button mat-stroked-button class="delete" [disabled]="!selectedItemsCount" (click)="onClickDelete()">
            <mat-icon class="size-auto"><i class="fal fa-trash-alt"></i></mat-icon>
            <span
                >Delete <span class="items-count">({{ selectedItemsCount }})</span></span
            >
        </button>
    </div>
}
@if (sortMode) {
    <div class="actions">
        <button mat-stroked-button (click)="onClickToggleSortMode()">Cancel</button>
        <button mat-stroked-button (click)="onClickSaveSort()">
            <span>Save</span>
        </button>
    </div>
}
