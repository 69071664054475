import { Injectable, inject } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { PromptsDTO, ReportType } from '@app/data/models';
import { AIReportType } from '@app/shared/dialogs/ai/ai-analytics-modals/ai-analytics.interfaces';

@Injectable({ providedIn: 'root' })
export class AIApiService {
    private apiService = inject(ApiService);
    private readonly basePath = '/ai';

    getReportTypePrompts(reportType: string): Observable<PromptsDTO[]> {
        return this.apiService.get(`${this.basePath}/report_types/${reportType}/prompts/`, {
            withCredentials: true,
        }) as any;
    }

    evaluateRisk(params: {
        channelId: number;
        variables: { key: string; value: string; name: string }[];
        num_posts: number;
    }) {
        return this.apiService.post(
            `${this.basePath}/channel/${params.channelId}/generate_report/risk-evaluation`,
            {
                variables: params.variables,
                num_posts: params.num_posts,
            },
            { withCredentials: true },
        );
    }

    generateReport(params: {
        channelId: number;
        variables: { key: string; value: string; name: string }[];
        reportType: ReportType;
        num_posts: number;
    }) {
        return this.apiService.post(
            `${this.basePath}/channel/${params.channelId}/generate_report/${params.reportType}`,
            {
                variables: params.variables,
                num_posts: params.num_posts,
            },
            {
                withCredentials: true,
            },
        );
    }

    updatePostAIScore(params: { postId: string; aiScore: Record<string, string> }) {
        return this.apiService.put(`${this.basePath}/post/${params.postId}/score`, params.aiScore, {
            withCredentials: true,
        });
    }

    getReportTypes(): Observable<AIReportType[]> {
        return this.apiService.get<AIReportType[]>(`${this.basePath}/report_types/`);
    }
}
