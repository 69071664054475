import { Component, Input, OnInit, inject } from '@angular/core';
import { matIconClassBySearchType } from '@app/core/constants/search-icons';
import { SearchItem } from '@app/data/models';
import { SearchStatus } from '@app/data/models/search.model';

type Collections = {
    id: number;
    name: string;
};
@Component({
    selector: 'app-search-item',
    templateUrl: './search-item.component.html',
    styleUrls: ['./search-item.component.scss'],
    standalone: false,
})
export class SearchItemComponent implements OnInit {
    @Input() search: SearchItem;
    @Input() searchType: string;
    @Input() collections: Collections[];
    @Input() sizeClass: 'size-md' | 'size-lg' = 'size-md';
    @Input() selected: boolean;
    @Input() editable: boolean;
    @Input() interactable: boolean;
    @Input() draggable: boolean;

    matIconClass: string;
    itemWrapperClass = '';
    scoreClass: string;

    ngOnInit(): void {
        this.matIconClass = this.getSearchTypeParameterByKey('icon-class');
        this.itemWrapperClass = this.getSearchTypeParameterByKey('wrapper-class');
        this.setScoreClass();
    }

    private getSearchTypeParameterByKey(configKey: string): string {
        const searchType = this.getSearchType();

        const searchDataMapping = matIconClassBySearchType[searchType];
        return searchDataMapping ? searchDataMapping[configKey] : '';
    }

    private getSearchType(): string {
        if (this.searchType) {
            return this.searchType;
        }
        if (this.collections) {
            if (this.collections?.length > 1) {
                return 'multiple';
            } else {
                return this.collections[0].name;
            }
        }
        return 'unknown';
    }

    private setScoreClass() {
        if (this.search.score != null) {
            this.scoreClass = `score score-${this.search.score}`;
        } else {
            this.scoreClass = '';
        }
    }

    hasErrors(): boolean {
        return this.search.status && this.search.status !== SearchStatus.Active;
    }

    getErrorTooltip(): string {
        if (this.search.error_messages && this.search.error_messages.length > 0) {
            return this.search.error_messages.join('\n');
        }
        return this.getStatusMessage();
    }

    private getStatusMessage(): string {
        switch (this.search.status) {
            case SearchStatus.LackOfCredits:
                return 'Search crawl is disabled due to lack of credits';
            case SearchStatus.InternalError:
            case SearchStatus.ExternalError:
                return 'Search crawl is disabled due to an error';
            default:
                return '';
        }
    }
}
