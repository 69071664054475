import { Injectable, inject } from '@angular/core';
import { ModalType, PromptVariableForSave } from './ai-analytics.interfaces';
import { AIApiService } from '@app/data/services';
import { ReportType } from '@app/data/models';

@Injectable()
export class AIAnalyticsService {
    private submitMethodName: string;
    private aiReportType: string;
    private aiApiService = inject(AIApiService);

    setVariableFieldsForSave(variable: string, key: string): PromptVariableForSave {
        return {
            key,
            value: variable,
        };
    }

    submitAIEvaluation(evaluationParams: {
        channelId: number;
        variables: { key: string; value: string }[];
        num_posts: number;
        reportType: ReportType;
    }) {
        return this.aiApiService[this.submitMethodName](evaluationParams);
    }

    getAIPrompts() {
        return this.aiApiService.getReportTypePrompts(this.aiReportType);
    }

    constructor(modalType: ModalType) {
        this.submitMethodName = {
            [ModalType.REPORT]: 'generateReport',
            [ModalType.SCORE]: 'evaluateRisk',
        }[modalType];
        this.aiReportType = {
            [ModalType.REPORT]: 'summary-report',
            [ModalType.SCORE]: 'risk-evaluation',
        }[modalType];
    }
}
