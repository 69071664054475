import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';

import { tap } from 'rxjs/operators';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false,
})
export class LoaderComponent implements OnInit {
    isLoaderVisible: boolean;
    readonly isLocal = false;
    private untilDestroy = untilDestroyed();

    constructor(private loaderService: LoaderService) {}

    ngOnInit() {
        this.loaderService.toggleLoaderSubject$
            .pipe(
                this.untilDestroy(),
                tap((isVisible) => (this.isLoaderVisible = isVisible)),
            )
            .subscribe();
    }
}
