import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ArticleService {
    constructor(private apiService: ApiService) {}

    createNewsArticle(data) {
        return this.apiService.post('/news', data, { withCredentials: true });
    }

    createMarketplaceArticle(data) {
        return this.apiService.post('/marketplace', data, { withCredentials: true });
    }

    createSocialMediaArticle(data) {
        return this.apiService.post('/social-media', data, { withCredentials: true });
    }

    createForumArticle(data) {
        return this.apiService.post('/forum', data, { withCredentials: true });
    }

    createWhoisArticle(data) {
        return this.apiService.post('/whois', data, { withCredentials: true });
    }

    createCreditCardArticle(data) {
        return this.apiService.post('/credit-card', data, { withCredentials: true });
    }

    editArticle(postId, data) {
        return this.apiService.patch(`/edit-post/${postId}`, data, { withCredentials: true });
    }

    downloadFile(storageId: string, bucketId: string) {
        return this.apiService.getFile(`/post/file/${storageId}/${bucketId}`, {
            withCredentials: true,
        });
    }
}
