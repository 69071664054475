import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(private title: Title) {}

    sliceArray(arr, page, pageSize) {
        const x = (page - 1) * pageSize;
        let y = page * pageSize;

        if (arr.length - x < y) {
            y = x + (arr.length - x);
        }
        return arr.slice(x, y);
    }

    setArrPaginationID(index, pageSize, currentPage) {
        return index + currentPage * pageSize;
    }

    getElementHeight(e) {
        return e.nativeElement.offsetHeight;
    }

    /*Sets scrollbar position by div ID and new position*/
    setScorllPosition(diV_ID: string, position: number) {
        const mainDiv = document.getElementById(diV_ID);
        mainDiv.scrollTop = position;
    }

    truncateString(string: string, maxStringSize = 10_000) {
        if (string?.length > maxStringSize) {
            return string.substring(0, maxStringSize) + '...';
        }
        return string;
    }

    resetCookies() {
        window.document.cookie.split(';').forEach(function (c) {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/');
        });
    }

    isLettersAndNumbersOnly(value: string) {
        return /^[a-z0-9\s]+$/i.test(value);
    }

    filterNestedArray<T>(itemsArray: T[], term: string, nestedFieldNames: string[], searchByField = 'name'): T[] {
        if (!term || !itemsArray) return itemsArray;
        const searchTermReg = new RegExp(term, 'i');
        return this.recursiveFiltering(
            JSON.parse(JSON.stringify(itemsArray)),
            searchTermReg,
            ['', ...nestedFieldNames],
            searchByField,
        );
    }

    nameValueArrayToObject<T>(arr: { name: string; value: T }[]): { [key: string]: T } {
        const obj = {};
        arr?.forEach((option) => {
            obj[option.name] = option.value;
        });
        return obj;
    }

    setTitleDescriptor(descriptor: string | null) {
        const titleBase = this.title.getTitle().split('|')[0];
        if (descriptor) this.title.setTitle(`${titleBase} | ${descriptor}`);
        else this.title.setTitle(titleBase);
    }

    mergeSearchParams(newValue: HttpParams, oldValue: HttpParams): HttpParams {
        let mergedParams = newValue;
        oldValue.keys().forEach((key) => {
            if (!newValue.has(key)) {
                mergedParams = mergedParams.append(key, oldValue.get(key));
            }
        });
        newValue.keys().forEach((key) => {
            if (newValue.get(key) === 'null') {
                mergedParams = mergedParams.delete(key);
            }
        });
        return mergedParams;
    }

    downloadBlob(blob: Blob, fileName: string = null) {
        const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName || 'file';
        a.dispatchEvent(new MouseEvent('click'));
        window.URL.revokeObjectURL(url);
    }

    private recursiveFiltering(items: any[], term: RegExp, nestedFieldNames: string[], searchByField: string): any[] {
        if (nestedFieldNames.length) {
            const fieldName = nestedFieldNames[0];
            let listToFilter: any[];

            if (!fieldName) {
                listToFilter = [...items];
            } else {
                listToFilter = [...items[fieldName]];
            }
            return listToFilter.filter((listItem) => {
                if (term.test(listItem[searchByField])) {
                    return true;
                } else {
                    const fieldNamesForNextIteration = [...nestedFieldNames];
                    fieldNamesForNextIteration.shift();
                    const nextFieldName = fieldNamesForNextIteration[0];
                    if (nextFieldName === undefined || listItem[nextFieldName] === undefined) {
                        return false;
                    }
                    const filteredNestedItems = this.recursiveFiltering(
                        listItem,
                        term,
                        fieldNamesForNextIteration,
                        searchByField,
                    );
                    listItem[nextFieldName] = [...filteredNestedItems];
                    if (filteredNestedItems.length) {
                        return true;
                    }
                }
            });
        }
        return items;
    }
}
