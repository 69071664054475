import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { ChannelService } from '@app/data/services/channel.service';
import { TagItem } from '@app/data/models';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, finalize, tap } from 'rxjs/operators';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';

@Component({
    selector: 'app-post-assign-items-dialog',
    templateUrl: './post-assign-items-dialog.component.html',
    styleUrls: ['./post-assign-items-dialog.component.scss'],
    standalone: false,
})
export class PostAssignItemsDialogComponent implements OnInit {
    selectedTags: TagItem[] = [];
    failureMessage: string;
    isSubmitting = false;
    private untilDestroy = untilDestroyed();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { post: { tags: TagItem[]; data: any } },
        public dialogRef: MatDialogRef<PostAssignItemsDialogComponent>,
        private channelService: ChannelService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.setTags();
        this.closeModalOnRouteChange();
    }

    setTags() {
        this.selectedTags = [...this.data.post.tags];
    }

    onToggleTag(item: TagItem) {
        const initialTagsLength = this.selectedTags.length;
        this.selectedTags = this.selectedTags.filter((tagItem) => tagItem.id !== item.id);
        if (initialTagsLength === this.selectedTags.length) {
            this.selectedTags.push(item);
        }
        this.resetFailureMessage();
    }

    onSelectItems(items: TagItem[]) {
        items.forEach((item) => {
            if (!this.selectedTags.some((tagItem) => tagItem.id === item.id)) {
                this.selectedTags.push(item);
            }
        });
        this.resetFailureMessage();
    }

    onUnSelectItems(items: TagItem[]) {
        items.forEach((item) => {
            this.selectedTags = this.selectedTags.filter((tagItem) => tagItem.id !== item.id);
        });
        this.resetFailureMessage();
    }

    save() {
        const requestData = this.getUpdateTagsRequestData();

        if (!requestData) {
            this.dialogRef.close(this.selectedTags);
            return;
        }
        this.isSubmitting = true;

        this.channelService
            .addTagsToPost(this.data.post.data.id, requestData)
            .pipe(
                tap(() => {
                    this.dialogRef.close(this.selectedTags);
                }),
                catchError((error) => {
                    console.error(`PostAssignItemsDialogComponent => save() => ${error}`);
                    this.failureMessage = error;
                    return of(error);
                }),
                finalize(() => {
                    this.isSubmitting = false;
                }),
            )
            .subscribe();
    }

    close() {
        this.dialogRef.close();
    }

    resetFailureMessage() {
        this.failureMessage = null;
    }

    private getUpdateTagsRequestData(): { added: number[]; removed: number[] } {
        const added = this.getTagIdsToAdd();
        const removed = this.getTagIdsToRemove();

        if (!added.length && !removed.length) {
            return null;
        }

        return { added, removed };
    }

    private getTagIdsToAdd(): number[] {
        return this.selectedTags
            .filter(
                (selectedTagItem) => !this.data.post.tags.some((postTagItem) => postTagItem.id === selectedTagItem.id),
            )
            .map((tagItem) => tagItem.id);
    }

    private getTagIdsToRemove(): number[] {
        return this.data.post.tags
            .filter(
                (selectedTagItem) => !this.selectedTags.some((postTagItem) => postTagItem.id === selectedTagItem.id),
            )
            .map((tagItem) => tagItem.id);
    }

    private closeModalOnRouteChange() {
        this.router.events
            .pipe(
                this.untilDestroy(),
                filter((event) => event instanceof NavigationStart),
                tap(() => {
                    this.dialogRef.close();
                }),
            )
            .subscribe();
    }

    get submitDisabled(): boolean {
        return !this.getTagIdsToRemove().length && !this.getTagIdsToAdd().length;
    }
}
