<mat-list-item
    class="item-header"
    [ngClass]="post.isExpanded ? 'expanded' : ''"
    [class.distributed]="post.data.previously_distributed"
>
    @if (post.data.is_read !== true) {
        <span class="read"></span>
    }

    <app-post-type-image [post]="post"></app-post-type-image>

    <div class="header_wrapper">
        <div
            class="title_wrapper"
            [ngClass]="post.isExpanded ? 'expanded' : ''"
            [class.distributed]="post.data.previously_distributed && post.isExpanded && curatorMode"
        >
            @if (post.isExpanded) {
                <app-post-type-label [post]="post"></app-post-type-label>
            }
            @switch (post.type) {
                @case ('forum') {
                    <h3 mat-line [ngClass]="post.isExpanded ? 'expanded' : ''">
                        {{ post.data.section_name || post.data.title }}
                    </h3>
                }
                @default {
                    <h3 mat-line [ngClass]="post.isExpanded ? 'expanded' : ''">
                        {{ post.data.title }}
                    </h3>
                }
            }
            @if (post.data.publish_date || post.data.published_at) {
                <div class="date_author">
                    @if (post.data.previously_distributed && post.isExpanded && curatorMode) {
                        <span class="item_distributed"
                            ><b>Distributed</b>
                            <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                        ></span>
                    }
                    @if (!post.isExpanded) {
                        <div class="item-info">
                            @if (post.data.indexed_at) {
                                <span
                                    matTooltip="{{
                                        dateService.setDateISO8601(post.data.indexed_at)
                                            | date: dateService.getDateFormat()
                                    }}"
                                    matTooltipPosition="above"
                                >
                                    Crawled: <b>{{ dateService.getDateDiff('' + post.data.indexed_at) }}</b>
                                </span>
                            }
                            <span
                                matTooltip="{{
                                    dateService.setDateISO8601(post.data.publish_date || post.data.published_at)
                                        | date: dateService.getDateFormat()
                                }}"
                                matTooltipPosition="above"
                            >
                                Posted:
                                <b>{{
                                    dateService.getDateDiff('' + (post.data.publish_date || post.data.published_at))
                                }}</b>
                            </span>

                            @if (post.data.author) {
                                <span>
                                    Author: <b>{{ post.data.author }}</b>
                                </span>
                            }
                            @if (post.data.source) {
                                <span [matTooltip]="post.data.source">
                                    Source: <b>{{ post.data.source }}</b>
                                </span>
                            }
                            @if (post.data.url) {
                                <span [matTooltip]="post.data.url">
                                    URL: <b>{{ post.data.url }}</b>
                                </span>
                            }
                            @if (post.data.previously_distributed && curatorMode) {
                                <span>
                                    <b
                                        >Distributed
                                        <mat-icon class="size-auto distributed"
                                            ><i class="far fa-check"></i></mat-icon></b
                                ></span>
                            }
                            @if (post.data.is_manual) {
                                <span><b>Added manually</b></span>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</mat-list-item>
