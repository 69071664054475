import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    setDateISO8601(date: string) {
        // IE and safari invalid date fix
        let dateObj = new Date(date);
        let res = '';
        if (isNaN(dateObj.getTime()) && date) {
            res = date.replace(/-/g, '/');
            dateObj = new Date(res);
            if (isNaN(dateObj.getTime())) {
                return '';
            } else return res;
        } else {
            return date;
        }
    }

    toLocalISODate(date: string) {
        const localTime = this.toLocalTime(date);
        return this.setDateISO8601(localTime);
    }

    getDateFormat(withoutTime = false) {
        if (withoutTime) {
            return 'd MMMM y';
        }
        const ua = window.navigator.userAgent;

        const msie = ua.indexOf('MSIE ');
        const trident = ua.indexOf('Trident/');
        const edge = ua.indexOf('Edge/');

        if (msie > 0 || trident > 0 || edge > 0) {
            // IE 10 or older or IE 11 or Edge (IE 12+)
            return 'd MMMM y - HH';
        } else {
            return 'd MMMM y - H:mm';
        }
    }

    getDateDiff(date: string) {
        const oneDay = 24 * 60 * 60 * 1000;
        // get timezone offset in ms plus 1 hour for Denmark timezone
        const tzOffset = (new Date().getTimezoneOffset() + 60) * 60 * 1000;

        // IE and safari invalid date fix
        date = this.setDateISO8601(date);

        let datediff = (new Date().getTime() - new Date(date).getTime() + tzOffset) / oneDay;

        if (isNaN(datediff)) {
            return '';
        } else {
            if (Math.floor(datediff) < 1) {
                datediff = datediff * 24;

                if (Math.floor(datediff) < 1) {
                    datediff = datediff * 60;

                    if (Math.floor(datediff) >= 2) {
                        return Math.floor(datediff) + ' minutes ago';
                    } else {
                        return 'recently';
                    }
                } else {
                    if (Math.floor(datediff) > 1) {
                        return Math.floor(datediff) + ' hours ago';
                    } else {
                        return Math.floor(datediff) + ' hour ago';
                    }
                }
            }

            if (Math.floor(datediff) > 1) {
                return Math.floor(datediff) + ' days ago';
            } else {
                return Math.floor(datediff) + ' day ago';
            }
        }
    }

    isUpdated(publishDate: string, updateDate: string, uploadDate?: string) {
        if ((updateDate == undefined || updateDate == null) && uploadDate != undefined && uploadDate != null) {
            updateDate = uploadDate;
        }

        const oneDay = 24 * 60 * 60 * 1000;
        const datediff = (new Date(updateDate).getTime() - new Date(publishDate).getTime()) / oneDay;

        return (
            Math.floor(datediff) > 1 ||
            Math.floor(datediff * 24) > 1 ||
            Math.floor(datediff * 24 * 60) > 1 ||
            Math.floor(datediff * 24 * 60 * 60) > 1 ||
            Math.floor(datediff * 24 * 60 * 60 * 1000) > 1
        );
    }

    isOlderThan(date: string | Date, maxAgeMinutes: number): boolean {
        const currentDate = new Date().getTime();
        const oldDate = new Date(date).getTime();
        const maxAgeMs = maxAgeMinutes * 60 * 1000;
        return currentDate - oldDate > maxAgeMs;
    }

    toLocalTime(date: string | Date): string {
        // API responses are returned in CET timezone but without the timezone
        const CETTimezoneShift = '+0100';
        let dateString: string;
        if (typeof date != 'string') {
            dateString = date.toISOString();
        } else {
            dateString = date;
        }
        return dateString.concat(CETTimezoneShift);
    }
}
