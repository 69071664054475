import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DateService } from '@app/core/services';
import { ImagePreviewDialogComponent } from '@app/shared/dialogs/image-preview-dialog/image-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-whois-body',
    templateUrl: './whois-body.component.html',
    styleUrls: ['./whois-body.component.scss'],
    standalone: false,
})
export class WhoisBodyComponent {
    @Input() post;
    @Input() reader;

    @Output() openImagePreviewEvent = new EventEmitter<any>();

    openPreviewRef;
    public dateService = inject(DateService);
    private dialog = inject(MatDialog);

    onImgError() {
        this.post.data.screenshot_url = '';
    }

    openImagePreview() {
        this.openImagePreviewEvent.emit(true);
        this.openPreviewRef = this.dialog.open(ImagePreviewDialogComponent, {
            panelClass: 'modal-lg',
            autoFocus: false,
            restoreFocus: false,
            data: { screenshotUrl: this.post.data.screenshot_url },
        });

        this.openPreviewRef
            .afterClosed()
            .pipe(tap(() => this.openImagePreviewEvent.emit(false)))
            .subscribe();
    }
}
