export const matIconClassBySearchType = {
    news: { 'wrapper-class': 'globe', 'icon-class': 'fas fa-globe' },
    social_media: { 'wrapper-class': 'social-media', 'icon-class': 'fad fa-users' },
    marketplace: { 'wrapper-class': 'shopping-cart', 'icon-class': 'fas fa-shopping-cart' },
    forum: { 'wrapper-class': 'comments-alt', 'icon-class': 'fas fa-comments-alt' },
    datadump: { 'wrapper-class': 'file-invoice', 'icon-class': 'fas fa-file-invoice' },
    creditcard: { 'wrapper-class': 'credit-card', 'icon-class': 'fas fa-credit-card' },
    databreach: { 'wrapper-class': 'lock-alt', 'icon-class': 'fas fa-lock-alt' },
    breach: { 'wrapper-class': 'lock-alt', 'icon-class': 'fas fa-lock-alt' },
    stealers_leaks: { 'wrapper-class': 'unlock', 'icon-class': 'fas fa-unlock' },
    whois: { 'wrapper-class': 'cloud', 'icon-class': 'fas fa-cloud' },
    exposed_services: { 'wrapper-class': 'server', 'icon-class': 'fas fa-server' },
    darkweb: { 'wrapper-class': 'spider-web', 'icon-class': 'fas fa-spider-web' },
    ext_chat: { 'wrapper-class': 'globe', 'icon-class': 'fas fa-globe' },
    ext_twitter: { 'wrapper-class': 'social-media', 'icon-class': 'fad fa-users' },
    ext_creditcard: { 'wrapper-class': 'credit-card', 'icon-class': 'fas fa-credit-card' },
    ext_whois: { 'wrapper-class': 'cloud', 'icon-class': 'fas fa-cloud' },
    opoint: { 'wrapper-class': 'globe', 'icon-class': 'fas fa-globe' },
    ext_marketplace: { 'wrapper-class': 'shopping-cart', 'icon-class': 'fas fa-shopping-cart' },
    ext_forum: { 'wrapper-class': 'comments-alt', 'icon-class': 'fas fa-comments-alt' },
    ext_news: { 'wrapper-class': 'globe', 'icon-class': 'fas fa-globe' },
    ext_blog: { 'wrapper-class': 'globe', 'icon-class': 'fas fa-globe' },
    ext_databreach: { 'wrapper-class': 'lock-alt', 'icon-class': 'fas fa-lock-alt' },
    ext_exposed_services: { 'wrapper-class': 'server', 'icon-class': 'fas fa-server' },
    ext_stealers_leaks: { 'wrapper-class': 'unlock', 'icon-class': 'fas fa-unlock' },
    multiple: { 'wrapper-class': 'multiple', 'icon-class': 'fak fa-solid-th-magnifying-glass' },
};
