<div class="post-details">
    <div class="post_brief_actions" [class.row-content]="!reader">
        @if (post.data.screenshot_url && reader) {
            <div class="screenshot_wrapper">
                <img class="screenshot_url" [src]="post.data.screenshot_url" (error)="onImgError()" />

                <div class="overlay" (click)="openImagePreview()">
                    <mat-icon><i class="fal fa-search-plus"></i></mat-icon>
                </div>
            </div>
        }
        @if (post.data.screenshot_url && !reader) {
            <img class="post_image" [src]="post.data.screenshot_url" (error)="onImgError()" />
        }

        <div class="dates_actions">
            @if (post.data.crawled_at && !reader) {
                <div class="post_source" [class.reader_modal]="reader">
                    <span class="label">Event date: </span>
                    <span>{{
                        dateService.setDateISO8601(post.data.publish_date || post.data.crawled_at) | date: 'd MMMM y'
                    }}</span>
                </div>
            }

            <div class="post_source">
                <span class="label">Added manually</span>
            </div>
            @if (post.data['record.registrant.organization']) {
                <div class="post_source" [class.reader_modal]="reader">
                    <span class="label">Registrant organization: </span>
                    <span>{{ post.data['record.registrant.organization'] }}</span>
                </div>
            }
            @if (post.data['record.registrant.state']) {
                <div class="post_source" [class.reader_modal]="reader">
                    <span class="label">State: </span>
                    <span>{{ post.data['record.registrant.state'] }}</span>
                </div>
            }
            @if (post.data['record.registrant.country']) {
                <div class="post_source" [class.reader_modal]="reader">
                    <span class="label">Country: </span>
                    <span>{{ post.data['record.registrant.country'] }}</span>
                </div>
            }
        </div>
    </div>
</div>
@if (reader) {
    <div class="reader_modal">
        @if (post.data.action) {
            <div class="post_source">
                <span class="label">Event: </span>
                <span>{{ post.data.action }}</span>
            </div>
        }
        @if (post.data.crawled_at) {
            <div class="post_source">
                <span class="label">Event date: </span>
                <span>{{
                    dateService.setDateISO8601(post.data.publish_date || post.data.crawled_at) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.createdDate']) {
            <div class="post_source">
                <span class="label">Created Date: </span>
                <span>{{ dateService.setDateISO8601(post.data['record.createdDate']) | date: 'd MMMM y' }}</span>
            </div>
        }
        @if (post.data['record.registrarName']) {
            <div class="post_source">
                <span class="label">Registrar Name: </span>
                <span>{{ post.data['record.registrarName'] }}</span>
            </div>
        }
        @if (post.data['status']) {
            <div class="post_source">
                <span class="label">Status: </span>
                <span>{{ post.data['status'] }}</span>
            </div>
        }
        @if (post.data['record.createdDateNormalized']) {
            <div class="post_source">
                <span class="label">Сreated Date Normalized: </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.createdDateNormalized']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registrant.contactId']) {
            <div class="post_source">
                <span class="label">Registrant Contact Id: </span>
                <span>{{ post.data['record.registrant.contactId'] }}</span>
            </div>
        }
        @if (post.data['record.whoisServer']) {
            <div class="post_source">
                <span class="label">Whois Server: </span>
                <span>{{ post.data['record.whoisServer'] }}</span>
            </div>
        }
        @if (post.data['record.customField1Name'] && post.data['record.customField1Value']) {
            <div class="post_source">
                <span class="label">{{ post.data['record.customField1Name'] }}: </span>
                <span>{{ post.data['record.customField1Value'] }}</span>
            </div>
        }
        @if (post.data['record.customField2Name'] && post.data['record.customField2Value']) {
            <div class="post_source">
                <span class="label">{{ post.data['record.customField2Name'] }}: </span>
                <span>{{ post.data['record.customField2Value'] }}</span>
            </div>
        }
        @if (post.data['record.customField3Name'] && post.data['record.customField3Value']) {
            <div class="post_source">
                <span class="label">{{ post.data['record.customField3Name'] }}: </span>
                <span>{{ post.data['record.customField3Value'] }}</span>
            </div>
        }
        @if (post.data['record.updatedDate']) {
            <div class="post_source">
                <span class="label">Updated Date: </span>
                <span>{{ dateService.setDateISO8601(post.data['record.updatedDate']) | date: 'd MMMM y' }}</span>
            </div>
        }
        @if (post.data['record.domainNameExt']) {
            <div class="post_source">
                <span class="label">Domain Name Ext: </span>
                <span>{{ post.data['record.domainNameExt'] }}</span>
            </div>
        }
        @if (post.data['record.contactEmail']) {
            <div class="post_source">
                <span class="label">Contact Email: </span>
                <span>{{ post.data['record.contactEmail'] }}</span>
            </div>
        }
        @if (post.data['record.estimatedDomainAge']) {
            <div class="post_source">
                <span class="label">Estimated Domain Age: </span>
                <span>{{ post.data['record.estimatedDomainAge'] }}</span>
            </div>
        }
        @if (post.data['record.registrarIANAID']) {
            <div class="post_source">
                <span class="label">Registrar IANAID: </span>
                <span>{{ post.data['record.registrarIANAID'] }}</span>
            </div>
        }
        @if (post.data['record.parseCode']) {
            <div class="post_source">
                <span class="label">Parse Code: </span>
                <span>{{ post.data['record.parseCode'] }}</span>
            </div>
        }
        @if (post.data['record.dataErrorFlag'] != undefined) {
            <div class="post_source">
                <span class="label">Data Error Flag: </span>
                <span>{{ post.data['record.dataErrorFlag'] }}</span>
            </div>
        }
        @if (post.data['record.domainName']) {
            <div class="post_source">
                <span class="label">Domain Name: </span>
                <span>{{ post.data['record.domainName'] }}</span>
            </div>
        }
        @if (post.data['record.expiresDate']) {
            <div class="post_source">
                <span class="label">Expires Date: </span>
                <span>{{ dateService.setDateISO8601(post.data['record.expiresDate']) | date: 'd MMMM y' }}</span>
            </div>
        }
        @if (post.data['record.audit.createdDate']) {
            <div class="post_source">
                <span class="label">Audit Created Date: </span>
                <span>{{ dateService.setDateISO8601(post.data['record.audit.createdDate']) | date: 'd MMMM y' }}</span>
            </div>
        }
        @if (post.data['record.audit.updatedDate']) {
            <div class="post_source">
                <span class="label">Audit Updated Date: </span>
                <span>{{ dateService.setDateISO8601(post.data['record.audit.updatedDate']) | date: 'd MMMM y' }}</span>
            </div>
        }
        @if (post.data['record.updatedDateNormalized']) {
            <div class="post_source">
                <span class="label">Updated Date Normalized: </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.updatedDateNormalized']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.expiresDateNormalized']) {
            <div class="post_source">
                <span class="label">Expires Date Normalized: </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.expiresDateNormalized']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.administrativeContact.contactId']) {
            <div class="post_source">
                <span class="label">Contact ID (Administrative Contact): </span>
                <span>{{ post.data['record.administrativeContact.contactId'] }}</span>
            </div>
        }
        @if (post.data['record.administrativeContact.country']) {
            <div class="post_source">
                <span class="label">Country (Administrative Contact): </span>
                <span>{{ post.data['record.administrativeContact.country'] }}</span>
            </div>
        }
        @if (post.data['record.administrativeContact.organization']) {
            <div class="post_source">
                <span class="label">Organization (Administrative Contact): </span>
                <span>{{ post.data['record.administrativeContact.organization'] }}</span>
            </div>
        }
        @if (post.data['record.administrativeContact.state']) {
            <div class="post_source">
                <span class="label">State (Administrative Contact): </span>
                <span>{{ post.data['record.administrativeContact.state'] }}</span>
            </div>
        }
        @if (post.data['record.administrativeContact.parseCode']) {
            <div class="post_source">
                <span class="label">Parse Code (Administrative Contact): </span>
                <span>{{ post.data['record.administrativeContact.parseCode'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.audit.createdDate']) {
            <div class="post_source">
                <span class="label">Audit Created Date(Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.audit.createdDate']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.audit.updatedDate']) {
            <div class="post_source">
                <span class="label">Audit Updated Date(Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.audit.updatedDate']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.createdDateNormalized']) {
            <div class="post_source">
                <span class="label">Created Date Normalized (Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.createdDateNormalized'])
                        | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.updatedDate']) {
            <div class="post_source">
                <span class="label">Updated Date (Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.updatedDate']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.updatedDateNormalized']) {
            <div class="post_source">
                <span class="label">Updated Date Normalized (Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.updatedDateNormalized'])
                        | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.parseCode']) {
            <div class="post_source">
                <span class="label">Parse Code (Registry Data): </span>
                <span>{{ post.data['record.registryData.parseCode'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.dataErrorFlag'] != undefined) {
            <div class="post_source">
                <span class="label">Data Error Flag (Registry Data): </span>
                <span>{{ post.data['record.registryData.dataErrorFlag'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.id']) {
            <div class="post_source">
                <span class="label">ID (Registry Data): </span>
                <span>{{ post.data['record.registryData.id'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.status']) {
            <div class="post_source">
                <span class="label">Status (Registry Data): </span>
                <span>{{ post.data['record.registryData.status'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.registrarIANAID']) {
            <div class="post_source">
                <span class="label">Registrar IANAID (Registry Data): </span>
                <span>{{ post.data['record.registryData.registrarIANAID'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.registrarName']) {
            <div class="post_source">
                <span class="label">Registrar Name (Registry Data): </span>
                <span>{{ post.data['record.registryData.registrarName'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.whoisServer']) {
            <div class="post_source">
                <span class="label">Whois Server (Registry Data): </span>
                <span>{{ post.data['record.registryData.whoisServer'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.customField1Name'] && post.data['record.registryData.customField1Value']) {
            <div class="post_source">
                <span class="label">{{ post.data['record.registryData.customField1Name'] }}: </span>
                <span>{{ post.data['record.registryData.customField1Value'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.customField2Name'] && post.data['record.registryData.customField2Value']) {
            <div class="post_source">
                <span class="label">{{ post.data['record.registryData.customField2Name'] }}: </span>
                <span>{{ post.data['record.registryData.customField2Value'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.customField3Name'] && post.data['record.registryData.customField3Value']) {
            <div class="post_source">
                <span class="label">{{ post.data['record.registryData.customField3Name'] }}: </span>
                <span>{{ post.data['record.registryData.customField3Value'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.createdDate']) {
            <div class="post_source">
                <span class="label">Created Date (Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.createdDate']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.expiresDateNormalized']) {
            <div class="post_source">
                <span class="label">Expires Date Normalized (Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.expiresDateNormalized'])
                        | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.expiresDate']) {
            <div class="post_source">
                <span class="label">Expires Date (Registry Data): </span>
                <span>{{
                    dateService.setDateISO8601(post.data['record.registryData.expiresDate']) | date: 'd MMMM y'
                }}</span>
            </div>
        }
        @if (post.data['record.registryData.domainName']) {
            <div class="post_source">
                <span class="label">Domain Name (Registry Data): </span>
                <span>{{ post.data['record.registryData.domainName'] }}</span>
            </div>
        }
        @if (post.data['record.nameServers.hostNames']) {
            <div class="post_source">
                <span class="label">Host Names: </span>
                <span>{{ post.data['record.nameServers.hostNames'].join(', ') }}</span>
            </div>
        }
        @if (post.data['record.registryData.nameServers.servers']) {
            <div class="post_source">
                <span class="label">Servers (Registry Data): </span>
                <span>{{ post.data['record.registryData.nameServers.servers'].join(', ') }}</span>
            </div>
        }
        @if (post.data['record.registryData.header']) {
            <div class="post_source">
                <span class="label">Header (Registry Data): </span>
                <span>{{ post.data['record.registryData.header'] }}</span>
            </div>
        }
        @if (post.data['record.registryData.footer']) {
            <div class="post_source">
                <span class="label">Footer (Registry Data): </span>
                <span>{{ post.data['record.registryData.footer'] }}</span>
            </div>
        }
        @if (post.data['record.technicalContact.country']) {
            <div class="post_source">
                <span class="label">Сountry (Technical Contact): </span>
                <span>{{ post.data['record.technicalContact.country'] }}</span>
            </div>
        }
        @if (post.data['record.technicalContact.contactId']) {
            <div class="post_source">
                <span class="label">Contact Id (Technical Contact): </span>
                <span>{{ post.data['record.technicalContact.contactId'] }}</span>
            </div>
        }
        @if (post.data['record.technicalContact.state']) {
            <div class="post_source">
                <span class="label">State (Technical Contact): </span>
                <span>{{ post.data['record.technicalContact.state'] }}</span>
            </div>
        }
        @if (post.data['record.technicalContact.organization']) {
            <div class="post_source">
                <span class="label">Organization (Technical Contact): </span>
                <span>{{ post.data['record.technicalContact.organization'] }}</span>
            </div>
        }
        @if (post.data['record.technicalContact.parseCode']) {
            <div class="post_source">
                <span class="label">Parse Code (Technical Contact): </span>
                <span>{{ post.data['record.technicalContact.parseCode'] }}</span>
            </div>
        }
        @if (post.data['record.registrant.country']) {
            <div class="post_source">
                <span class="label">Country (Registrant): </span>
                <span>{{ post.data['record.registrant.country'] }}</span>
            </div>
        }
        @if (post.data['record.registrant.state']) {
            <div class="post_source">
                <span class="label">State (Registrant): </span>
                <span>{{ post.data['record.registrant.state'] }}</span>
            </div>
        }
        @if (post.data['record.registrant.parseCode']) {
            <div class="post_source">
                <span class="label">Parse Code (Registrant): </span>
                <span>{{ post.data['record.registrant.parseCode'] }}</span>
            </div>
        }

        <div class="previous_events">
            <div class="title">
                <span>Domain events </span>

                <div class="icon_tooltip">
                    <mat-icon><i class="far fa-question-circle"></i></mat-icon>

                    <div class="modal_tooltip">
                        <div class="text">This is a list of events for the domain registered in the system</div>
                    </div>
                </div>
            </div>

            <div class="event">
                @for (event of post.data.domain_events; track event) {
                    <div class="events_info info">
                        <div class="label">{{ event.action }}:</div>
                        <span class="value">{{ dateService.setDateISO8601(event.crawled_at) | date: 'd MMMM y' }}</span>
                    </div>
                }
            </div>
        </div>
    </div>
}
