<mat-list-item class="item-header" [ngClass]="post.isExpanded ? 'expanded' : ''">
    <div class="shodan_wrap">
        @if (post.data.is_read !== true) {
            <span class="read"></span>
        }
        @if (!reader) {
            <app-post-type-image [post]="post"></app-post-type-image>
        }
        @if (!post.isExpanded && !reader) {
            <div class="shodan_text_wrap">
                <div class="info-wrapper">
                    <div class="info-wrapper-row">
                        <span class="shodan_text_item ip-address">{{ postViewData.ip }}</span>
                    </div>
                    <div class="info-wrapper-row details">
                        @if (post.data.indexed_at) {
                            <span
                                matTooltip="{{
                                    dateService.setDateISO8601(post.data.indexed_at) | date: dateService.getDateFormat()
                                }}"
                                matTooltipPosition="above"
                            >
                                Crawled: <b>{{ dateService.getDateDiff('' + post.data.indexed_at) }}</b>
                            </span>
                        }
                        @if (postViewData.published_at) {
                            <span
                                matTooltip="{{
                                    dateService.setDateISO8601(postViewData.published_at)
                                        | date: dateService.getDateFormat()
                                }}"
                                matTooltipPosition="above"
                            >
                                Posted: <b>{{ dateService.getDateDiff('' + postViewData.published_at) }}</b>
                            </span>
                        }
                        @if (postViewData.location) {
                            <span class="shodan_text_item location" [matTooltip]="postViewData.location"
                                >Location:
                                <span class="shodan_span_item">{{ postViewData.location }}</span>
                            </span>
                        }
                        @if (post.data.is_manual) {
                            <span><span class="divider"></span>Added manually</span>
                        }
                        @if (post.data.previously_distributed && curatorMode) {
                            <span
                                ><span class="divider"></span><b>Distributed</b>
                                <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                            ></span>
                        }
                    </div>
                </div>
            </div>
        }
        @if (post.isExpanded || reader) {
            <div
                class="shodan_text_wrap"
                [class.reader]="reader"
                [ngClass]="post.isExpanded || reader ? 'expanded' : ''"
            >
                <div
                    class="header_wrapper"
                    [ngClass]="post.isExpanded ? 'expanded' : ''"
                    [class.distributed]="post.data.previously_distributed && post.isExpanded && curatorMode"
                >
                    @if (post.isExpanded) {
                        <app-post-type-label [post]="post"></app-post-type-label>
                    }
                    <h3 mat-line [ngClass]="post.isExpanded ? 'expanded' : ''">
                        {{ post.data.ip_str }}
                    </h3>
                    @if (post.data.previously_distributed && post.isExpanded && curatorMode) {
                        <span class="item_distributed"
                            ><b>Distributed</b>
                            <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                        ></span>
                    }
                </div>
            </div>
        }
    </div>
</mat-list-item>
