import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchService } from '@app/data/services/search.service';
import { catchError, finalize, tap } from 'rxjs/operators';

@Component({
    selector: 'app-search-help-dialog',
    templateUrl: './search-help-dialog.component.html',
    styleUrls: ['./search-help-dialog.component.scss'],
    standalone: false,
})
export class SearchHelpDialogComponent implements OnInit {
    document: any;
    isLoading = false;
    markdown: any;
    noHelpTextMessage = 'Help text is not available';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SearchHelpDialogComponent>,
        private searchService: SearchService,
    ) {}

    ngOnInit() {
        this.getHelpText();
    }

    getHelpText() {
        this.isLoading = true;
        this.searchService
            .getHelpText(this.data.collectionId)
            .pipe(
                finalize(() => (this.isLoading = false)),
                tap((resp) => {
                    if (resp.data) {
                        this.markdown = resp.data;
                    } else {
                        this.markdown = this.noHelpTextMessage;
                    }
                }),
                catchError(() => (this.markdown = this.noHelpTextMessage)),
            )
            .subscribe();
    }

    close() {
        this.dialogRef.close();
    }
}
