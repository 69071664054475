import { computed, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    getChannelsList,
    loadChannelsUnreadInfo,
    selectActivatedChannelCollectionId,
    selectChannelsList,
    selectChannelsListIsEmpty,
    selectChannelsListLoadingState,
    selectNextChannelId,
    updateChannelUnreadStatus,
} from '@app/core/state/channels-list';
import { getFeatureFlags, selectCreditCardFeatureFlag, selectFeatureFlags } from '@app/core/state/feature-flags';
import {
    getThemingConfig,
    selectIsInitialThemeSet,
    selectSearchWith,
    selectThemingConfig,
} from '@app/core/state/theming';
import {
    getUserInfo,
    logoutUser,
    logoutUserSuccess,
    resetUserInfo,
    selectCurrentUserPreferences,
    selectIsAuthorized,
    selectSetUserSiloErrorMessage,
    selectSiloLogo,
    selectSiloName,
    selectSiloOrganisation,
    selectSiloPrivateName,
    selectSiloScoreWording,
    selectSiloScoreWordingChoises,
    selectTrialDays,
    selectUpdateUserSiloStatus,
    selectUserInfo,
    selectUserIsOrganisationAdmin,
    selectUserIsSiloAdmin,
    selectUserIsSuperUser,
    selectUserSilo,
    selectUserSilos,
    setUserSilo,
    switchUserSilo,
    updateUserDetailsSuccess,
    updateUserSilo,
    updateUsersPreferences,
} from '@app/core/state/user';
import { Silo, UpdatedUserDetails, UserPreferences } from '@app/data/models';
import { map, withLatestFrom } from 'rxjs/operators';
import { SEARCH_PROVIDER_DESCRIPTIONS } from '../constants/collectionProviders';
import { selectQueryParams, selectUrl } from '../state/router';

@Injectable({ providedIn: 'root' })
export class AppStateService {
    currentSilo$ = this.store.select(selectUserSilo);
    logo$ = this.store.select(selectSiloLogo).pipe(
        withLatestFrom(this.store.select(selectThemingConfig)),
        map(([siloLogo, themeConfig]) => (siloLogo !== null ? siloLogo : themeConfig?.defaultSiloLogoPath)),
    );
    siloName$ = this.store.select(selectSiloName);
    $siloPrivateName = this.store.selectSignal(selectSiloPrivateName);
    siloOrganisation = this.store.selectSignal(selectSiloOrganisation);
    userSilos$ = this.store.select(selectUserSilos);
    themeLoaded$ = this.store.select(selectIsInitialThemeSet);
    themingConfig$ = this.store.select(selectThemingConfig);
    searchWith$ = this.store.select(selectSearchWith);
    userSiloSetErrorMessage$ = this.store.select(selectSetUserSiloErrorMessage);
    userInfo$ = this.store.select(selectUserInfo);
    userInfo = this.store.selectSignal(selectUserInfo);
    isAuthorized$ = this.store.select(selectIsAuthorized);
    flags$ = this.store.select(selectFeatureFlags);
    selectOrganisationManagementEnabled$ = this.userInfo$.pipe(
        map(
            (userInfo) =>
                !userInfo?.organisation ||
                (userInfo?.organisation &&
                    (userInfo.user.is_organisation_admin ||
                        userInfo.user.is_organisation_user ||
                        userInfo.user.is_superuser)),
        ),
    );
    creditCardEnabled$ = this.store.select(selectCreditCardFeatureFlag);
    creditCardEnabled = this.store.selectSignal(selectCreditCardFeatureFlag);
    updateUserSiloStatus$ = this.store.select(selectUpdateUserSiloStatus);
    channelsList$ = this.store.select(selectChannelsList);
    channelsListLoadingState$ = this.store.select(selectChannelsListLoadingState);
    channelsListIsEmpty$ = this.store.select(selectChannelsListIsEmpty);
    userIsSuperUser$ = this.store.select(selectUserIsSuperUser);
    userIsSuperUser = this.store.selectSignal(selectUserIsSuperUser);
    userIsSiloAdmin$ = this.store.select(selectUserIsSiloAdmin);
    userIsSiloAdmin = this.store.selectSignal(selectUserIsSiloAdmin);
    userIsAdminOrSuperUser = computed(() => this.userIsSiloAdmin() || this.userIsSuperUser());
    userIsOrganisationAdmin$ = this.store.select(selectUserIsOrganisationAdmin);
    userIsOrganisationAdmin = this.store.selectSignal(selectUserIsOrganisationAdmin);
    userIsOrganisationAdminOrSuperUser = computed(() => this.userIsOrganisationAdmin() || this.userIsSuperUser());
    activatedChannelCollectionId$ = this.store.select(selectActivatedChannelCollectionId);
    trialDays$ = this.store.select(selectTrialDays);
    siloScoreWording$ = this.store.select(selectSiloScoreWording);
    siloScoreWordingChoises$ = this.store.select(selectSiloScoreWordingChoises);
    searchProvidersDescriptions$ = this.themingConfig$.pipe(
        map((themingConfig) => SEARCH_PROVIDER_DESCRIPTIONS(themingConfig.searchWith)),
    );
    currentUrl$ = this.store.select(selectUrl);
    currentUrl = this.store.selectSignal(selectUrl);
    $queryParams = this.store.selectSignal(selectQueryParams);
    userPreferences = this.store.selectSignal(selectCurrentUserPreferences);

    constructor(private store: Store) {}

    nextChannelId$ = (currentChannelId: string) => this.store.select(selectNextChannelId(currentChannelId));

    getThemingConfig() {
        this.store.dispatch(getThemingConfig());
    }

    getUserInfo() {
        this.store.dispatch(getUserInfo());
    }

    resetUserInfo() {
        this.store.dispatch(resetUserInfo());
    }

    switchUserSilo(siloId: number) {
        this.store.dispatch(switchUserSilo({ siloId }));
    }

    updateUserSilo(updatedSilo: Silo) {
        this.store.dispatch(updateUserSilo({ updatedSilo }));
    }

    setUserSilo(userSilo: Silo) {
        this.store.dispatch(setUserSilo({ userSilo }));
    }

    updateUserPreferences(preferences: UserPreferences) {
        this.store.dispatch(updateUsersPreferences({ preferences }));
    }

    getFeatureFlags() {
        this.store.dispatch(getFeatureFlags());
    }

    updatedUserDetails(updatedUserDetails: UpdatedUserDetails) {
        this.store.dispatch(updateUserDetailsSuccess({ updatedUserDetails }));
    }

    logoutUser() {
        this.store.dispatch(logoutUser());
    }

    logoutUserSuccess() {
        this.store.dispatch(logoutUserSuccess());
    }

    getChannelsList() {
        this.store.dispatch(getChannelsList());
    }

    getChannelsListUnreadStatus() {
        return this.store.dispatch(loadChannelsUnreadInfo());
    }

    updateChannelUnreadStatus(channelId: string, hasUnread: boolean) {
        this.store.dispatch(updateChannelUnreadStatus({ channelId, hasUnread }));
    }
}
