import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-bulk-operations-buttons',
    templateUrl: './bulk-operations-buttons.component.html',
    styleUrls: ['./bulk-operations-buttons.component.scss'],
    standalone: false,
})
export class BulkOperationsComponent {
    @Output() delete = new EventEmitter<void>();
    @Output() move = new EventEmitter<void>();
    @Output() toggleSelectMode = new EventEmitter<void>();
    @Output() toggleSortMode = new EventEmitter<void>();
    @Output() saveSort = new EventEmitter<void>();
    @Input() selectedItemsCount = 0;
    @Input() selectionMode: boolean;
    @Input() sortMode: boolean;

    onClickToggleSelectionMode() {
        this.toggleSelectMode.emit();
    }

    onClickToggleSortMode() {
        this.toggleSortMode.emit();
    }

    onClickMove() {
        this.move.emit();
    }

    onClickDelete() {
        this.delete.emit();
    }

    onClickSaveSort() {
        this.saveSort.emit();
    }
}
