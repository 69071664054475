<div class="label_container">
    <div [ngClass]="'post_type_label' + ' ' + post.type">
        <mat-icon class="size-auto"><i [ngClass]="matIconClassByType[post.type]"></i></mat-icon>
        <span>{{ postTypes[post.type] | uppercase }}</span>
        @if (post.data.score?.dominant_score != null) {
            <app-post-score-icon [postData]="post.data" class="post-type-label-score" />
        }
    </div>

    @if (post.data.source_icon) {
        <div>
            <img class="label_image" [src]="post.data.source_icon" />
        </div>
    }
</div>
