@if (scoreWording$ | async; as scoreWording) {
    <mat-button-toggle-group
        name="scoreFilter"
        matTooltip="{{ tooltipText$ | async }}"
        aria-label="Score Filter"
        [formControl]="scoreControl"
        [multiple]="multiple"
    >
        @for (score of scoreWording.levels; track score; let i = $index) {
            <mat-button-toggle [ngClass]="'score-' + i" [value]="i" (click)="onClick(i)" [disabled]="isDisabled">
                <mat-icon class="size-auto"><i class="fas fa-exclamation-triangle"></i></mat-icon>
                @if (withLabels) {
                    <span class="score-label">{{ score | titlecase }}</span>
                }
            </mat-button-toggle>
        }
    </mat-button-toggle-group>
}
