@switch (itemType) {
    @case ('searches') {
        <app-search-item
            [search]="item"
            [collections]="item.collections"
            [sizeClass]="size"
            [selected]="selected"
            [editable]="editable"
            [interactable]="interactable"
            [draggable]="draggable"
        ></app-search-item>
    }
    @case ('tags') {
        <app-tag-item
            [tag]="item"
            [sizeClass]="size"
            [selected]="selected"
            [locked]="locked"
            [editable]="editable"
            [interactable]="interactable"
            [draggable]="draggable"
        ></app-tag-item>
    }
}
