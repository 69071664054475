import { Injectable, inject } from '@angular/core';
import { AIApiService } from '@app/data/services/ai-api.service';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { switchMap, tap } from 'rxjs/operators';

export enum Statuses {
    initial = 'INITIAL',
    loading = 'LOADING',
    loaded = 'LOADED',
    failed = 'FAILED',
}
export type ViewModel = {
    errorMessage: string;
    saveScoreLoading: boolean;
    saveScoreLoaded: boolean;
};
export interface PostEditAIScoreState {
    saveScoreStatus: Statuses;
    errorMessage: string;
}
const PostEditAIScoreDefaultState = { saveScoreStatus: Statuses.initial, errorMessage: '' };

@Injectable()
export class PostEditAIScoreStore extends ComponentStore<PostEditAIScoreState> {
    //Selectors
    readonly saveScoreLoading$ = this.select((state) => state.saveScoreStatus === Statuses.loading);
    readonly saveScoreLoaded$ = this.select((state) => state.saveScoreStatus === Statuses.loaded);
    readonly vm$ = this.select(
        this.state$,
        this.saveScoreLoading$,
        this.saveScoreLoaded$,
        (state, saveScoreLoading, saveScoreLoaded) => ({
            errorMessage: state.errorMessage,
            saveScoreLoading,
            saveScoreLoaded,
        }),
    );

    //Updaters
    readonly resetErrorMessage = this.updater((state) => ({ ...state, errorMessage: '' }));

    //Effects
    readonly saveScore = this.effect<{ postId: string; aiScore: Record<string, string> }>((scoreParams$) =>
        scoreParams$.pipe(
            tap(() => {
                this.patchState({ saveScoreStatus: Statuses.loading });
                this.resetErrorMessage();
            }),
            switchMap((scoreParams) =>
                this.aiApiService.updatePostAIScore(scoreParams).pipe(
                    tapResponse({
                        next: (resp: any) => {
                            if (resp.status === 'fail') {
                                if (resp.message) this.patchState({ errorMessage: resp.message });
                                this.patchState({ saveScoreStatus: Statuses.initial });
                            } else {
                                this.patchState({ saveScoreStatus: Statuses.loaded });
                            }
                        },
                        error: (err: { error: any }) => {
                            if (err.error?.message) this.patchState({ errorMessage: err.error?.message });
                            this.patchState({ saveScoreStatus: Statuses.initial });
                        },
                    }),
                ),
            ),
        ),
    );

    //Initialization
    private aiApiService = inject(AIApiService);
    constructor() {
        super(PostEditAIScoreDefaultState);
    }
}
