<div class="post-details">
    <div class="post_brief_actions row-content">
        <div class="dates_actions">
            @if (post.data.is_manual) {
                <div class="info">
                    <span class="label">Added manually</span>
                </div>
            }
            @if (post.data?.breach?.name) {
                <div class="info">
                    <span class="label">Breach name: </span>
                    <span>{{ post.data.breach.name }}</span>
                </div>
            }
            @if (post.data?.password) {
                <div class="info">
                    <span class="label">Password: </span>
                    <span>{{ post.data.password }}</span>
                </div>
            }
            @if (post.data?.username) {
                <div class="info">
                    <span class="label">Username: </span>
                    <span>{{ post.data.username }}</span>
                </div>
            }

            <div>
                @if (post.data?.breach?.breach_date) {
                    <div class="date info">
                        <span class="label">Breach date: </span>
                        <span
                            >{{ dateService.getDateDiff('' + post.data.breach.breach_date) }} &nbsp; / &nbsp;
                            {{
                                dateService.setDateISO8601(post.data.breach.breach_date)
                                    | date: dateService.getDateFormat()
                            }}</span
                        >
                    </div>
                }
            </div>
            @if (post.data?.indexed_at) {
                <div class="info">
                    <span class="label">Index date: </span>
                    <span>{{
                        dateService.setDateISO8601(post.data.indexed_at) | date: dateService.getDateFormat()
                    }}</span>
                </div>
            }
            @if (post.data.filename) {
                <div class="info">
                    <span class="label">Filename: </span>
                    <span>{{ post.data.filename }}</span>
                </div>
            }
            @if (post.data?.breach?.indexed_data) {
                <div class="info">
                    <span class="label">Indexed data: </span>
                    <span>{{ post.data.breach.indexed_data.join(', ') }}</span>
                </div>
            }
            @if (post.data?.breach?.other_data?.length > 0) {
                <div class="info">
                    <span class="label">Also compromised: </span>
                    <span>{{ post.data.breach.other_data }}</span>
                </div>
            }
            @if (post.data?.hashed_password) {
                <div class="info">
                    <span class="label">Hashed password: </span>
                    <span>{{ post.data.hashed_password }}</span>
                </div>
            }
            @if (post.data?.name) {
                <div class="info">
                    <span class="label">Name: </span>
                    <span>{{ post.data.name }}</span>
                </div>
            }
            @if (post.data?.address) {
                <div class="info">
                    <span class="label">Address: </span>
                    <span>{{ post.data.address }}</span>
                </div>
            }
            @if (post.data?.ip_address) {
                <div class="info">
                    <span class="label">IP address: </span>
                    <span>{{ post.data.ip_address }}</span>
                </div>
            }
            @if (post.data?.phone) {
                <div class="info">
                    <span class="label">Phone: </span>
                    <span>{{ post.data.phone }}</span>
                </div>
            }
        </div>
    </div>
</div>
