import { Component, OnInit, Input } from '@angular/core';
import { Post } from '@app/data/models';

@Component({
    selector: 'app-add-article',
    templateUrl: './add-article.component.html',
    styleUrls: ['./add-article.component.scss'],
    host: { class: 'styled-scrollbar' },
    standalone: false,
})
export class AddArticleComponent implements OnInit {
    @Input() dialogRef?: any;
    @Input() data?: { post: Post; articleType?: string; edit: boolean };

    post;
    failureMessage;
    selectedType = null;
    articleTypes = [
        {
            title: 'News',
            value: 'news',
            supported: true,
            'icon-class': 'fas fa-globe',
        },
        {
            title: 'Marketplace',
            value: 'marketplace',
            supported: true,
            'icon-class': 'fas fa-shopping-cart',
        },
        {
            title: 'Social Media',
            value: 'social_media',
            supported: true,
            'icon-class': 'fad fa-users',
        },
        {
            title: 'Forum',
            value: 'forum',
            supported: true,
            'icon-class': 'fas fa-comments-alt',
        },
        {
            title: 'Whois',
            value: 'whois',
            supported: true,
            'icon-class': 'fas fa-cloud',
        },
        {
            title: 'Credit card',
            value: 'creditcard',
            supported: true,
            'icon-class': 'fas fa-credit-card',
        },
    ];

    ngOnInit() {
        if (this.data) {
            this.post = this.data.post;
            const postType = this.data.articleType || this.post?.type;
            if (postType) {
                this.selectedType = this.articleTypes.find((ar) => ar.value === postType);
            }
        }
    }
    selectArticleType(type) {
        this.selectedType = type;
    }

    close(postId: string) {
        this.selectedType = null;
        this.dialogRef?.close(postId);
    }
}
