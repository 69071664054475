<div class="header-content-wrapper">
    @switch (post.type) {
        @case (postTypes.creditcard) {
            <app-credit-card-header [post]="post" [curatorMode]="!readerMode" [channel]="channel">
            </app-credit-card-header>
        }
        @case (postTypes.databreach) {
            <app-breach-header [post]="post" [channel]="channel" [curatorMode]="!readerMode"> </app-breach-header>
        }
        @case (postTypes.leaks) {
            <app-leaks-header [post]="post" [channel]="channel" [curatorMode]="!readerMode"> </app-leaks-header>
        }
        @case (postTypes.darkweb) {
            <app-darkweb-header
                [post]="post"
                [channel]="channel"
                [curatorMode]="!readerMode"
                [isActiveSearch]="isActiveSearch"
                [hasAdminRights]="hasAdminRights"
            >
            </app-darkweb-header>
        }
        @case (postTypes.exposedServices) {
            <app-shodan-header [post]="post" [channel]="channel" [curatorMode]="!readerMode"> </app-shodan-header>
        }
        @case (postTypes.whois) {
            <app-whois-header [post]="post" [channel]="channel" [curatorMode]="!readerMode"> </app-whois-header>
        }
        @default {
            <app-forum-header
                [post]="post"
                [channel]="channel"
                [curatorMode]="!readerMode"
                [isActiveSearch]="isActiveSearch"
                [hasAdminRights]="hasAdminRights"
            ></app-forum-header>
        }
    }
</div>
<div class="fast_actions">
    @if (!readerMode && !post.isExpanded && enabledChannelDistribution()) {
        <app-include-post
            [post]="post"
            [isSelected]="post.data.is_selected"
            (includeInNewsletterEvent)="onIncludeInNewsletter($event)"
            (includeInNewsletterRangeEvent)="onIncludeInNewsletterRange($event)"
        ></app-include-post>
    }
    <div class="go_source action_icons">
        @if (post.data?.url != null && !post.isExpanded) {
            <a [href]="post.data?.url" target="_blank" container="body" placement="left" matTooltip="Go to source">
                <mat-icon><i class="far fa-link"></i></mat-icon>
            </a>
        }
    </div>
    @if (isActiveSearch && hasAdminRights && !post.isExpanded) {
        <div class="add_post action_icons" (click)="$event.preventDefault(); onAddPost(post)">
            <a target="_blank" container="body" placement="left" matTooltip="Add post"
                ><mat-icon class="add-post-icon"><i class="fak fa-add-playlist"></i></mat-icon
            ></a>
        </div>
    }
</div>
