import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, finalize } from 'rxjs/operators';

import { ConfirmationDialogComponent } from '@app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ChannelService } from '@app/data/services/channel.service';
import { AppStateService } from '@app/core/services/app-state.service';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';

@Component({
    selector: 'app-tags-form',
    templateUrl: './tag-category-form.component.html',
    styleUrls: ['./tag-category-form.component.scss'],
    standalone: false,
})
export class TagCategoryFormComponent implements OnInit {
    assignedTags = [];
    errorMessage: any;
    form: FormGroup;
    isDeleting = false;
    isLoading = true;
    isSubmitting = false;

    private untilDestroy = untilDestroyed();

    constructor(
        private channelService: ChannelService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        public el: ElementRef,
        private matDialogRef: MatDialogRef<TagCategoryFormComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: { tagCategory: any },
        private appStateService: AppStateService,
    ) {}

    ngOnInit() {
        this.createForm();

        if (this.tagCategory) {
            this.setFormValues();
        } else {
            this.isLoading = false;
        }
    }

    createForm() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
        });

        this.form.valueChanges.pipe(this.untilDestroy()).subscribe(() => this.onFormValuesChanged());
    }

    onFormValuesChanged() {}

    setFormValues() {
        this.form.reset({
            name: this.tagCategory.name,
        });

        this.isLoading = false;
    }

    onSubmit() {
        this.isSubmitting = true;
        const saveData = this.prepareSaveData();
        this.isSubmitting = true;

        if (this.tagCategory) {
            this.updateTagCategory(saveData);
        } else {
            this.createTagCategory(saveData);
        }
    }

    createTagCategory(saveData) {
        this.channelService
            .createTagCategory(saveData)
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe((data) => {
                this.matDialogRef.close(data);
            });
    }

    updateTagCategory(saveData) {
        this.channelService
            .updateTagCategory(this.tagCategory.id, saveData)
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe((data) => {
                this.matDialogRef.close(data);
            });
    }

    close() {
        this.matDialogRef.close();
    }

    prepareSaveData() {
        const formData = this.form.value;

        return {
            name: formData.name,
            tag_ids: [],
        };
    }

    deleteTagCategory() {
        this.dialog
            .open(ConfirmationDialogComponent, {
                panelClass: 'modal-sm',
                autoFocus: false,
                restoreFocus: false,
                data: {
                    header: 'Delete category',
                    message: 'Are you sure you would like to delete this category?',
                    confirm: 'Delete',
                },
            })
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe(() => {
                this.isDeleting = true;
                this.channelService
                    .deleteTagCategory(this.tagCategory.id)
                    .pipe(finalize(() => (this.isDeleting = false)))
                    .subscribe((data) => {
                        this.appStateService.getChannelsList();

                        this.matDialogRef.close(data);
                    });
            });
    }

    get tagCategory() {
        return this.data.tagCategory;
    }
}
