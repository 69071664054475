import { Component, OnInit, OnDestroy, Inject, HostBinding } from '@angular/core';

import { DateService } from '@app/core/services';
import { ChannelService } from '@app/data/services/channel.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-reader-modal',
    templateUrl: './reader-modal.component.html',
    styleUrls: ['./reader-modal.component.scss'],
    providers: [DateService, ChannelService],
    host: { class: 'styled-scrollbar' },
    standalone: false,
})
export class ReaderModalComponent implements OnInit, OnDestroy {
    @HostBinding('class') className = 'styled-scrollbar';
    errorMessage: string;
    selectedPostReader;
    originalPostReader;
    noPostDetails = false;
    postComments: any[];
    isImagePreviewVsible = false;
    tempPostComments: any[];
    postHistory;
    isOriginalPost = true;
    commentsFeedCount = 0;
    commentOffset = 0;
    commentsCount = 4;
    activeLoadingComments = false;
    endOfComments = false;
    noComments = false;
    imageIndex = 0;

    private ESCAPE_KEYCODE = 27;

    constructor(
        public dateService: DateService,
        private channelService: ChannelService,
        private modalRef: MatDialogRef<ReaderModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { postID: string },
    ) {}

    ngOnInit() {
        this.getPostInfo(this.postID);
        const searcFormModal = document.getElementsByClassName('search_form_modal');

        if (!searcFormModal.length) {
            document.addEventListener('keydown', this.handlerEsc, true);
        }
    }

    changeProductImageIncrease(imageIndex, imageLength) {
        if (this.imageIndex >= imageLength - 1) {
            this.imageIndex = 0;
        } else {
            this.imageIndex = this.imageIndex + 1;
        }
    }
    changeProductImageDecrese(imageIndex, imageLength) {
        if (this.imageIndex <= 0) {
            this.imageIndex = imageLength - 1;
        } else {
            this.imageIndex--;
        }
    }

    getPostInfo(postId: string) {
        // this is the main function that calls the feed services
        this.channelService
            .getPostInfo(postId)
            .pipe(
                tap((data) => {
                    this.selectedPostReader = data;

                    this.originalPostReader = data;

                    this.noPostDetails = !!(
                        Object.keys(this.selectedPostReader).length < 2 &&
                        (this.selectedPostReader.data === undefined || this.selectedPostReader.data == null)
                    );
                }),
                catchError((error) => {
                    this.noPostDetails = true;
                    this.errorMessage = <any>error;
                    return of(error);
                }),
            )
            .subscribe();
    }

    showOriginal() {
        this.isOriginalPost = true;
        this.selectedPostReader = this.originalPostReader;
    }

    handlerEsc = (event) => {
        if (event.keyCode === this.ESCAPE_KEYCODE && !this.isImagePreviewVsible) {
            event.stopPropagation();
            this.modalRef.close();
        }
    };

    openImagePreview(state) {
        this.isImagePreviewVsible = state;
    }

    get isAnalystCommentVisible() {
        const isVIsibleForPostType =
            this.selectedPostReader.type == 'forum' ||
            this.selectedPostReader.type == 'social_media' ||
            this.selectedPostReader.type == 'news' ||
            this.selectedPostReader.type == 'datadump' ||
            this.selectedPostReader.type == 'marketplace';

        return this.selectedPostReader.data?.analyst_comment && isVIsibleForPostType;
    }

    get postID() {
        return this.data.postID;
    }

    ngOnDestroy() {
        document.removeEventListener('keydown', this.handlerEsc, true);
    }
}
