import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[msDigitsOnly]',
    standalone: false,
})
export class MSDigitsOnlyDirective {
    @Input() invalidChars = ['-', '+', 'e', 'E', '.'];

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    }
}
