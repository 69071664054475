import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    constructor(private apiService: ApiService) {}

    addMetadata(postId: string, data) {
        return this.apiService.post('/post/' + postId + '/metadata', data, { withCredentials: true });
    }

    updateMetadata(postId: string, data, metadataId: string) {
        if (metadataId == null) {
            // Alternate to using a feature flag as it's not working here
            return this.apiService.post('/post/' + postId + '/metadata', data, { withCredentials: true });
        } else {
            return this.apiService.put('/post/' + postId + '/metadata/' + metadataId, data, { withCredentials: true });
        }
    }

    deleteMetadata(postId: string, metadataId: string) {
        if (metadataId == null) {
            // Alternate to using a feature flag as it's not working here
            return this.apiService.delete('/post/' + postId + '/metadata', { withCredentials: true });
        } else {
            return this.apiService.delete('/post/' + postId + '/metadata/' + metadataId, { withCredentials: true });
        }
    }
}
