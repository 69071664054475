export enum PostTypes {
    creditcard = 'creditcard',
    databreach = 'breach',
    exposedServices = 'exposed_services',
    whois = 'whois',
    forum = 'forum',
    news = 'news',
    marketplace = 'marketplace',
    socialMedia = 'social_media',
    darkweb = 'darkweb',
    leaks = 'stealers_leaks',
}
