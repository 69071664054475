import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-newsletter-distribution-dialog',
    templateUrl: './newsletter-distribution-dialog.component.html',
    styleUrls: ['./newsletter-distribution-dialog.component.scss'],
    standalone: false,
})
export class NewsletterDistributionDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {}
}
