import { Component, Input } from '@angular/core';
import { MatIconClassByType } from '@app/core/constants/matIconClassByType';

@Component({
    selector: 'app-post-type-image',
    templateUrl: './post-type-image.component.html',
    styleUrls: ['./post-type-image.component.scss'],
    standalone: false,
})
export class PostTypeImageComponent {
    @Input() post;

    matIconClassByType = MatIconClassByType;
}
