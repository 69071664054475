import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-spinner-wrapper',
    templateUrl: './spinner-wrapper.component.html',
    styleUrls: ['./spinner-wrapper.component.scss'],
    standalone: false,
})
export class SpinnerWrapperComponent {
    @Input() strokeWidth = 3;
    @Input() diameter = 36;
    @Input() color!: string;
}
