<div class="product_image">
    @for (item of data.images; track item; let i = $index) {
        <img class="hidden" [src]="data.images[i]" (error)="onImgError($event, item)" />
    }
    @if (data.images[imageIndex]) {
        <img class="post_image" [src]="data.images[imageIndex]" [alt]="data.title" />
    }
    @if (data.images && data.images.length > 1) {
        <div class="image_slider_arrows">
            <a (click)="changeProductImageDecrese(imageIndex, data.images.length)"
                ><mat-icon><i class="far fa-angle-left"></i></mat-icon
            ></a>
            <a
                ><span class="product_image_total_images"> {{ imageIndex + 1 + ' / ' + data.images.length }}</span></a
            >
            <a (click)="changeProductImageIncrease(imageIndex, data.images.length)"
                ><mat-icon><i class="far fa-angle-right"></i></mat-icon
            ></a>
        </div>
    }
</div>
