import { createAction, props } from '@ngrx/store';

import { ThemingConfig } from '@app/data/models';

export enum ThemingActionTypes {
    GetThemingConfig = '[Theming] Get theming config',
    GetThemingConfigSuccess = '[Theming] Get theming config success',

    SetThemeName = '[Theming] Set theme name',
}

export const getThemingConfig = createAction(ThemingActionTypes.GetThemingConfig);
export const getThemingConfigSuccess = createAction(
    ThemingActionTypes.GetThemingConfigSuccess,
    props<{ themingConfig: ThemingConfig }>(),
);

export const setThemeName = createAction(ThemingActionTypes.SetThemeName, props<{ themeName: string }>());
