import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    standalone: false,
})
export class AlertComponent {
    @Output() closed = new EventEmitter<void>();
    @Input() type: 'success' | 'error' | 'warning' = 'error';

    onClose() {
        this.closed.emit();
    }
}
