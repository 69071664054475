export type PromptVariableForSave = { value: string; key: string };

export interface AiTemplateConfig {
    staticText: {
        title: string;
        actionDescription: string;
        submitButton: string;
        confirmation: string;
    };
    submitTrackingName: string;
    maxPosts?: number;
    creditRate?: number;
}

export enum ModalType {
    SCORE = 'score',
    REPORT = 'report',
}

export interface AIReportType {
    id: number;
    name: string;
    type: string;
    max_posts?: number;
    credit_rate?: number;
}

// Constants for default values
export const DEFAULT_MAX_POSTS = 20;
export const DEFAULT_CREDIT_RATE = 1;
