import { Component, Input, OnInit } from '@angular/core';
import { ErrorScrollService } from '@app/core/services/errorScroll.service';

@Component({
    selector: 'app-submit-button-tooltip',
    templateUrl: './submit-button-tooltip.component.html',
    styleUrls: ['./submit-button-tooltip.component.scss'],
    providers: [ErrorScrollService],
    standalone: false,
})
export class SubmitButtonTooltipComponent implements OnInit {
    @Input() class;
    @Input() type;
    @Input() form;
    @Input() isSubmitting;
    @Input() buttonText;
    @Input() nativeElement;
    @Input() dataTestid: string;

    constructor(public errorScrollService: ErrorScrollService) {}

    ngOnInit(): void {}
}
