import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserData } from '@app/modules/manage-users/edit-user/edit-user.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '@app/data/services/user.service';
import { debounce, distinctUntilChanged, skip, tap, withLatestFrom } from 'rxjs/operators';
import { UserInfo, UsersResponce } from '@app/data/models';
import { AppStateService } from '@app/core/services/app-state.service';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';
import { Subject, race, timer } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-users-grid',
    templateUrl: './users-grid.component.html',
    styleUrls: ['./users-grid.component.scss'],
    standalone: false,
})
export class UsersGridComponent implements OnInit {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    @Input() displayedColumns;
    @Input() disableMe;
    @Input() permissions;
    @Input() showChannelSubscriptionStatus = false;
    @Input() channelId;
    @Input() actionNotDisabled;
    @Input() addedUsers;
    @Input() adminUsers: number[];
    @Input() excludeCurrentUser;
    @Input() activeUsersOnly = false;
    @Input() actionName = 'Subscribe';

    @Output() selectRow = new EventEmitter();
    @Output() addUser = new EventEmitter();
    @Output() removeUser = new EventEmitter();
    @Output() roleChange = new EventEmitter();
    @Output() setUsers = new EventEmitter();
    @Output() setIsAdmin = new EventEmitter<{ userId: number; isAdmin: boolean }>();

    dataSource: MatTableDataSource<UserData>;
    form: FormGroup;
    isSubmitting = false;
    pageSize = 5;
    start = 0;
    usersCount: number;
    length: number;
    gridLoading = true;
    defaultRole = 'reader';
    users;
    emitSearchOnEnter$ = new Subject<number>();

    get showOnlySubscribedToggle(): boolean {
        return this.showChannelSubscriptionStatus && this.channelId;
    }

    private untilDestroy = untilDestroyed();

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private appStateService: AppStateService,
    ) {}

    ngOnInit(): void {
        this.getUsers();
        this.createCommonForm();
        this.updateUsersOnSiloChange();
    }

    createCommonForm() {
        this.form = this.formBuilder.group({
            search: '',
            onlyActive: false,
            onlySubscribed: false,
        });

        this.form
            .get('search')
            .valueChanges.pipe(
                this.untilDestroy(),
                debounce(() => race(timer(1500), this.emitSearchOnEnter$)),
                distinctUntilChanged(),
                tap(() => this.changeGridParams()),
            )
            .subscribe();
    }

    changePage(event) {
        this.getUsers(event.pageIndex * event.pageSize, event.pageSize);
    }

    onClickEnterInSearch() {
        this.emitSearchOnEnter$.next(0);
    }

    getUsers(start = 0, pageSize = 5, changeCount?) {
        let params;

        params = {
            only_active: this.activeUsersOnly,
        };

        if (this.channelId) {
            params = {
                ...params,
                channel_id: this.channelId,
            };
        }

        if (this.form) {
            params = {
                ...params,
                query: this.form.value.search,
                only_active: this.activeUsersOnly || this.form.value.onlyActive,
            };

            if (this.showOnlySubscribedToggle) {
                params = {
                    ...params,
                    only_subscribed: this.form.value.onlySubscribed,
                };
            }
        }

        this.userService
            .getUsers(params, start, pageSize)
            .pipe(
                withLatestFrom(this.appStateService.userInfo$),
                tap(([users, userInfo]: [UsersResponce, UserInfo]) => {
                    this.users = users.users;

                    if (this.excludeCurrentUser) {
                        const currentUser = userInfo;
                        if (currentUser.user && currentUser.user?.id) {
                            this.users = this.users.filter((u) => u.id !== currentUser.user?.id);
                        }
                    }

                    if (this.displayedColumns.indexOf('role') !== -1 && this.permissions) {
                        this.permissions.forEach((permission) => {
                            const index = this.users.findIndex((user) => user.id == permission.user_id);

                            if (index != -1) {
                                this.users[index].role = permission.role;
                            }
                        });
                    }

                    if (this.adminUsers) {
                        this.users = this.users.map((u) => ({ ...u, is_admin: this.adminUsers.includes(u.id) }));
                    }

                    this.dataSource = new MatTableDataSource(this.users);

                    if (!this.usersCount) {
                        this.usersCount = users.count;
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                    }

                    if (changeCount) {
                        this.usersCount = users.count;
                    }

                    this.gridLoading = false;

                    this.setUsers.emit(this.users);
                }),
            )
            .subscribe();
    }

    changeGridParams() {
        this.gridLoading = true;
        this.paginator.pageIndex = 0;

        this.getUsers(this.paginator.pageIndex * this.paginator.pageSize, this.paginator.pageSize, true);
    }

    onSelect(row) {
        this.selectRow.emit(row);
    }

    toggleUser(user) {
        if (this.isAdded(user)) {
            this.onRemoveUser(user);
        } else {
            this.onAddUser(user);
        }
    }

    onAddUser(user) {
        if (this.showChannelSubscriptionStatus) {
            this.userService
                .subscribeToChannel(user.id, this.channelId)
                .pipe(
                    tap(() => {
                        this.users = this.users.map((u) => {
                            if (u.id === user.id) {
                                user.is_subscriber = true;
                            }

                            return u;
                        });
                    }),
                )
                .subscribe();
        } else {
            this.addUser.emit(user);
        }
    }

    onRemoveUser(user) {
        if (this.showChannelSubscriptionStatus) {
            this.userService
                .unsubscribeFromChannel(user.id, this.channelId)
                .pipe(
                    tap(() => {
                        this.users = this.users.map((u) => {
                            if (u.id === user.id) {
                                u.is_subscriber = false;
                            }

                            return u;
                        });
                    }),
                )
                .subscribe();
        } else {
            this.removeUser.emit(user);
        }
    }

    isAdded(row) {
        let isAdded = false;
        if (this.showChannelSubscriptionStatus) {
            isAdded = this.users.find((user) => row.id === user.id).is_subscriber;
        } else {
            if (this.permissions) {
                this.permissions.forEach((permission) => {
                    if (row.id == permission.user_id) {
                        isAdded = true;
                    }
                });
            } else if (this.addedUsers) {
                isAdded = this.addedUsers.find((user) => row.id === user);
            }
        }

        return isAdded;
    }

    onRoleChange(user) {
        this.roleChange.emit(user);
    }

    onToggleIsAdmin(event: MatCheckboxChange, user) {
        const isAdmin = event.checked;
        this.setIsAdmin.emit({ userId: user.id, isAdmin });
    }

    private updateUsersOnSiloChange() {
        this.appStateService.currentSilo$
            .pipe(
                this.untilDestroy(),
                skip(1),
                tap(() => {
                    this.getUsers();
                }),
            )
            .subscribe();
    }
}
