import { createAction, props } from '@ngrx/store';

import { UserInfo, Silo, UpdatedUserDetails, UsersPreferences, UserPreferences, SiloItem } from '@app/data/models';

export enum UserActionTypes {
    SetUserSilo = '[User] Set user silo',
    SetUserSiloSuccess = '[User] Set user silo success',
    SetUserSiloError = '[User] Set user silo error',
    SwitchUserSilo = '[User] Switch user silo',
    SwitchUserSiloSuccess = '[User] Switch user silo success',
    SwitchUserSiloCompleted = '[User] Switch user silo completed',
    GetUserInfo = '[User] Get user info',
    GetUserInfoSuccess = '[User] Get user info success',
    ResetUserInfo = '[User] Reset user info',
    UpdateUserSilo = '[User] Update user silo',
    UpdateUserSiloSuccess = '[User] Update user silo success',
    UpdateUserSiloError = '[User] Update user silo error',
    LogoutUser = '[User] Log out user',
    LogoutUserSuccess = '[User] Log out user success',
    UpdateUserDetailsSuccess = '[User] Update user details success',
    SetTrialDays = '[User] Set trial days',
    GetTrialDays = '[User] Get trial days',
    ResetTrialDays = '[User] Reset trial days',
    GetUserSilos = '[User] Get user silos',
    GetUserSilosSuccess = '[User] Get user silos success',
    UpdateUsersPreferences = '[User] Update user preferences',
    UpdateUsersPreferencesSuccess = '[User] Update user preferences success',
}

export const setUserSilo = createAction(UserActionTypes.SetUserSilo, props<{ userSilo: Silo }>());
export const setUserSiloSuccess = createAction(UserActionTypes.SetUserSiloSuccess, props<{ userSilo: Silo }>());
export const setUserSiloError = createAction(UserActionTypes.SetUserSiloError, props<{ errorMessage: string }>());

export const switchUserSilo = createAction(UserActionTypes.SwitchUserSilo, props<{ siloId: number }>());
export const switchUserSiloSuccess = createAction(UserActionTypes.SwitchUserSiloSuccess);
export const switchSiloCompleted = createAction(UserActionTypes.SwitchUserSiloCompleted);

export const getUserInfo = createAction(UserActionTypes.GetUserInfo);
export const getUserInfoSuccess = createAction(UserActionTypes.GetUserInfoSuccess, props<{ userInfo: UserInfo }>());
export const resetUserInfo = createAction(UserActionTypes.ResetUserInfo);

export const updateUserSilo = createAction(UserActionTypes.UpdateUserSilo, props<{ updatedSilo: Silo }>());
export const updateUserSiloSuccess = createAction(
    UserActionTypes.UpdateUserSiloSuccess,
    props<{ updatedSilo: Silo }>(),
);
export const updateUserSiloError = createAction(UserActionTypes.UpdateUserSiloError, props<{ error: unknown }>());

export const logoutUser = createAction(UserActionTypes.LogoutUser);
export const logoutUserSuccess = createAction(UserActionTypes.LogoutUserSuccess);

export const updateUserDetailsSuccess = createAction(
    UserActionTypes.UpdateUserDetailsSuccess,
    props<{ updatedUserDetails: UpdatedUserDetails }>(),
);

export const setTrialDays = createAction(UserActionTypes.SetTrialDays, props<{ trialDays: string }>());
export const getTrialDays = createAction(UserActionTypes.GetTrialDays);
export const resetTrialDays = createAction(UserActionTypes.ResetTrialDays);

export const getUserSilos = createAction(UserActionTypes.GetUserSilos);
export const getUserSilosSuccess = createAction(UserActionTypes.GetUserSilosSuccess, props<{ silos: SiloItem[] }>());

export const updateUsersPreferences = createAction(
    UserActionTypes.UpdateUsersPreferences,
    props<{ preferences: UserPreferences }>(),
);
export const updateUsersPreferencesSuccess = createAction(
    UserActionTypes.UpdateUsersPreferencesSuccess,
    props<{ preferences: UsersPreferences }>(),
);
