import { Component, inject, Input, OnInit } from '@angular/core';
import { AIEvaluationStore } from './ai-modals-template.store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PromptVariables } from '@app/data/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, skip } from 'rxjs/operators';
import { AiTemplateConfig } from '../ai-analytics.interfaces';
import { AppStateService } from '@app/core/services';

@Component({
    selector: 'app-ai-modals-template',
    templateUrl: './ai-modals-template.component.html',
    styleUrl: './ai-modals-template.component.scss',
    standalone: false,
})
export class AiModalsTemplateComponent implements OnInit {
    @Input({ required: true }) templateConfig: AiTemplateConfig;
    form: FormGroup;
    evaluationRequestLoaded$: Observable<boolean>;
    readonly appStateService = inject(AppStateService);
    readonly vm$ = inject(AIEvaluationStore).vm$;
    readonly trackingCategory = 'ai';

    private dialogData: { channelId: number } = inject(MAT_DIALOG_DATA);
    private aiEvaluationStore = inject(AIEvaluationStore);
    private formBuilder = inject(FormBuilder);

    numPosts: number;

    ngOnInit(): void {
        this.aiEvaluationStore.getPrompts(this.dialogData.channelId);
        this.aiEvaluationStore.promptVariables$
            .pipe(
                skip(1),
                filter((variables) => !!variables?.length),
            )
            .subscribe((variables) => this.setForm(variables));
        this.evaluationRequestLoaded$ = this.aiEvaluationStore.evaluationRequestLoaded$;
    }

    submitEvaluation() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        const variables = Object.entries(this.form?.value || {}).reduce(
            (acc, [k, v]) => (k !== 'num_posts' && v !== null ? { [k]: v, ...acc } : acc),
            {},
        );
        const requestParams = {
            channelId: this.dialogData.channelId,
            num_posts: this.form?.value.num_posts,
            variables: variables,
        };
        this.aiEvaluationStore.submitAIEvaluation(requestParams);
    }

    setForm(variables: PromptVariables[]) {
        if (variables?.length) {
            const formGroup = {
                num_posts: [
                    this.templateConfig.maxPosts,
                    [Validators.required, Validators.min(1), Validators.max(this.templateConfig.maxPosts)],
                ],
            };

            variables.forEach((variable) => {
                const hasPossibleValues = !!variable.possible_values?.length;
                let defaultValue = variable.default_value || hasPossibleValues ? variable.possible_values[0] : '';
                const validators = variable.default_value ? [] : [Validators.required, this.noWhitespaceValidator];

                if (variable.key === 'entity') {
                    defaultValue = this.appStateService.$siloPrivateName();
                }
                formGroup[variable.key] = [defaultValue, validators];
            });
            this.form = this.formBuilder.group(formGroup);
        }
    }

    resetErrorMessage() {
        this.aiEvaluationStore.resetErrorMessage();
    }

    private noWhitespaceValidator(control: FormControl<string>) {
        const value = control.value || '';
        return value.trim().length ? null : { whitespace: true };
    }
}
