<div
    class="search-item category-item"
    [ngClass]="[sizeClass, itemWrapperClass, scoreClass]"
    [class.active]="selected"
    [class.editable]="editable"
    [class.interactable]="interactable"
    data-testid="search-item"
    [class.draggable]="draggable"
    [class.stopped]="hasErrors()"
    [matTooltip]="getErrorTooltip()"
    [matTooltipDisabled]="!hasErrors()"
>
    <mat-icon><i [ngClass]="matIconClass"></i></mat-icon>
    {{ search.name }}
    @if (editable) {
        <mat-icon class="edit-icon"><i class="fas fa-pen"></i></mat-icon>
    }
</div>
