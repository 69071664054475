import { Component, Input, OnInit } from '@angular/core';
import { DateService } from '@app/core/services';

@Component({
    selector: 'app-credit-card-post',
    templateUrl: './credit-card-post.component.html',
    styleUrls: ['./credit-card-post.component.scss'],
    standalone: false,
})
export class CreditCardPostComponent implements OnInit {
    @Input() selectedPostReader;

    CARD_ICONS_IMG = {
        VISA: {
            'object-position': '-198px -7px',
            'object-fit': 'none',
            height: '34px',
            width: '64px',
        },
        PAYPAL: {
            'object-position': '-99px -7px',
            'object-fit': 'none',
            height: '34px',
            width: '64px',
        },
        WESTERNUNION: {
            'object-position': '-288px 0',
            'object-fit': 'none',
            height: '34px',
            width: '64px',
        },
        MASTERCARD: {
            'object-position': '-102px -68px',
            'object-fit': 'none',
            height: '34px',
            width: '64px',
        },
        MAESTRO: {
            'object-position': '-385px -67px',
            'object-fit': 'none',
            height: '34px',
            width: '64px',
        },
    };

    constructor(public _DateService: DateService) {}

    ngOnInit() {}

    getStyle(type) {
        return this.CARD_ICONS_IMG[type];
    }
}
