import { Component, Input, OnInit } from '@angular/core';
import { DateService } from '@app/core/services';

@Component({
    selector: 'app-credit-card-details',
    templateUrl: './credit-card-details.component.html',
    styleUrls: ['./credit-card-details.component.scss'],
    standalone: false,
})
export class CreditCardDetailsComponent implements OnInit {
    @Input() selectedPostReader;

    cardImg = '/assets/images/stopper.png';

    constructor(public dateService: DateService) {}

    ngOnInit() {}
}
