import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ModalsQueueService {
    private modalRefsQueue = [];

    addRefToQueue(ref) {
        this.modalRefsQueue.push(ref);
    }

    getModalRefsQueue() {
        return this.modalRefsQueue;
    }

    removeLastRefFromQueue() {
        return this.modalRefsQueue.pop();
    }
}
