<div class="post-details">
    <div class="post_brief_actions row-content">
        <div class="dates_actions">
            @if (leaksViewPost.data.is_manual) {
                <div class="info">
                    <span class="label">Added manually</span>
                </div>
            }
            <div>
                @if (leaksViewPost.data?.published_at; as publishDate) {
                    <div class="date info">
                        <span class="label">Posted: </span>
                        <span
                            >{{ dateService.getDateDiff('' + publishDate) }} &nbsp; / &nbsp;
                            {{ dateService.setDateISO8601(publishDate) | date: dateService.getDateFormat() }}</span
                        >
                    </div>
                }
            </div>
            @if (leaksViewPost.data?.indexed_at; as indexedDate) {
                <div class="info">
                    <span class="label">Indexed: </span>
                    <span class="value">{{
                        dateService.setDateISO8601(indexedDate) | date: dateService.getDateFormat()
                    }}</span>
                </div>
            }
            @if (leaksViewPost.data.bucket) {
                <div class="info">
                    <span class="label">Source: </span>
                    <span class="value">{{ leaksViewPost.data.bucket }}</span>
                </div>
            }
            @if (leaksViewPost.data.file_name) {
                <div class="info">
                    <span class="label">Filename: </span>
                    <span class="value">{{ leaksViewPost.data.file_name }}</span>
                </div>
            }

            @if (leaksViewPost.data.storage_id && leaksViewPost.data.bucket) {
                <div class="info download-link">
                    <span class="label" (click)="onDownloadFile()">
                        Download source file
                        <mat-icon class="size-auto"><i class="fal fa-download"></i></mat-icon>
                    </span>
                </div>
            }
        </div>
    </div>
</div>
