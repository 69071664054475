import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as fromThemingActions from './theming.actions';
import { AppConfigService } from '@app/core/services/app-config.service';
import { of } from 'rxjs';

@Injectable()
export class ThemingEffects {
    constructor(
        private actions$: Actions,
        private appConfigService: AppConfigService,
    ) {}

    getThemingConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromThemingActions.getThemingConfig),
            switchMap(() =>
                this.appConfigService.getThemingOption().pipe(
                    take(1),
                    map((themingOptions) => {
                        const themeName = themingOptions?.theme;
                        if (themeName && themeName !== 'default') {
                            return fromThemingActions.setThemeName({ themeName });
                        } else {
                            return fromThemingActions.setThemeName({ themeName: 'default' });
                        }
                    }),
                    catchError(() => {
                        return of(fromThemingActions.setThemeName({ themeName: 'default' }));
                    }),
                ),
            ),
        ),
    );

    setThemeName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromThemingActions.setThemeName),
            map((action) => action.themeName),
            switchMap((themeName) =>
                this.appConfigService.getAppThemingConfig(themeName).pipe(
                    map((themingConfig) => fromThemingActions.getThemingConfigSuccess({ themingConfig })),
                    catchError(() => {
                        return of(fromThemingActions.setThemeName({ themeName: 'default' }));
                    }),
                ),
            ),
        ),
    );

    getThemingConfigSucces$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromThemingActions.getThemingConfigSuccess),
                map((action) => action.themingConfig),
                filter((themingConfig) => !!themingConfig),
                tap((themingConfig) => {
                    this.appConfigService.onThemeConfigUpdate(themingConfig);
                }),
            ),
        { dispatch: false },
    );
}
