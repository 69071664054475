import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    charTypeValidator(gC: AbstractControl): ValidationErrors | null {
        const password = gC.value;
        if (!password) return null;

        const upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/,
            special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

        const complexity = [];
        if (password) {
            for (const ch of password) {
                if (lower.test(ch)) {
                    complexity['lower'] = true;
                }
                if (upper.test(ch)) {
                    complexity['upper'] = true;
                }
                if (number.test(ch) || special.test(ch)) {
                    complexity['special'] = true;
                }
            }
        }

        return complexity['lower'] && complexity['upper'] && complexity['special'] ? null : { charTypes: true };
    }

    matchPasswordValidator(gC: AbstractControl): ValidationErrors | null {
        const mismatchError = { mismatch: true };
        if (gC.get('newPassword')) {
            if (gC.get('newPassword').value !== gC.get('repeatPassword').value) {
                gC.get('repeatPassword').setErrors(mismatchError);
                return mismatchError;
            }
        } else if (gC.get('password')) {
            if (gC.get('password').value !== gC.get('password_confirm').value) {
                gC.get('password_confirm').setErrors(mismatchError);
                return mismatchError;
            } else {
                gC.get('password_confirm').setErrors(null);
            }
        }

        return null;
    }

    channelBasicValidator(channelFormControl: AbstractControl): ValidationErrors | null {
        const basicSettingsError = { basicSettingsInvalid: true };
        const basicSettings = channelFormControl.value;
        if (!basicSettings.name || !basicSettings.description) {
            return basicSettingsError;
        }
        if (basicSettings.private === false && !basicSettings.category_id) {
            return basicSettingsError;
        }
        return null;
    }

    senderNameValidator(charactersValidator): ValidatorFn {
        return (senderNameControl: AbstractControl): ValidationErrors | null => {
            if (!senderNameControl.value) return null;
            if (!charactersValidator(senderNameControl.value)) {
                return { invalidSenderNameCharacters: true };
            }
            return null;
        };
    }

    channelDistributionValidator(senderNameValidor): ValidatorFn {
        return (channelFormControl: AbstractControl): ValidationErrors | null => {
            const distributionSettingsError = { distributionSettingsInvalid: true };
            const distributionSettings = channelFormControl.value;
            const emptyFields = !distributionSettings.name || !distributionSettings.sender_name;
            const invalidFields =
                distributionSettings.name?.length > 48 ||
                distributionSettings.sender_name?.length > 85 ||
                !senderNameValidor(distributionSettings.sender_name);
            if (emptyFields || invalidFields) {
                return distributionSettingsError;
            }
            return null;
        };
    }

    searchFormSimpleValidator(searchFormControl: AbstractControl): ValidationErrors | null {
        const emptyCategoryError = { emptyCategoryError: true };
        const formValue = searchFormControl.value;
        if (!formValue.category && !formValue.newCategory) {
            searchFormControl.get('category').setErrors(emptyCategoryError);
            searchFormControl.get('newCategory').setErrors(emptyCategoryError);
            return emptyCategoryError;
        }

        searchFormControl.get('category').setErrors(null);
        searchFormControl.get('newCategory').setErrors(null);
        return null;
    }

    channelIntegrationValidator(channelIntegrationFormControl: AbstractControl): ValidationErrors | null {
        const integrationFormValue = channelIntegrationFormControl.value;
        let error: ValidationErrors | null = null;
        if (integrationFormValue?.id) {
            for (const key in integrationFormValue.data) {
                if (integrationFormValue.data[key] === '') {
                    error = { emptyIntegrationField: true };
                }
            }
        }
        return error;
    }

    handleServerErrors(error: any, form: FormGroup): string {
        const serverErrors = this.#getServerFieldErrors(error, form);
        if (serverErrors) {
            serverErrors.forEach(({ fieldName, error }) => {
                form.get(fieldName)?.setErrors({ serverError: error });
            });
            return '';
        } else {
            const errorString = typeof error.error === 'string' ? error.error : null;
            const predefinedErrorMsg = error.error?.non_field_errors || error.error?.detail || errorString;
            const errorMsg =
                predefinedErrorMsg ||
                'Oops! Something went wrong! \n Help us improve your experience by sending an error report';
            return errorMsg;
        }
    }
    F;

    #getServerFieldErrors(error: any, form: FormGroup): { fieldName: string; error: string }[] | null {
        const fieldNames = this.#getControlNames(form);
        const errors = [];
        fieldNames.reduce((acc, fieldName) => {
            const name = fieldName.split('.').at(-1);
            const fieldError = error.error?.[name] ? error.error?.[name][0] : null;
            if (fieldError) {
                acc.push({ fieldName, error: fieldError });
            }
            return acc;
        }, errors);
        return errors.length > 0 ? errors : null;
    }

    #getControlNames(formGroup: FormGroup): string[] {
        const controls = formGroup.controls;
        const controlNames: string[] = [];

        Object.keys(controls).forEach((key) => {
            const control = controls[key];
            if (control instanceof FormGroup) {
                // For nested groups, recursively get control names
                const nestedNames = this.#getControlNames(control);
                controlNames.push(...nestedNames.map((name) => `${key}.${name}`));
            } else {
                controlNames.push(key);
            }
        });

        return controlNames;
    }
}
