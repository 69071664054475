import { Component, inject, input, output, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { PostsFeedStore } from '@app/modules/feed/posts-feed/posts-feed.store';
import { AppStateService } from '@app/core/services';
import { UserPreferencesKeys } from '@app/data/models';

@Component({
    selector: 'app-include-post',
    imports: [CommonModule, MatCheckboxModule, MatMenuModule],
    templateUrl: './include-post.component.html',
    styleUrl: './include-post.component.scss',
})
export class IncludePostComponent {
    post = input<any>();
    isSelected = input<boolean>();
    includeInNewsletterEvent = output<any>();
    includeInNewsletterRangeEvent = output<any>();
    menuTrigger = viewChild.required(MatMenuTrigger);

    private postsFeedStore = inject(PostsFeedStore);
    private appStateService = inject(AppStateService);

    includeInNewsletter(event: MouseEvent) {
        event.stopPropagation();
        if (event.shiftKey) {
            this.includeInNewsletterRangeEvent.emit(this.post());
            this.onDoNotShowAgain();
        } else {
            this.includeInNewsletterEvent.emit(this.post());
            this.showHint();
        }
    }

    onDoNotShowAgain() {
        this.appStateService.updateUserPreferences({
            [UserPreferencesKeys.HideIncludeRangeHint]: true,
        });
    }

    private showHint() {
        const hintDisabled = this.appStateService.userPreferences()?.[UserPreferencesKeys.HideIncludeRangeHint];
        if (hintDisabled) return;
        if (this.postsFeedStore.lastIncludedPost() && !this.postsFeedStore.includeRangeHintShown()) {
            this.menuTrigger().openMenu();
            this.postsFeedStore.setIncludeRangeHintShown();
        }
    }
}
