import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService, ErrorHandlerService } from '@app/core/services';
import { catchError, map } from 'rxjs/operators';
import {
    AdvancedSearchOptionsResponseModel,
    CreateSearchPayload,
    DeleteSearchesRequest,
    Search,
    SearchPostsRequest,
    SearchPostsResponse,
    UpdateSearchCategoryRequest,
    UpdateSearchCategoryResponse,
    UpdateSearchesCategoryRequest,
    ValidateSearchRequest,
    ValidateSearchResponse,
} from '@app/data/models';
import { ContentProvider } from '../models/content-provider';
import { SearchCategory } from '../models/searchCategory.model';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private headers = new HttpHeaders().append('Content-Type', 'application/json; charset=UTF-8');

    constructor(
        private apiService: ApiService,
        private errorHandlerService: ErrorHandlerService,
    ) {}

    getAllSearches() {
        return this.apiService
            .get<SearchCategory[]>('/searches', { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    getSearchById(searchId: number) {
        return this.apiService
            .get<Search>(`/searches/${searchId}`, { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    getContentProviders() {
        return this.apiService
            .get<ContentProvider[]>('/content_providers', { headers: this.headers, withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    createSearch(newSearch: CreateSearchPayload): Observable<unknown> {
        return this.apiService
            .post('/searches', newSearch, { headers: this.headers, withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    getHelpText(collectionId: number) {
        return this.apiService
            .get<{
                data: string;
            }>(`/content_providers/collection/${collectionId}/help`, { headers: this.headers, withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    getAdvancedOptions(collectionId: number) {
        return this.apiService
            .get<AdvancedSearchOptionsResponseModel>(`/content_providers/collection/${collectionId}/search_options`, {
                headers: this.headers,
                withCredentials: true,
            })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    updateSearchById(searchId: number, data: CreateSearchPayload) {
        return this.apiService.put('/searches/' + searchId, data, { withCredentials: true });
    }

    deleteSearchById(searchId: number) {
        return this.apiService
            .delete(`/searches/${searchId}`, { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    updateSearchCategory(id: number, data: UpdateSearchCategoryRequest) {
        return this.apiService.put(`/searches/category/${id}`, data, { withCredentials: true });
    }

    updateSearchesCategory(data: UpdateSearchesCategoryRequest) {
        return this.apiService.put('/bulk/searches/', data, { withCredentials: true });
    }

    deleteSearches(data: DeleteSearchesRequest) {
        return this.apiService.delete('/bulk/searches/', { withCredentials: true, body: data });
    }

    createSearchCategory(data: UpdateSearchCategoryRequest) {
        return this.apiService
            .post<UpdateSearchCategoryResponse>('/searches/category', data, {
                headers: this.headers,
                withCredentials: true,
            })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    searchPosts(searchParams: SearchPostsRequest) {
        return this.apiService
            .post<SearchPostsResponse>('/search', searchParams, { headers: this.headers, withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    validate(searchParams: ValidateSearchRequest) {
        return this.apiService.post<ValidateSearchResponse>('/validate', searchParams, {
            headers: this.headers,
            withCredentials: true,
        });
    }

    deleteSearchCategory(id: string) {
        return this.apiService
            .delete(`/searches/category/${id}`, { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    setSearchesOrder(searchIds: number[]) {
        const requestData = searchIds.map((id) => ({ id }));
        return this.apiService
            .put('/searches/order', requestData, { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }

    setSearchesCategoriesOrder(searchCategoryIds: number[]) {
        const requestData = searchCategoryIds.map((id) => ({ id }));
        return this.apiService
            .put('/searches/category/order', requestData, { withCredentials: true })
            .pipe(catchError((error) => this.errorHandlerService.getErrorMessage(error)));
    }
}
