import { ThemingConfig } from '@app/data/models';

export const defaultThemingConfig: ThemingConfig = {
    themeClassName: 'default',
    loginScreenTitle: 'MarketScape&reg',
    pageTitle: 'MarketScape',
    searchWith: 'SAGA&reg',
    customFaviconPath: '/assets/images/favicon.ico',
    defaultSiloLogoPath: '/assets/images/company_logo.png',
};
