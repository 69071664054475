/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-reader-side-menu',
    templateUrl: './reader-side-menu.component.html',
    styleUrls: ['./reader-side-menu.component.scss'],
    standalone: false,
})
export class ReaderSideMenuComponent implements OnInit, AfterViewInit {
    @Input() selectedPostReader;
    @ViewChild('locationMap') private locationMap;

    currentZoom = 3;
    currentLocation;

    ngOnInit() {
        // meta could be returned as null (saga-api) or [] (ms4)
        const location =
            this.selectedPostReader.data.meta &&
            this.selectedPostReader.data.meta.find((meta) => meta.type === 'location');

        if (location) {
            this.currentZoom = parseFloat(location.data.zoom);
            this.currentLocation = {
                lat: parseFloat(location.data.location.lat),
                lng: parseFloat(location.data.location.lng),
            };
        }
    }

    ngAfterViewInit() {
        if (this.currentLocation) {
            this.initMap();
        }
    }

    initMap() {
        const postLatLng = this.currentLocation;

        const map = new google.maps.Map(this.locationMap.nativeElement, {
            zoom: this.currentZoom,
            center: postLatLng,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
        });

        if (this.currentLocation.lat) {
            new google.maps.Marker({
                position: postLatLng,
                map: map,
                title: 'Post location',
            });
        }
    }
}
