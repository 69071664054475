<div class="header">
    <div class="header_details">
        <div class="title">
            {{ edit ? 'Edit' : 'Add' }} <b>{{ articleConfig.title }}</b> post
        </div>
    </div>
</div>

<div class="modal-body">
    <app-article-form
        [type]="postType"
        [edit]="edit"
        [fields]="typeFields"
        [post]="post"
        [showTags]="!edit"
        [showLinks]="false"
        [isSubmitting]="isSubmitting"
        (formSubmit)="onSubmit($event)"
        (formClose)="onClose()"
        [formErrors]="formErrors"
    ></app-article-form>
</div>
