import { TagItem } from './tagItem.model';

export interface Channel {
    auto_selection: boolean;
    basic: ChannelBasic;
    count?: number;
    count_manual_selection?: number;
    distribution: ChannelIDistribution;
    id: string | number;
    is_owner?: boolean;
    newsletter: ChannelNewsletter;
    note: string;
    subscribers_count?: number;
    tag_ids: any[];
    search_ids: any[];
    tags: TagItem[];
    subscribed?: boolean;
    description?: string;
    name?: string;
    created_by?: string;
    type?: string;
    has_unread?: boolean;
    last_read_at?: string;
    total_count?: number;
    unread_count?: number;
    score?: number;
    integration: null | { id: number; data: { key: string }[] };
    crawl_errors?: CrawlError[];
}

export interface CrawlError {
    search_id: number;
    search_name: string;
    status: CrawlErrorStatus;
    error_message: string;
}

export type CrawlErrorStatus = 'lack_of_credits' | 'internal_error' | 'external_error';

export interface ChannelUnreadInfo {
    id: string | number;
    name?: string;
    has_unread?: boolean;
    last_read_at?: string;
    total_count?: number;
    unread_count?: number;
    score?: number;
}

export interface CollectionChannel {
    id: string;
    name: string;
    is_owner: boolean;
    subscribed: boolean;
    subscribers_count: number;
    total_count: number;
    type: string;
    has_unread?: boolean;
    last_read_at?: string;
    unread_count?: number;
    score?: number;
    has_crawl_errors?: boolean;
}

export interface ChannelIntegration {
    name: string;
    description: string;
    logo?: string;
    id: number;
    expected_data: {
        key_name: string;
        data_type: IntegrationFieldTypes;
    }[];
}

export enum IntegrationFieldTypes {
    string = 'str',
    list = 'list',
    integer = 'int',
}

interface ChannelBasic {
    category_id: number;
    collection_id?: string | number;
    description: string;
    name: string;
    private: boolean;
}

interface ChannelIDistribution {
    day_of_month?: string;
    day_of_week?: string;
    duplication_filter?: number;
    distribution_filter?: number;
    enabled?: boolean;
    frequency?: string;
    name: string;
    sender_email?: string;
    sender_name: string;
    sort?: string;
    group_by_search_category_or_tag_category: boolean;
    group_by_search_or_tag: boolean;
    show_only_subscribed_tags_searches: boolean;
    time?: string;
}

interface ChannelNewsletter {
    custom_css: string;
    hidden_elements: string[];
    send_as_attachment: boolean;
    templates?: any;
    template_id?: string | number;
}
