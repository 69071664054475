import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PostService {
    isAutoSelectionDisabled(channel) {
        return channel && !channel['auto_selection'];
    }
}
