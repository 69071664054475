import { createReducer, on } from '@ngrx/store';

import * as FeatureFlagsActions from './feature-flags.actions';
import { FeatureFlags, FeatureFlagsResponse } from '@app/data/models';

export interface FeatureFlagsState {
    featureFlags?: FeatureFlags;
}

const initialFeatureFlagsState: FeatureFlagsState = {};

function featureFlagsToBoolean(featureFlags: FeatureFlagsResponse): FeatureFlags {
    const featureFlagsWithBoolean = {};
    for (const flag in featureFlags) {
        featureFlagsWithBoolean[flag] = featureFlags[flag] === true || featureFlags[flag] === 'yes';
    }
    return featureFlagsWithBoolean as FeatureFlags;
}

export const featureFlagsReducer = createReducer(
    initialFeatureFlagsState,
    on(FeatureFlagsActions.getFeatureFlagsSuccess, (state, { featureFlags }): FeatureFlagsState => {
        return { ...state, featureFlags: featureFlagsToBoolean(featureFlags) };
    }),
);
