import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store';

export const selectRouterFeature = createSelector(
    (state: AppState) => state.router,
    (router) => router,
);
export const {
    selectRouteParams, // select the current route params
    selectCurrentRoute,
    selectUrl,
    selectQueryParams,
} = getRouterSelectors(selectRouterFeature);

export const selectActiveChannelId = createSelector(
    selectRouteParams,
    (routeParams): string | undefined => routeParams.channelId,
);
