import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

const userStateFeatureKey = 'user';

export const selectUserFeature = createFeatureSelector<UserState>(userStateFeatureKey);

export const selectSiloLogo = createSelector(selectUserFeature, (state: UserState) => state.userInfo?.silo?.logo);

export const selectUserSilo = createSelector(selectUserFeature, (state: UserState) => state.userInfo?.silo);

export const selectSiloName = createSelector(selectUserFeature, (state: UserState) => state.userInfo?.silo?.name);

export const selectSiloPrivateName = createSelector(
    selectUserFeature,
    (state: UserState) => state.userInfo?.silo?.private_name,
);

export const selectUserInfo = createSelector(selectUserFeature, (state: UserState) => state.userInfo);

export const selectUserSilos = createSelector(selectUserFeature, (state: UserState) => state.userSilos);

export const selectSiloOrganisation = createSelector(
    selectUserFeature,
    (state: UserState) => state.userInfo?.organisation,
);

export const selectSwitchSiloStatus = createSelector(selectUserFeature, (state: UserState) => state.switchSiloStatus);

export const selectSetUserSiloErrorMessage = createSelector(
    selectUserFeature,
    (state: UserState) => state.setUserSiloErrorMessage,
);

export const selectUserInfoDetails = createSelector(selectUserFeature, (state: UserState) => state.userInfo?.user);

export const selectUserIsSuperUser = createSelector(
    selectUserFeature,
    (state: UserState) => state.userInfo?.user?.is_superuser,
);

export const selectUpdateUserSiloStatus = createSelector(
    selectUserFeature,
    (state: UserState) => state.updateSiloStatus,
);

export const selectUserIsSiloAdmin = createSelector(
    selectUserFeature,
    (state: UserState) => state.userInfo?.user?.is_silo_admin,
);

export const selectUserIsOrganisationAdmin = createSelector(
    selectUserFeature,
    (state: UserState) => state.userInfo?.user?.is_organisation_admin,
);

export const selectTrialDays = createSelector(selectUserFeature, (state: UserState) => state.trialDays);

export const selectSiloScoreWording = createSelector(
    selectUserFeature,
    (state: UserState) => state.userInfo?.silo?.score_wording,
);

export const selectSiloScoreWordingChoises = createSelector(
    selectUserFeature,
    (state: UserState) => state.userInfo?.silo?.score_wording_choices,
);

export const selectUsersPreferences = createSelector(selectUserFeature, (state: UserState) => state.usersPreferences);

export const selectCurrentUserPreferences = createSelector(selectUserFeature, (state: UserState) => {
    const userId = state.userInfo?.user?.id;
    return userId ? state.usersPreferences[userId] : {};
});

export const selectIsAuthorized = createSelector(selectUserFeature, (state: UserState) => state.isAuthorized);
