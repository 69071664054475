@if (scoreWording$ | async; as scoreWording) {
    <div class="post_source">
        <span class="label" [ngStyle]="{ 'width.px': labelWidth }">Manual {{ scoreWording.word }}: </span>
        @if (post.data.score?.manual_score != null) {
            <span class="comment score" [ngClass]="'score-' + post.data.score?.manual_score">
                <mat-icon class="size-auto"><i [ngClass]="iconClass"></i></mat-icon>
                {{ scoreWording.levels[post.data.score?.manual_score] | titlecase }}
            </span>
        }
        <span class="edit" (click)="openScoreDialog()">{{
            post.data.score?.manual_score != null ? 'Edit' : 'Add'
        }}</span>
    </div>
}
