import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material/material.module';

import { BreachBodyComponent } from './components/post-components/post-types/breach/breach-body/breach-body.component';
import { BreachHeaderComponent } from './components/post-components/post-types/breach/breach-header/breach-header.component';
import { ChangeItemsCategoryComponent } from './dialogs/change-items-category/change-items-category.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { CreditCardBodyComponent } from './components/post-components/post-types/credit-card/credit-card-body/credit-card-body.component';
import { CreditCardDetailsComponent } from './dialogs/reader-modal/credit-card-details/credit-card-details.component';
import { CreditCardHeaderComponent } from './components/post-components/post-types/credit-card/credit-card-header/credit-card-header.component';
import { CreditCardPostComponent } from './dialogs/reader-modal/post-types/credit-card-post/credit-card-post.component';
import { EmptyPageComponent } from './components/empty-page/empty-page.component';
import { ForumBodyComponent } from './components/post-components/post-types/forum/forum-body/forum-body.component';
import { ForumHeaderComponent } from './components/post-components/post-types/forum/forum-header/forum-header.component';
import { ImagePreviewDialogComponent } from './dialogs/image-preview-dialog/image-preview-dialog.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderLocalComponent } from './components/loader/loader-local.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MetadataDialogComponent } from './dialogs/metadata-dialog/metadata-dialog.component';
import { MSLoadingSpinnerDirective } from './directives/loading-spinner.directive';
import { MultipleImageUploaderComponent } from './components/multiple-image-uploader/multiple-image-uploader.component';
import { NewsletterDistributionDialogComponent } from './dialogs/newsletter-distribution-dialog/newsletter-distribution-dialog.component';
import { NewsletterPreviewDialogComponent } from './dialogs/newsletter-preview-dialog/newsletter-preview-dialog.component';
import { NewsPostComponent } from './dialogs/reader-modal/post-types/news-post/news-post.component';
import { PostActionButtonsComponent } from './components/post-components/post-action-buttons/post-action-buttons.component';
import { PostCommentDialogComponent } from './dialogs/post-comment-dialog/post-comment-dialog.component';
import { PostFeedPreviewComponent } from './components/post-feed-preview/post-feed-preview.component';
import { PostTypeImageComponent } from './components/post-components/post-type-image/post-type-image.component';
import { PostTypeLabelComponent } from './components/post-components/post-type-label/post-type-label.component';
import { ProductImagesSliderComponent } from './components/product-images-slider/product-images-slider.component';
import { ReaderModalComponent } from './dialogs/reader-modal/reader-modal.component';
import { ReaderSideMenuComponent } from './dialogs/reader-modal/reader-side-menu/reader-side-menu.component';
import { SearchHelpDialogComponent } from './dialogs/search-help-dialog/search-help-dialog.component';
import { SearchItemComponent } from './components/search-item/search-item.component';
import { ShodanBodyComponent } from './components/post-components/post-types/shodan/shodan-body/shodan-body.component';
import { ShodanHeaderComponent } from './components/post-components/post-types/shodan/shodan-header/shodan-header.component';
import { SiloDelitionDialogComponent } from './dialogs/silo-delition-dialog/silo-delition-dialog.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { SpinnerWrapperComponent } from './components/spinner-wrapper/spinner-wrapper.component';
import { SubmitButtonTooltipComponent } from './components/submit-button-tooltip/submit-button-tooltip.component';
import { TagItemComponent } from './components/tag-item/tag-item.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UsersGridComponent } from './components/users-grid/users-grid.component';
import { WhoisBodyComponent } from './components/post-components/post-types/whois/whois-body/whois-body.component';
import { DateRangeOptionComponent } from './components/search-panel/advanced-options/date-range-option/date-range-option.component';
import { DatepickerOptionComponent } from './components/search-panel/advanced-options/datepicker-option/datepicker-option.component';
import { WhoisHeaderComponent } from './components/post-components/post-types/whois/whois-header/whois-header.component';
import { PostBodyComponent } from './components/post-components/post-body/post-body.component';
import { PostHeaderComponent } from './components/post-components/post-header/post-header.component';
import { AlertComponent } from './alert/alert.component';
import { ScoreToggleComponent } from './components/score-toggle/score-toggle.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ScoreFilterComponent } from './components/score-filter/score-filter.component';
import { PostScoreComponent } from './dialogs/post-score/post-score.component';
import { PostManualScoreComponent } from './components/post-components/post-manual-score/post-manual-score.component';
import { MSDigitsOnlyDirective } from './directives/digits-only.directive';
import { DarkwebHeaderComponent } from './components/post-components/post-types/darkweb/darkweb-header/darkweb-header.component';
import { DarkwebBodyComponent } from './components/post-components/post-types/darkweb/darkweb-body/darkweb-body.component';
import { PostScoreIconComponent } from './components/post-components/post-score-icon/post-score-icon.component';
import { PostAiScoreComponent } from './components/post-components/post-ai-score/post-ai-score.component';
import { ScoreEvaluationComponent } from './dialogs/ai/ai-analytics-modals/score-evaluation/score-evaluation.component';
import { AIReportComponent } from './dialogs/ai/ai-analytics-modals/posts-report/ai-report.component';
import { AiModalsTemplateComponent } from './dialogs/ai/ai-analytics-modals/analytics-modals-template/ai-modals-template.component';
import { PostEditAiScoreComponent } from './dialogs/ai/post-ai-score-edit/post-edit-ai-score.component';
import { MarkdownModule } from 'ngx-markdown';
import { MatomoModule } from 'ngx-matomo-client';
import { NgxStripeModule } from 'ngx-stripe';
import { LeaksBodyComponent } from './components/post-components/post-types/leaks/leaks-body/leaks-body.component';
import { LeaksHeaderComponent } from './components/post-components/post-types/leaks/leaks-header/leaks-header.component';
import { IncludePostComponent } from './components/post-components/include-post/include-post.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        AngularSvgIconModule,
        RouterModule,
        IncludePostComponent,

        MarkdownModule.forRoot(),
        MatAutocompleteModule,
        MatomoModule,
        NgxStripeModule.forRoot(),
    ],
    declarations: [
        ImageUploaderComponent,
        LoaderComponent,
        LoaderLocalComponent,
        ConfirmationDialogComponent,
        SiloDelitionDialogComponent,
        PostCommentDialogComponent,
        NewsletterPreviewDialogComponent,
        NewsletterDistributionDialogComponent,
        PostFeedPreviewComponent,
        ProductImagesSliderComponent,
        ReaderModalComponent,
        ReaderSideMenuComponent,
        NewsPostComponent,
        CreditCardPostComponent,
        CreditCardDetailsComponent,
        SnackBarComponent,
        SpinnerWrapperComponent,

        ShodanBodyComponent,
        ShodanHeaderComponent,
        BreachBodyComponent,
        BreachHeaderComponent,
        LeaksBodyComponent,
        LeaksHeaderComponent,
        CreditCardBodyComponent,
        CreditCardHeaderComponent,
        DarkwebHeaderComponent,
        DarkwebBodyComponent,
        SearchHelpDialogComponent,
        WhoisHeaderComponent,
        WhoisBodyComponent,
        MetadataDialogComponent,
        ForumHeaderComponent,
        ForumBodyComponent,
        UsersGridComponent,
        MultipleImageUploaderComponent,
        PostActionButtonsComponent,
        PostTypeLabelComponent,
        PostTypeImageComponent,
        ChangeItemsCategoryComponent,
        TagItemComponent,
        SearchItemComponent,

        EmptyPageComponent,

        ImagePreviewDialogComponent,
        SubmitButtonTooltipComponent,

        MSLoadingSpinnerDirective,
        MSDigitsOnlyDirective,

        TruncatePipe,
        DateRangeOptionComponent,
        DatepickerOptionComponent,
        PostBodyComponent,
        PostHeaderComponent,
        AlertComponent,
        FilterPipe,
        ScoreToggleComponent,
        ScoreFilterComponent,
        PostScoreComponent,
        PostManualScoreComponent,
        PostScoreIconComponent,
        PostAiScoreComponent,
        ScoreEvaluationComponent,
        AIReportComponent,
        AiModalsTemplateComponent,
        PostEditAiScoreComponent,
    ],
    exports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        ImageUploaderComponent,
        LoaderComponent,
        LoaderLocalComponent,
        ConfirmationDialogComponent,
        SiloDelitionDialogComponent,
        PostFeedPreviewComponent,
        ProductImagesSliderComponent,
        ReaderModalComponent,
        ReaderSideMenuComponent,
        NewsPostComponent,
        CreditCardPostComponent,
        CreditCardDetailsComponent,
        BreachBodyComponent,
        BreachHeaderComponent,
        CreditCardBodyComponent,
        CreditCardHeaderComponent,
        DarkwebHeaderComponent,
        DarkwebBodyComponent,
        ShodanBodyComponent,
        ShodanHeaderComponent,
        SearchHelpDialogComponent,
        SnackBarComponent,
        WhoisHeaderComponent,
        WhoisBodyComponent,
        ForumHeaderComponent,
        ForumBodyComponent,
        UsersGridComponent,
        MultipleImageUploaderComponent,
        PostTypeLabelComponent,
        PostTypeImageComponent,
        SpinnerWrapperComponent,
        ChangeItemsCategoryComponent,
        TagItemComponent,
        SearchItemComponent,
        AlertComponent,

        MatAutocompleteModule,
        MetadataDialogComponent,
        PostActionButtonsComponent,
        EmptyPageComponent,
        ImagePreviewDialogComponent,
        SubmitButtonTooltipComponent,
        PostScoreComponent,
        PostScoreIconComponent,
        PostAiScoreComponent,
        MSLoadingSpinnerDirective,
        MSDigitsOnlyDirective,
        TruncatePipe,
        PostHeaderComponent,
        PostBodyComponent,
        ScoreToggleComponent,
        ScoreFilterComponent,
        MatomoModule,
        FilterPipe,
        RouterModule,
    ],
    providers: [FilterPipe, IncludePostComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
