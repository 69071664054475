<div class="card_header_content">
    @if (CARD_ICONS_IMG[selectedPostReader.data.type]) {
        <img
            mat-list-avatar
            [src]="'/assets/images/payment_icons.png'"
            [ngStyle]="getStyle(selectedPostReader.data.type)"
        />
    }
    <div class="post_details_wrap">
        <div class="card_title_container card_text_wrap">
            <span class="card_text_item">{{ selectedPostReader.data.bank }}</span>
            <div class="card_title_line_container">
                @if (selectedPostReader.data.score?.dominant_score != null) {
                    <app-post-score-icon [postData]="selectedPostReader.data" class="reader-score" />
                    <span class="divider"></span>
                }
                <span class="card_text_item"
                    >Type:<span class="card_span_item">{{ selectedPostReader.data.type?.toUpperCase() }}</span></span
                >
                <span class="divider"></span>
                <span class="card_text_item"
                    >Mark:<span class="card_span_item">{{ selectedPostReader.data.mark?.toUpperCase() }}</span></span
                >
                <span class="divider"></span>
                <span class="card_text_item"
                    >Class:<span class="card_span_item">{{ selectedPostReader.data.subtype }}</span></span
                >

                @if (selectedPostReader.data.is_manual) {
                    <span class="divider"></span>
                    <span class="card_text_item">Added manually</span>
                }
            </div>
        </div>
    </div>
</div>
