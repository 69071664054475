import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AIPostScoreDetail, ScoreWording } from '@app/data/models';
import { Observable } from 'rxjs';
import { PostEditAIScoreStore, ViewModel } from './post-edit-ai-score.store';
import { filter } from 'rxjs/operators';

type DialogData = { aiScore: AIPostScoreDetail; postId: string; scoreWording: ScoreWording };
@Component({
    selector: 'app-post-edit-ai-score',
    templateUrl: './post-edit-ai-score.component.html',
    styleUrls: ['./post-edit-ai-score.component.scss'],
    providers: [PostEditAIScoreStore],
    standalone: false,
})
export class PostEditAiScoreComponent implements OnInit {
    form: FormGroup;
    vm$: Observable<ViewModel>;
    private formBuilder = inject(FormBuilder);
    private dialogData: DialogData = inject(MAT_DIALOG_DATA);
    private postEditAIScoreStore = inject(PostEditAIScoreStore);
    private dialogRef = inject(MatDialogRef<PostEditAiScoreComponent>);

    ngOnInit() {
        this.vm$ = this.postEditAIScoreStore.vm$;
        this.setForm();
        this.vm$.pipe(filter((vm: ViewModel) => vm.saveScoreLoaded)).subscribe(() => {
            this.onSaved();
        });
    }

    onSave() {
        this.postEditAIScoreStore.saveScore({
            aiScore: this.form?.value || {},
            postId: this.dialogData.postId,
        });
    }

    onSaved() {
        this.dialogRef.close({ postId: this.dialogData.postId, aiScore: this.form.value });
    }

    resetErrorMessage() {
        this.postEditAIScoreStore.resetErrorMessage();
    }

    private setForm() {
        const aiScore = this.dialogData.aiScore;
        this.form = this.formBuilder.group({
            entity: [aiScore.entity],
            description: [aiScore.description],
            category: [aiScore.category],
            source_of_score: [aiScore.source_of_score],
        });
    }

    get modalTitle() {
        return `Edit AI ${this.dialogData.scoreWording.word} details`;
    }
}
