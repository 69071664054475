import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Post } from '@app/data/models';
import { DialogNames, ModalsService, ModalsQueueService } from '@app/core/services';
import { filter, switchMap } from 'rxjs/operators';
import { ChannelService } from '@app/data/services/channel.service';
import {
    AddArticleModalComponent,
    MetadataDialogComponent,
    PostScoreComponent,
    ReaderModalComponent,
} from '@app/shared/dialogs';
import { PostAssignItemsDialogComponent } from '@app/modules/feed/post-assign-items-dialog/post-assign-items-dialog.component';

@Component({
    selector: 'app-post-feed-preview',
    templateUrl: './post-feed-preview.component.html',
    styleUrls: ['./post-feed-preview.component.scss'],
    standalone: false,
})
export class PostFeedPreviewComponent implements OnInit {
    @Input() channelFeedPosts: Post[];
    @Input() channel;
    @Input() channelFeedCount;
    @Input() selectedChannelId;
    @Input() loading;
    @Input() activeLoading;
    @Input() feedMaxHeight;
    @Input() searchParams;
    @Input() saveSearchHidden;
    @Input() isOpenedFromSearchForm;
    @Input() checkProvider;
    @Input() endOfPosts;
    @Input() hasAdminRights;

    @Output() saveSearch = new EventEmitter<any>();

    readerModalRef: any;
    externalProvider = 'external';
    metadataRef;

    constructor(
        private modalsQueueService: ModalsQueueService,
        private modalsService: ModalsService,
        private channelService: ChannelService,
    ) {}

    ngOnInit() {}

    setPostAsRead(isRead, postId, index, post = null): void {
        post.isExpanded = !post.isExpanded;

        if (!this.channelFeedPosts[index].comment) {
            this.channelFeedPosts[index].comment = '';
        }

        if (!this.channelFeedPosts[index].searches) {
            this.channelFeedPosts[index].searches = [];
        }
    }

    onOpenInReader(postId) {
        this.readerModalRef = this.modalsService.openDialog(DialogNames.Reader, ReaderModalComponent, {
            postID: postId,
            channelRef: this,
        });

        if (this.isOpenedFromSearchForm) {
            this.modalsQueueService.addRefToQueue(this.readerModalRef);
        }
    }

    openAssignDialog(post, type) {
        this.modalsService
            .openDialog(DialogNames.PostTags, PostAssignItemsDialogComponent, {
                post: post,
                type: type,
            })
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe((data) => {
                if (type === 'tags') {
                    post.tags = data;
                } else {
                    post.searches = data;
                }
            });
    }

    closeReaderModal() {
        const modalRef = this.readerModalRef;
        modalRef.close();
    }

    showMetadata(post, open = true) {
        if (open && this.hasAdminRights) {
            this.metadataRef = this.modalsService.openDialog(DialogNames.Metadata, MetadataDialogComponent, {
                post,
            });
        } else {
            this.metadataRef.close();
        }
    }

    addPost(post) {
        this.modalsService.openDialog(DialogNames.Post, AddArticleModalComponent, {
            post: post,
            articleType: post.type,
        });
    }

    onSaveSearch() {
        const searchParamsForSave = {
            ...this.searchParams,
            collections: this.getValuesByKey(this.searchParams.collections, 'collection_name'),
        };
        this.saveSearch.emit({
            searchCategory: null,
            searchCategoriesList: null,
            params: searchParamsForSave,
        });
    }

    onOpenScoreDialog(post = null) {
        this.modalsService
            .openDialog(DialogNames.PostScore, PostScoreComponent, {
                post: post,
            })
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                switchMap(() => this.channelService.getPostInfo(post.data.id)),
            )
            .subscribe((postData) => {
                post.data.score = postData.data.score;
            });
    }

    getValuesByKey(arr: any[], key: string, toString?: boolean) {
        if (!toString) return arr.map((collection) => collection[key]);
        return arr.map((collection) => collection[key]).join(', ');
    }

    get maxPostsPerSearchNum(): number {
        if (
            this.searchParams.provider === 'external' &&
            this.searchParams.collections[0].collection_name === 'ext_databreach'
        ) {
            return 40_000;
        } else {
            return 10_000;
        }
    }
}
