@if (loading) {
    <div class="loader-wrapper">
        <mat-spinner class="spinner" color="primary" strokeWidth="2" diameter="26"></mat-spinner>
    </div>
} @else {
    <div class="channels_container_wrap">
        @if (channelFeedPosts.length > 0) {
            <div class="header-wrapper">
                <div class="amount_pages">
                    @if (channelFeedCount < maxPostsPerSearchNum) {
                        <b>{{ channelFeedCount | number }}</b>
                    } @else {
                        <b>More than {{ channelFeedCount | number }}</b>
                    }
                    results for <b>'{{ searchParams.searchString }}'</b> in
                    <b>{{ getValuesByKey(searchParams.collections, 'display_name', true) }}</b>
                </div>

                @if (!saveSearchHidden && hasAdminRights) {
                    <button class="btn primary_btn" (click)="onSaveSearch()" mat-raised-button>
                        <span>Save search</span>
                    </button>
                }
            </div>
        }
        <div class="channels_container">
            <div>
                <div class="sb_box_container">
                    @if (channelFeedPosts && channelFeedPosts.length) {
                        <div class="filters_feed_wrapper sb_list_container" [style.height]="feedMaxHeight">
                            <div class="sb_element searchScroll_container" id="searchScroll_container">
                                @if (channelFeedPosts && channelFeedCount > 0) {
                                    <div class="feed_listing">
                                        <mat-list>
                                            <mat-accordion displayMode="flat" multi>
                                                @for (post of channelFeedPosts; track post; let i = $index) {
                                                    <mat-expansion-panel
                                                        hideToggle="true"
                                                        class="panel"
                                                        [ngClass]="!post.isExpanded ? 'expanded' : ''"
                                                    >
                                                        <mat-expansion-panel-header
                                                            (click)="
                                                                setPostAsRead(post.data.is_read, post.data.id, i, post)
                                                            "
                                                        >
                                                            <app-post-header
                                                                [post]="post"
                                                                [channel]="channel"
                                                                [isActiveSearch]="true"
                                                                [hasAdminRights]="hasAdminRights"
                                                                (addPost)="addPost($event)"
                                                            ></app-post-header>
                                                            <ng-template matExpansionPanelContent>
                                                                <app-post-body
                                                                    [post]="post"
                                                                    [checkProvider]="checkProvider"
                                                                    [isActiveSearch]="true"
                                                                    [hasAdminRights]="hasAdminRights"
                                                                    [searchParams]="searchParams"
                                                                    (openAssignDialogEvent)="
                                                                        openAssignDialog($event, 'tags')
                                                                    "
                                                                    (openScoreDialogEvent)="onOpenScoreDialog($event)"
                                                                    (openInReader)="onOpenInReader($event)"
                                                                    (showMetadataDialog)="showMetadata($event)"
                                                                    (addPostEvent)="addPost($event)"
                                                                ></app-post-body>
                                                            </ng-template>
                                                        </mat-expansion-panel-header>
                                                    </mat-expansion-panel>
                                                }
                                            </mat-accordion>
                                        </mat-list>
                                        @if (activeLoading || endOfPosts) {
                                            <div
                                                class="activeLoading"
                                                [ngClass]="endOfPosts == true ? 'noMorePosts' : ''"
                                            >
                                                {{ endOfPosts != true ? 'Loading ...' : 'No more posts' }}
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    } @else {
                        <div class="no-posts-wrapper">
                            <div class="info">
                                <mat-icon class="size-auto"><i class="fal fa-exclamation-circle"></i></mat-icon> There
                                are no posts found
                            </div>

                            @if (!saveSearchHidden && hasAdminRights) {
                                <button class="btn primary_btn" (click)="onSaveSearch()" mat-raised-button>
                                    <span>Save search</span>
                                </button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}
