<mat-list-item
    class="item-header"
    [ngClass]="post.isExpanded ? 'expanded' : ''"
    [class.distributed]="post.data.previously_distributed"
>
    <div class="card_wrap header_wrapper">
        <div class="title_wrapper">
            @if (post.data.is_read !== true) {
                <span class="read"></span>
            }

            <app-post-type-image [post]="post"></app-post-type-image>

            @if (!post.isExpanded) {
                <div class="header_wrapper_collapsed">
                    <span class="card_text_item bank">{{ post.data.bank }}</span>

                    <div class="card_text_wrap">
                        <span class="card_text_item action">
                            @if (post.data.indexed_at) {
                                <span
                                    matTooltip="{{
                                        dateService.setDateISO8601(post.data.indexed_at)
                                            | date: dateService.getDateFormat()
                                    }}"
                                    matTooltipPosition="above"
                                >
                                    Crawled:
                                    <span class="card_span_item">
                                        {{ dateService.getDateDiff('' + post.data.indexed_at) }}
                                    </span>
                                </span>
                            }
                            <span class="last-action">
                                Last action:
                                <span class="card_span_item">
                                    {{
                                        dateService.getDateDiff('' + (post.data.publish_date || post.data.published_at))
                                    }}
                                </span>
                            </span>
                            <span class="card_text_item name"
                                >Name:<span class="card_span_item">{{
                                    post.data.name && post.data.name.length > 20
                                        ? (post.data.name | slice: 0 : 20) + '...'
                                        : post.data.name
                                }}</span></span
                            >
                            @if (post.data.bin) {
                                <span class="card_text_item short bin"
                                    >Bin:<span class="card_span_item">{{ post.data.bin }}</span></span
                                >
                            }
                            @if (post.data.expM) {
                                <span class="card_text_item short exp"
                                    >Exp:<span class="card_span_item"
                                        >{{ post.data.expM }}/{{ post.data.expY }}</span
                                    ></span
                                >
                            }
                            @if (post.data.city) {
                                <span class="card_text_item name city"
                                    >City:<span class="card_span_item">{{ post.data.city }}</span></span
                                >
                            }
                            @if (post.data.zip) {
                                <span class="card_text_item short zip"
                                    >Zip:<span class="card_span_item">{{ post.data.zip }}</span></span
                                >
                            }
                            @if (post.data.country) {
                                <span class="card_text_item short country"
                                    >Country:<span class="card_span_item">{{
                                        post.data.country && post.data.country.length > 15
                                            ? (post.data.country | slice: 0 : 20) + '...'
                                            : post.data.country
                                    }}</span></span
                                >
                            }
                            @if (post.data.is_manual) {
                                <span class="card_text_item short"> Added manually</span>
                            }
                            @if (post.data.previously_distributed && curatorMode) {
                                <span>
                                    <b>Distributed</b>
                                    <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                                ></span>
                            }
                        </span>
                    </div>
                </div>
            }
        </div>

        @if (post.isExpanded) {
            <div class="card_text_wrap" [ngClass]="post.isExpanded ? 'expanded' : ''">
                @if (post.isExpanded) {
                    <app-post-type-label [post]="post"></app-post-type-label>
                }

                <div class="card_title_container">
                    <div class="card_title_line_container">
                        <span class="card_text_item">{{ post.data.bank }}</span>
                        <span class="divider"></span>
                        <span class="card_text_item"
                            >Type:<span class="card_span_item">{{ post.data.type?.toUpperCase() }}</span></span
                        >
                        <span class="divider"></span>
                        <span class="card_text_item"
                            >Mark:<span class="card_span_item">{{ post.data.mark?.toUpperCase() }}</span></span
                        >
                        <span class="divider"></span>
                        <span class="card_text_item"
                            >Class:<span class="card_span_item">{{ post.data.subtype }}</span></span
                        >

                        @if (post.data.is_manual) {
                            <span class="divider"></span>
                            <span class="card_text_item">Added manually</span>
                        }
                    </div>

                    @if (post.data.previously_distributed && post.isExpanded && curatorMode) {
                        <span class="item_distributed"
                            ><b>Distributed</b>
                            <mat-icon class="size-auto distributed"><i class="far fa-check"></i></mat-icon
                        ></span>
                    }
                </div>
            </div>
        }
    </div>
</mat-list-item>
