/** API response for transactions table */
export interface TransactionsResponse {
    count: number;
    next?: string;
    previous?: string;
    results: TransactionItem[];
}

export interface TransactionItem {
    message: string;
    /** Plain date "2023-12-14" */
    created_at: string;
    entries: TransactionItemChild[];
}

export interface TransactionItemChild {
    id: number;
    message: string;
    state: TransactionState;
    updated_at: string;
    created_at: string;
    triggered_by: string;
    credit_balance_change: string;
    error_message: string;
}

export enum TransactionState {
    INITIAL = 'initial',
    CHECKED = 'checked',
    PENDING = 'pending',
    COMPLETED = 'completed',
    ERROR = 'error',
    CANCELLED = 'cancelled',
}
