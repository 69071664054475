import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    readonly toggleLoaderSubject$: Observable<boolean>;
    private toggleLoaderSubject = new BehaviorSubject<boolean>(false);

    constructor() {
        this.toggleLoaderSubject$ = this.toggleLoaderSubject.asObservable();
    }

    showLoader() {
        this.toggleLoader(true);
    }

    hideLoader() {
        this.toggleLoader(false);
    }

    private toggleLoader(isVisible: boolean) {
        this.toggleLoaderSubject.next(isVisible);
    }
}
