import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';

import { UtilsService, DateService } from '@app/core/services';
import { AIPostScoreDetail, Post, Channel, SearchItem } from '@app/data/models';
import { PostTypes } from '@app/shared/interfaces/post-type.interface';

@Component({
    selector: 'app-post-body',
    templateUrl: './post-body.component.html',
    styleUrls: ['./post-body.component.scss'],
    standalone: false,
})
export class PostBodyComponent implements OnInit {
    @Input() searchParams;
    @Input() post: Post;
    @Input() readerMode: boolean;
    @Input() channel: Channel;
    @Input() index: number;
    @Input() isActiveSearch = false;
    @Input() hasAdminRights = false;
    @Input() checkProvider;

    @Output() includeInNewsletterEvent = new EventEmitter<Post>();
    @Output() addPostEvent = new EventEmitter<Post>();
    @Output() editPostEvent = new EventEmitter<Post>();
    @Output() editSearchEvent = new EventEmitter<SearchItem>();
    @Output() showMetadataDialog = new EventEmitter<any>();
    @Output() deletePostEvent = new EventEmitter<any>();
    @Output() openCommentDialogEvent = new EventEmitter<any>();
    @Output() openInReader = new EventEmitter<any>();
    @Output() openAssignDialogEvent = new EventEmitter<any>();
    @Output() openScoreDialogEvent = new EventEmitter<any>();
    @Output() updateAiScore = new EventEmitter<{ postId: string; aiScore: AIPostScoreDetail }>();

    postTypes: typeof PostTypes = PostTypes;
    postDescription: string;
    _DateService = inject(DateService);
    readonly externalProvider = 'external';
    readonly cardImg = '/assets/images/stopper.png';
    private utilsService = inject(UtilsService);

    ngOnInit(): void {
        this.setDescription();
    }

    onIncludeInNewsletter(event) {
        this.includeInNewsletterEvent.emit(event);
    }
    onAddPost(event: Post) {
        this.addPostEvent.emit(event);
    }
    onEditSearch(event: SearchItem) {
        this.editSearchEvent.emit(event);
    }
    onEditPost(event: Post) {
        this.editPostEvent.emit(event);
    }
    showMetadata(event) {
        this.showMetadataDialog.emit(event);
    }
    deletePost(event) {
        this.deletePostEvent.emit(event);
    }
    openCommentDialog(event) {
        this.openCommentDialogEvent.emit(event);
    }
    onOpenInReader(event) {
        this.openInReader.emit(event);
    }
    openAssignDialog(event) {
        this.openAssignDialogEvent.emit(event);
    }
    onOpenScoreDialog(event) {
        this.openScoreDialogEvent.emit(event);
    }
    onUpdateAiScore(event: { postId: string; aiScore: AIPostScoreDetail }) {
        this.updateAiScore.emit(event);
    }

    setDescription() {
        if (this.post.type === this.postTypes.databreach) return this.getDataBreachDescription();
        if (this.post.type === this.postTypes.news && this.post.data.abstract?.length) {
            this.postDescription = this.utilsService.truncateString(this.post.data.abstract, 350);
        }
        if (this.post.type === this.postTypes.creditcard && this.post.data.content?.length) {
            this.postDescription = this.getCreditCardDescription();
        }
        if (this.post.type === this.postTypes.leaks && this.post.data.line?.length) {
            this.postDescription = this.utilsService.truncateString(this.post.data.line, 350);
        }
        if (!this.post.data.abstract && this.post.data.content?.length) {
            this.postDescription = this.utilsService.truncateString(this.post.data.content, 350);
        }
    }

    private getDataBreachDescription() {
        if (!this.getIsExternalInSearchPreview()) {
            return this.utilsService.truncateString(this.post.data.breach?.description, 350);
        }
        return this.post.data.breach?.description;
    }

    private getCreditCardDescription() {
        if (!this.getIsExternalInSearchPreview()) {
            return this.utilsService.truncateString(this.post.data.content, 350);
        }
        return this.post.data.content;
    }

    private getIsExternalInSearchPreview() {
        return this.searchParams?.provider == this.externalProvider && this.checkProvider;
    }
}
