import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-multiple-image-uploader',
    templateUrl: './multiple-image-uploader.component.html',
    styleUrls: ['./multiple-image-uploader.component.scss'],
    standalone: false,
})
export class MultipleImageUploaderComponent implements OnInit {
    @Input() images;
    @Output() imageupload = new EventEmitter();

    urls = [];

    ngOnInit() {
        if (this.images && this.images.length) {
            this.urls = this.images;
        }
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            const filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                const reader = new FileReader();

                reader.onload = (loadEvent: any) => {
                    this.urls.push(loadEvent.target.result);
                    this.imageupload.emit(this.urls);
                };

                reader.readAsDataURL(event.target.files[i]);
            }
        }
    }

    deleteUrl(i) {
        this.urls.splice(i, 1);
        this.imageupload.emit(this.urls);
    }

    clear() {
        this.urls = [];
        this.imageupload.emit(this.urls);
    }
}
