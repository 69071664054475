<mat-button-toggle-group name="fontStyle" aria-label="Font Style" [formControl]="scoreControl">
    <mat-button-toggle [value]="-1" class="risk-score-none">
        <mat-icon class="size-auto"><i class="far fa-ban"></i></mat-icon> No icon
    </mat-button-toggle>
    @for (scoreValue of [0, 1, 2]; track scoreValue) {
        <mat-button-toggle [value]="scoreValue" [ngClass]="'risk-score-' + scoreValue"
            ><mat-icon class="size-auto"><i class="fas fa-exclamation-triangle"></i></mat-icon>
            {{ (scoreWording$ | async).levels[scoreValue] | titlecase }}
        </mat-button-toggle>
    }
</mat-button-toggle-group>
