import { Component, Input, inject } from '@angular/core';
import { DateService } from '@app/core/services';

@Component({
    selector: 'app-breach-body',
    templateUrl: './breach-body.component.html',
    styleUrls: ['./breach-body.component.scss'],
    standalone: false,
})
export class BreachBodyComponent {
    @Input() post;

    public dateService = inject(DateService);
}
