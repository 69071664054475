import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ThemingState } from './theming.reducer';

const themingStateFeatureKey = 'themingConfig';
export const selectThemingFeature = createFeatureSelector<ThemingState>(themingStateFeatureKey);

export const selectThemingConfig = createSelector(selectThemingFeature, (state: ThemingState) => state.themingConfig);

export const selectSearchWith = createSelector(
    selectThemingFeature,
    (state: ThemingState) => state.themingConfig?.searchWith,
);

export const selectIsInitialThemeSet = createSelector(
    selectThemingFeature,
    (state: ThemingState) => state.isInitialThemeSet,
);
