import { Injectable, Injector, inject, runInInjectionContext } from '@angular/core';
import { UserInfo } from '@app/data/models';
import { MatomoTracker } from 'ngx-matomo-client';
import { notTrackableDomains, pendoApiKey } from '../constants/tracking';
import { NgxPendoService } from 'ngx-pendo';
import { interval, take } from 'rxjs';
import { environment } from '@env/environment';

/**
 * Service for initializing matomo and pendo tracking
 */
@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    constructor(
        private matomo: MatomoTracker,
        private injector: Injector,
    ) {}

    setupTracking(userInfo: UserInfo) {
        this.setupMatomoTracking(userInfo);
        this.setupPendoTracking(userInfo);
    }
    setMatomoUserId(userInfo: UserInfo) {
        const userId = `email=${userInfo.user.email}/silo=${userInfo.silo.private_name || userInfo.silo.name}`;
        this.matomo.setUserId(userId);
    }

    /**
     * Initialize matomo tracking, if user is not superuser or from not trackable domain
     * @param userInfo
     * */
    private setupMatomoTracking(userInfo: UserInfo) {
        const isSuperUser = userInfo.user.is_superuser;
        const isNotTrackableDomain = notTrackableDomains.some((domain) => userInfo.user.email.includes(domain));
        if (isSuperUser || isNotTrackableDomain) {
            this.matomo.forgetConsentGiven();
        } else {
            this.matomo.setConsentGiven();
        }
        this.setMatomoUserId(userInfo);
    }

    /**
     * Initialize pendo tracking, if pendo api key is available
     * @param userInfo
     **/
    private setupPendoTracking(userInfo: UserInfo) {
        if (!environment.pendo_api_key) return;
        const { user, silo } = userInfo ?? {};
        const role = user.is_silo_admin ? 'admin' : user.is_superuser ? 'superadmin' : 'default';
        const waitForPendo$$ = interval(500)
            .pipe(take(2 * 2 * 60)) // attempt for 2 minutes
            .subscribe(() => {
                if (window['pendo']) {
                    waitForPendo$$.unsubscribe();
                    runInInjectionContext(this.injector, () => {
                        const ngxPendoService = inject(NgxPendoService);
                        ngxPendoService.initialize({
                            visitor: {
                                id: String(user?.id),
                                name: `${user.first_name} ${user.last_name}`,
                                email: user.email,
                                role,
                            },
                            account: {
                                id: String(silo.id),
                                name: silo.private_name,
                            },
                            apiKey: pendoApiKey,
                        });
                    });
                }
            });
    }
}
