import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Tag, TagItem } from '@app/data/models';
import { TagCategoryFormComponent } from './tag-category-form/tag-category-form.component';
import { ChannelService } from '@app/data/services/channel.service';
import { catchError, filter, finalize, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppStateService, LoaderService, DialogNames, ModalsService, UtilsService } from '@app/core/services';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Observable, throwError } from 'rxjs';
import { SnackBarComponent } from '@app/shared/components/snack-bar/snack-bar.component';
import { ChangeItemsCategoryComponent, ConfirmationDialogComponent } from '@app/shared/dialogs';
import { TagFormComponent } from './tag-form/tag-form.component';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss', '../taxonomy-grid.scss'],
    standalone: false,
})
export class TagsComponent implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('selectedTags') selectedTagsProperty: TagItem[];
    @Input() hideEmptyCategories: boolean;
    @Input() readOnlyMode: boolean;
    @Output() selectTags = new EventEmitter<any>();
    @Output() toggleTag = new EventEmitter<any>();
    @Output() unSelectTags = new EventEmitter<any>();

    tagsCategoryList: Tag[];
    tagsCategoryListBeforeSearch: Tag[];
    errorMessage: any;
    dialogsArray = [];
    editTagRef: any;
    editTagCategoryRef: any;
    isEmptyTags: boolean;
    isLoading: boolean;
    bulkSelectionModeActive: boolean;
    sortModeActive: boolean;
    tagsForBulkOperation = [];
    tagsNewOrder: number[];
    tagsCategoriesNewOrder: number[];

    constructor(
        private channelService: ChannelService,
        private dialog: MatDialog,
        private utilsService: UtilsService,
        private modalsService: ModalsService,
        private router: Router,
        private appStateService: AppStateService,
        private loaderService: LoaderService,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit() {
        this.getTags();
    }

    openTagFormDialog(tag?, category?) {
        this.editTagRef = this.modalsService.openDialog(DialogNames.Tag, TagFormComponent, {
            tag: tag || null,
            tagCategoriesList: this.tagsCategoryListBeforeSearch,
            category,
        });

        this.dialogsArray.push(this.editTagRef);

        this.editTagRef
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                tap(() => {
                    this.getTags();
                    this.dialogsArray.pop();
                }),
            )
            .subscribe();
    }

    openTagCategoryFormDialog(tagCategory?) {
        this.editTagCategoryRef = this.dialog.open(TagCategoryFormComponent, {
            panelClass: 'modal-sm',
            autoFocus: false,
            restoreFocus: false,
            data: {
                tagCategory: tagCategory || null,
                tagCategoriesList: this.tagsCategoryListBeforeSearch,
            },
        });

        this.dialogsArray.push(this.editTagCategoryRef);

        this.editTagCategoryRef
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                tap(() => {
                    this.getTags();
                    this.dialogsArray.pop();
                }),
            )
            .subscribe();
    }

    onGoToTagsPageClick() {
        this.router.navigate(['/app/tags']);
    }

    deleteTagCategory(category) {
        this.channelService
            .deleteTagCategory(category.id)
            .pipe(
                tap(() => {
                    this.appStateService.getChannelsList();
                    this.getTags();
                }),
            )
            .subscribe();
    }

    onSearch(event: Event) {
        const searchTerm = (event.target as HTMLInputElement).value;
        this.tagsCategoryList = this.getTagsListFiltered(searchTerm);
    }

    onToggleSelectionMode() {
        this.tagsForBulkOperation = [];
        this.bulkSelectionModeActive = !this.bulkSelectionModeActive;
    }

    onToggleSortMode() {
        this.sortModeActive = !this.sortModeActive;
        if (!this.sortModeActive) {
            this.tagsCategoryList = JSON.parse(JSON.stringify(this.tagsCategoryList));
            this.resetSortingOrder();
        }
    }

    onToggleTag(event) {
        if (this.bulkSelectionModeActive) {
            this.selectItemForBulkOperation(event);
        }
        this.toggleTag.emit(event);
    }

    selectAllCategoryItems(category) {
        if (this.bulkSelectionModeActive) {
            this.selectItemsForBulkOperation(category.tags);
        } else {
            this.selectTags.emit(category.tags);
        }
    }

    unSelectAllCategoryItems(category) {
        if (this.bulkSelectionModeActive) {
            this.unSelectItemsForBulkOperation(category.tags);
        } else {
            this.unSelectTags.emit(category.tags);
        }
    }

    onChaneTagsCategory() {
        const modalRef = this.modalsService.openDialog(DialogNames.ChangeCategory, ChangeItemsCategoryComponent, {
            categories: this.tagsCategoryListBeforeSearch,
            items: this.tagsForBulkOperation,
        });
        modalRef
            .afterClosed()
            .pipe(
                filter((category) => !!category),
                tap((category) => {
                    this.changeItemsCategory(category);
                }),
            )
            .subscribe();
    }

    onDeleteTags() {
        const modalRef = this.modalsService.openDialog(DialogNames.Confirmation, ConfirmationDialogComponent, {
            header: `Delete ${this.tagsForBulkOperation.length > 1 ? 'Tags' : 'Tag'}`,
            message: `Are you sure you would like to delete ${this.tagsForBulkOperation.length} ${
                this.tagsForBulkOperation.length > 1 ? 'Tags' : 'Tag'
            }?`,
            confirm: 'Delete',
        });
        modalRef
            .afterClosed()
            .pipe(
                filter((confirmed) => !!confirmed),
                tap(() => {
                    this.deleteTags();
                }),
            )
            .subscribe();
    }

    onSortCategories(categories: number[]) {
        this.tagsCategoriesNewOrder = categories;
    }

    onSortCategoryItems(searchesOrder: number[]) {
        this.tagsNewOrder = searchesOrder;
    }

    onSaveSort() {
        if (!this.tagsNewOrder && !this.tagsCategoriesNewOrder) {
            this.sortModeActive = false;
            return;
        }
        this.loaderService.showLoader();
        const sortingRequests = [];
        if (this.tagsNewOrder) {
            sortingRequests.push(this.channelService.setTagsOrder(this.tagsNewOrder));
        }
        if (this.tagsCategoriesNewOrder) {
            sortingRequests.push(this.channelService.setTagssCategoriesOrder(this.tagsCategoriesNewOrder));
        }
        this.onSearchOrderSaved(forkJoin(sortingRequests));
    }

    private onSearchOrderSaved(response: Observable<any>) {
        response
            .pipe(
                tap(() => {
                    this.snackBar.openFromComponent(SnackBarComponent, {
                        duration: 2000,
                        data: { message: 'Tags order successfully saved.' },
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                    });
                    this.sortModeActive = false;
                    this.resetSortingOrder();
                    this.getTags();
                }),
                catchError((err) => {
                    this.snackBar.openFromComponent(SnackBarComponent, {
                        duration: 4000,
                        data: {
                            message:
                                'An error occurred while saving the order of tags. Try again or contact support if the issue persists.',
                        },
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        panelClass: 'error',
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    this.loaderService.hideLoader();
                }),
            )
            .subscribe();
    }

    private resetSortingOrder() {
        this.tagsNewOrder = null;
        this.tagsCategoriesNewOrder = null;
    }

    private getTags() {
        this.tagsCategoryListBeforeSearch = undefined;
        this.tagsCategoryList = undefined;
        this.isLoading = true;
        this.channelService
            .getTags()
            .pipe(
                tap((data) => {
                    if (this.hideEmptyCategories) {
                        this.tagsCategoryListBeforeSearch = data.filter((category) => category.tags.length);
                    } else {
                        this.tagsCategoryListBeforeSearch = data;
                    }
                    this.tagsCategoryList = this.tagsCategoryListBeforeSearch;
                    this.isEmptyTags = !this.tagsCategoryListBeforeSearch.length;
                }),
                catchError((error) => (this.errorMessage = <any>error)),
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe();
    }

    private getTagsListFiltered(searchTerm = ''): Tag[] {
        if (!searchTerm || !this.tagsCategoryListBeforeSearch) return this.tagsCategoryListBeforeSearch;
        return this.utilsService.filterNestedArray(this.tagsCategoryListBeforeSearch, searchTerm, ['tags']);
    }

    private deleteTags() {
        this.channelService
            .deleteTags({ tag_ids: this.tagsForBulkOperation.map((tag) => tag.id) })
            .pipe(
                tap(() => {
                    this.onToggleSelectionMode();
                    this.getTags();
                }),
            )
            .subscribe();
    }

    private changeItemsCategory(targetCategory) {
        this.channelService
            .updateTagsCategory({
                tag_ids: this.tagsForBulkOperation.map((tag) => tag.id),
                category_id: targetCategory.id,
            })
            .pipe(
                tap(() => {
                    this.onToggleSelectionMode();
                    this.getTags();
                }),
            )
            .subscribe();
    }

    private selectItemsForBulkOperation(items: Tag[]) {
        items.forEach((item) => {
            const addedTagIndex = this.findItemIndex(this.tagsForBulkOperation, item);
            if (addedTagIndex === -1) {
                this.tagsForBulkOperation.push({ ...item, type: 'tag' });
            }
        });
    }

    private unSelectItemsForBulkOperation(items: Tag[]) {
        items.forEach((item) => {
            const addedTagIndex = this.findItemIndex(this.tagsForBulkOperation, item);
            if (addedTagIndex !== -1) {
                this.tagsForBulkOperation.splice(addedTagIndex, 1);
            }
        });
    }

    private selectItemForBulkOperation(item: Tag) {
        const addedTagIndex = this.findItemIndex(this.tagsForBulkOperation, item);
        if (addedTagIndex === -1) {
            this.tagsForBulkOperation.push(item);
        } else {
            this.tagsForBulkOperation.splice(addedTagIndex, 1);
        }
    }

    private findItemIndex(tags: Tag[], tag: Tag): number {
        return tags.findIndex((tagItem) => tagItem.id === tag.id);
    }

    get selectionMode(): boolean {
        return this.readOnlyMode || this.bulkSelectionModeActive;
    }

    get selectedTags(): Tag[] {
        return this.bulkSelectionModeActive ? this.tagsForBulkOperation : this.selectedTagsProperty;
    }

    get tagsCount(): number {
        const categories = this.getTagsListFiltered();
        return categories?.reduce((acc, category) => {
            const tagsInCategory = category.tags ? category.tags.length : 0;
            acc += tagsInCategory;
            return acc;
        }, 0);
    }
}
