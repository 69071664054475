<div class="wrapper">
    <div class="page_content">
        @if (matIconClass) {
            <mat-icon class="size-auto page-icon"><i [ngClass]="matIconClass"></i></mat-icon>
        }
        @if (img) {
            <svg-icon class="crown-icon" [src]="'/assets/images/' + img"></svg-icon>
        }
        @if (appTitle) {
            <div class="title" data-testid="empty-page-title">{{ appTitle }}</div>
        }
        @if (description) {
            <div class="description" [ngClass]="{ 'with-link': linkName }" [innerHTML]="description"></div>
        }

        <span class="link" (click)="linkClick()">{{ linkName }}</span>

        @if (buttonName && showButton()) {
            <button
                class="btn primary_btn"
                type="button"
                mat-raised-button="mat-raised-button"
                [attr.data-testid]="buttonTestId"
                (click)="buttonClick()"
            >
                @if (!hidePlus) {
                    <mat-icon class="size-auto"><i class="far fa-plus"></i></mat-icon>
                }
                <span>{{ buttonName }}</span>
            </button>
        }
    </div>
</div>
