<div
    class="category-item tag-item"
    [ngClass]="sizeClass"
    [class.active]="selected"
    [class.editable]="editable"
    [class.interactable]="interactable"
    [class.locked]="locked"
    data-testid="tag-item"
    [class.draggable]="draggable"
>
    {{ tag.name }}
    @if (locked) {
        <mat-icon class="size-auto locked-icon"><i class="fal fa-lock"></i></mat-icon>
    }
    @if (editable) {
        <mat-icon class="edit-icon"><i class="fas fa-pen"></i></mat-icon>
    }
</div>
