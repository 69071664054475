import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

const itemsTypesNamesMap = {
    search: { plural: 'Searches', single: 'Search' },
    tag: { plural: 'Tags', single: 'Tag' },
};
@Component({
    selector: 'app-change-items-category',
    templateUrl: './change-items-category.component.html',
    styleUrls: ['./change-items-category.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ChangeItemsCategoryComponent implements OnInit {
    categoryControl: FormControl = new FormControl();
    itemsTypeName: string;
    categories: any[];
    items: any[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) private data: { categories: any; items: any[] }) {}

    ngOnInit() {
        this.items = this.data.items;
        this.categories = this.data.categories;
        this.setTitleTypeName();
    }

    private setTitleTypeName() {
        const itemsType = this.data.items[0].type;
        this.itemsTypeName =
            this.data.items.length > 1 ? itemsTypesNamesMap[itemsType].plural : itemsTypesNamesMap[itemsType].single;
    }
}
