import { credentials } from '../app/core/constants/credentials';

export const environment = {
    production: true,
    hmr: false,
    host:
        window['env'] && window['env']['HOST_URL']
            ? window['env']['HOST_URL']
            : 'http://127.0.0.1:7070/ms4/index.php/api/',
    saga_api_url:
        window['env'] && window['env']['SAGA_API_URL'] ? window['env']['SAGA_API_URL'] : 'http://127.0.0.1:7070/',
    sso_enabled: window['env'] && window['env']['SSO_ENABLED'] ? window['env']['SSO_ENABLED'] === 'true' : false,
    start_page: window['env'] && window['env']['START_PAGE'] ? window['env']['START_PAGE'] : 'channels',
    provider: window['env'] && window['env']['PROVIDER'] ? JSON.parse(window['env']['PROVIDER']) : {},
    login_title: window['env'] && window['env']['LOGIN_TITLE'] ? window['env']['LOGIN_TITLE'] : 'Def val from env',
    mode: window['env'] && window['env']['MODE'] ? window['env']['MODE'] : 'default',
    items_to_hide:
        window['env'] && window['env']['ITEMS_TO_HIDE']
            ? JSON.parse(window['env']['ITEMS_TO_HIDE'])
            : { create_channels: false, created_by: false, dashboard: false },
    host_name: window['env'] && window['env']['HOST_NAME'] ? window['env']['HOST_NAME'] : 'HOST_NAME',
    logout_redirect_url:
        window['env'] && window['env']['LOGOUT_REDIRECT_URL'] ? window['env']['LOGOUT_REDIRECT_URL'] : '/',
    maps_api_key: window['env'] && window['env']['MAPS_API_KEY'] ? window['env']['MAPS_API_KEY'] : '/',
    include_dummy_data: false,
    show_read_and_unread_posts: true,
    show_edit_category: true,
    show_edit_collection: true,
    show_edit_channel: true,
    show_create_account:
        window['env'] && window['env']['SHOW_CREATE_ACCOUNT'] ? window['env']['SHOW_CREATE_ACCOUNT'] === 'true' : false,
    sentry_dsn: credentials.sentry_dsn || window['env']['SENTRY_DSN'] || null,
    sentry_env: credentials.sentry_env || window['env']['SENTRY_ENV'] || null,
    default_support_email: 'support@marketscape.com',
    page_title: credentials.page_title || window['env']['PAGE_TITLE'] || null,
    custom_assets_id: credentials.custom_assets_id || window['env']['CUSTOM_ASSETS_ID'] || null,
    matomo_url: window['env']['MATOMO_URL'] || null,
    matomo_site_id: window['env']['MATOMO_SITE_ID'] || null,
    matomo_enabled: window['env']['MATOMO_ENABLED'] || 'false',
    zendesk_enabled: window['env']['ZENDESK_ENABLED'] || 'false',
    pendo_api_key: window['env']['PENDO_API_KEY'] || null,
};
