import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Post } from '@app/data/models';

@Component({
    selector: 'app-add-article-modal',
    template: '<app-add-article [dialogRef]="dialogRef" [data]="data"></app-add-article>',
    standalone: false,
})
export class AddArticleModalComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { post: Post; edit: boolean },
        public dialogRef: MatDialogRef<AddArticleModalComponent>,
    ) {}

    ngOnInit() {}
}
