import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { ChannelService } from '@app/data/services/channel.service';
import * as ChannelsListActions from './channels-list.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../root-store';
import { selectChannelsListLoadingState } from './channels-list.selectors';
import { LoadingState } from './channels-list.reducer';

@Injectable()
export class ChannelsListEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private channelService: ChannelService,
    ) {}

    getChannelsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChannelsListActions.getChannelsList),
            concatLatestFrom(() => this.store.select(selectChannelsListLoadingState)),
            filter(([, loadingState]) => loadingState !== LoadingState.LOADING),
            map(() => ChannelsListActions.loadChannelsList()),
        ),
    );

    loadChannelsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChannelsListActions.loadChannelsList),
            switchMap(() =>
                this.channelService.getChannels().pipe(
                    map((channelsList: any) =>
                        ChannelsListActions.loadChannelsListSuccess({ channelsList: channelsList }),
                    ),
                    catchError(() => of(ChannelsListActions.loadChannelsListSuccess({ channelsList: [] }))),
                ),
            ),
        ),
    );

    loadChannelsUnreadInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChannelsListActions.loadChannelsListSuccess, ChannelsListActions.loadChannelsUnreadInfo),
            switchMap(() =>
                this.channelService.getChannelsUnreadInfo().pipe(
                    map((data: any) => ChannelsListActions.loadChannelsUnreadInfoSuccess({ channelsUnreadInfo: data })),
                    catchError(() => of(ChannelsListActions.loadChannelsUnreadInfoSuccess({ channelsUnreadInfo: [] }))),
                ),
            ),
        ),
    );
}
