import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { AIPostScoreDetail, Post } from '@app/data/models';
import { PostEditAiScoreComponent } from '@app/shared/dialogs';
import { AppStateService, DialogNames, ModalsService } from '@app/core/services';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-post-ai-score',
    templateUrl: './post-ai-score.component.html',
    styleUrls: ['./post-ai-score.component.scss'],
    standalone: false,
})
export class PostAiScoreComponent implements OnInit {
    @Output() updateAIScore = new EventEmitter<{ postId: string; aiScore: AIPostScoreDetail }>();
    @Input() postData: Post;

    scoreWording$: Observable<any>;
    private appStateService = inject(AppStateService);
    private modalService = inject(ModalsService);

    ngOnInit(): void {
        this.scoreWording$ = this.appStateService.siloScoreWording$;
    }

    openAiDialog() {
        this.scoreWording$
            .pipe(
                take(1),
                switchMap((scoreWording) =>
                    this.modalService
                        .openDialog(DialogNames.PostEditAIScore, PostEditAiScoreComponent, {
                            scoreWording,
                            aiScore: this.postData.ai_score,
                            postId: this.postData.id,
                        })
                        .afterClosed()
                        .pipe(
                            filter((postData) => !!postData),
                            tap((postData) => {
                                this.updateAIScore.emit(postData);
                            }),
                        ),
                ),
            )
            .subscribe();
    }
}
