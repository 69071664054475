<div class="add_post minimized" (click)="includeInNewsletter($event)">
    <mat-checkbox
        [matMenuTriggerFor]="menu"
        class="watch_this btn_checkbox collapsed"
        [checked]="isSelected()"
        labelPosition="before"
    >
        {{ isSelected() ? 'Exclude' : 'Include' }}
    </mat-checkbox>
    <mat-menu #menu="matMenu" class="include-post-menu">
        <div class="header">Use <b>Shift+Click</b> to select a range of posts</div>
        <div class="do-not-show-again" (click)="onDoNotShowAgain()">Do not show again</div>
    </mat-menu>
</div>
