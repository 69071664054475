import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectActiveChannelId } from '../router';
import { ChannelsListState, LoadingState } from './channels-list.reducer';

const channelsListStateStateFeatureKey = 'channelsList';

export const selectchannelsListFeature = createFeatureSelector<ChannelsListState>(channelsListStateStateFeatureKey);

export const selectChannelsList = createSelector(
    selectchannelsListFeature,
    (state: ChannelsListState) => state.channelsList,
);

export const selectChannelsListLoadingState = createSelector(
    selectchannelsListFeature,
    (state: ChannelsListState) => state.loadingState,
);

export const selectChannelsListIsEmpty = createSelector(selectchannelsListFeature, (state: ChannelsListState) => {
    if (![LoadingState.LOADED, LoadingState.LOADED_UNREAD_INFO].includes(state.loadingState)) return false;

    const channelsCategoriesList = state.channelsList;
    if (!channelsCategoriesList.length) return true;
    return channelsCategoriesList.every(
        (categoryItem) =>
            !categoryItem.channels?.length &&
            categoryItem.collections?.every((collectionItem) => !collectionItem.channels?.length),
    );
});

export const selectActivatedChannelCollectionId = createSelector(
    selectchannelsListFeature,
    selectActiveChannelId,
    (state: ChannelsListState, channelId: string) => state.channelCollectionIds[channelId],
);

export const selectNextChannelId = (currentChannelId: string) =>
    createSelector(selectchannelsListFeature, (state: ChannelsListState) => {
        const channelIndex = state.channelIds.findIndex((channelId) => channelId === currentChannelId);
        if (channelIndex === -1) return null;
        if (state.channelIds[channelIndex - 1]) return state.channelIds[channelIndex - 1];
        if (state.channelIds[channelIndex + 1]) return state.channelIds[channelIndex + 1];
        return null;
    });
