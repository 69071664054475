/// <reference types="@types/googlemaps" />
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';

import { MetadataService, ChannelService } from '@app/data/services';
import { ConfirmationDialogComponent } from '@app/shared/dialogs';

@Component({
    selector: 'app-metadata-dialog',
    templateUrl: './metadata-dialog.component.html',
    styleUrls: ['./metadata-dialog.component.scss'],
    standalone: false,
})
export class MetadataDialogComponent implements OnInit {
    @ViewChild('map') private map;

    currentZoom = 13;
    currentLocation = { lat: -33.8688, lng: 151.2195 };
    postLocation = null;
    isSubmitting = false;
    staticAlertClosed = false;
    successMessage = '';
    failureMessage = '';
    alertVisibilityTime = 5000;

    constructor(
        private metadataService: MetadataService,
        private channelService: ChannelService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<MetadataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { post: any },
    ) {}

    ngOnInit() {
        this.channelService
            .getPostInfo(this.post.data.id)
            .pipe(
                tap((data) => {
                    if (data['data'].meta && data['data'].meta.length) {
                        this.postLocation = data['data'].meta.find((meta) => meta.type === 'location');
                    }

                    if (this.postLocation && this.postLocation.data.location.lat) {
                        this.currentZoom = parseFloat(this.postLocation.data.zoom);
                        this.currentLocation = {
                            lat: parseFloat(this.postLocation.data.location.lat),
                            lng: parseFloat(this.postLocation.data.location.lng),
                        };
                    }

                    this.initAutocomplete();
                }),
            )
            .subscribe();
    }

    close() {
        this.dialogRef.close();
    }

    initAutocomplete() {
        const map = new google.maps.Map(this.map.nativeElement, {
            center: this.currentLocation,
            zoom: this.currentZoom,
            mapTypeId: 'roadmap',
        });

        // Create the search box and link it to the UI element.
        const input = document.getElementById('pac-input') as HTMLInputElement;
        const button = document.getElementById('clear-location');
        const searchBox = new google.maps.places.SearchBox(input);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

        let locationMarker;
        if (this.postLocation) {
            locationMarker = new google.maps.Marker({
                position: this.currentLocation,
                map: map,
                title: 'Post location',
            });
        }

        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds());
        });

        map.addListener('zoom_changed', () => {
            this.currentZoom = map.getZoom();
        });

        let markers = [];
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach((marker) => {
                marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();
            places.forEach((place) => {
                if (!place.geometry) {
                    console.warn('Returned place contains no geometry');
                    return;
                }
                const icon = {
                    url: place.icon,
                    size: new google.maps.Size(71, 71),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(25, 25),
                };

                // Create a marker for each place.
                markers.push(
                    new google.maps.Marker({
                        map: map,
                        icon: icon,
                        title: place.name,
                        position: place.geometry.location,
                    }),
                );

                this.currentLocation = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });

        button.onclick = () => {
            this.clearLocation(markers, locationMarker);
            (<HTMLInputElement>document.getElementById('pac-input')).value = '';
        };
    }

    saveLocation() {
        const methodName = this.postLocation ? 'updateMetadata' : 'addMetadata';

        const metadata = {
            type: 'location',
            data: {
                location: {
                    lng: this.currentLocation.lng,
                    lat: this.currentLocation.lat,
                },
                zoom: this.currentZoom,
            },
            collection: this.post.type,
        };

        if (metadata.data.location.lng) {
            this.isSubmitting = true;
            this.metadataService[methodName](
                this.post.data.id,
                metadata,
                this.postLocation ? this.postLocation.id : null,
            )
                .pipe(
                    finalize(() => (this.isSubmitting = false)),
                    tap(() => {
                        setTimeout(() => {
                            this.staticAlertClosed = true;
                            this.successMessage = '';
                        }, this.alertVisibilityTime);
                        this.successMessage = 'Metadata was successfully saved';
                    }),
                    catchError((error) => {
                        setTimeout(() => {
                            this.staticAlertClosed = true;
                            this.failureMessage = '';
                        }, this.alertVisibilityTime);
                        this.failureMessage = 'Error saving metadata';
                        return of(error);
                    }),
                )
                .subscribe();
        }
    }

    clearLocation(markers, locationMarker) {
        if (this.postLocation) {
            this.dialog
                .open(ConfirmationDialogComponent, {
                    panelClass: 'modal-sm',
                    autoFocus: false,
                    restoreFocus: false,
                    data: {
                        header: 'Delete post location',
                        message: 'Are you sure you would like to delete post location?',
                        confirm: 'Delete',
                    },
                })
                .afterClosed()
                .pipe(
                    filter((result) => !!result),
                    tap(() => {
                        markers.forEach((marker) => {
                            marker.setMap(null);
                        });

                        if (locationMarker) {
                            locationMarker.setMap(null);
                            locationMarker = null;
                        }
                        markers = [];

                        this.currentLocation = {
                            lat: null,
                            lng: null,
                        };
                    }),
                    switchMap(() => this.metadataService.deleteMetadata(this.post.data.id, this.postLocation.id)),
                    tap(() => {
                        this.postLocation = null;
                    }),
                )
                .subscribe();
        }
    }

    get post() {
        return this.data.post;
    }
}
