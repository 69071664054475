import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ApiService, UtilsService } from '@app/core/services';
import { Silo, SiloItem } from '../models/silo.model';
import User, { UsersResponce } from '../models/user.model';
import { UserInfo } from '../models';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    static signUpEmail;
    currentUser$: Observable<User>;
    private currentUserSubject = new BehaviorSubject<User>(null);

    constructor(
        private apiService: ApiService,
        private router: Router,
        private utilsService: UtilsService,
    ) {
        this.currentUser$ = this.currentUserSubject.asObservable();
    }

    getSignupEmail(): string {
        return UserService.signUpEmail;
    }

    setSignUpEmail(email) {
        UserService.signUpEmail = email;
    }

    getUserInfo(): Observable<UserInfo> {
        return this.apiService.get('/me/', { withCredentials: true }).pipe(
            map((data) => {
                return this.extractUserDataDemo(data);
            }),
            catchError(this.handleError),
        );
    }

    getUsers(options, start = 0, rows = 10): Observable<UsersResponce> {
        let params = {
            start: start.toString(),
            rows: rows.toString(),
        };

        if (options) {
            params = {
                ...params,
                ...options,
            };
        }

        return this.apiService.get('/users', {
            params: params,
            withCredentials: true,
        }) as unknown as Observable<UsersResponce>;
    }

    getSilos(queryParams?: HttpParams) {
        return this.apiService.get('/me/silo', { withCredentials: true, params: queryParams }) as unknown as Observable<
            SiloItem[]
        >;
    }

    getAdminNotOrganisationSilos() {
        return this.getSilos(new HttpParams({ fromObject: { is_admin: true, in_organisation: false } }));
    }

    isSiloExists(): boolean {
        return Boolean(window.localStorage.getItem('ms4::is_silo'));
    }

    setUserSilo(siloId) {
        return this.apiService.post(`/me/silo/${siloId}`, {}, { withCredentials: true });
    }

    createUser(userData) {
        return this.apiService.post('/users', userData, { withCredentials: true });
    }

    updateUser(id, userData) {
        return this.apiService.patch(`/users/${id}`, userData, { withCredentials: true });
    }

    deleteUser(id) {
        return this.apiService.delete(`/users/${id}`, { withCredentials: true });
    }

    updateCurrentUser(userData) {
        return this.apiService.post('/me', userData, { withCredentials: true });
    }

    logout() {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        window.localStorage.removeItem('ms4::is_logged_in');
        return this.apiService.post('/logout', {}, { headers, withCredentials: true });
    }

    subscribeToChannel(userId, channelId) {
        return this.apiService.put(`/user/${userId}/subscriptions/channel/${channelId}`, {}, { withCredentials: true });
    }

    unsubscribeFromChannel(userId, channelId) {
        return this.apiService.delete(`/user/${userId}/subscriptions/channel/${channelId}`, { withCredentials: true });
    }

    purgeAuth() {
        window.localStorage.removeItem('ms4::is_logged_in');
        window.localStorage.removeItem('ms4::is_silo');
        this.utilsService.resetCookies();
    }

    signUp(email: string): Observable<unknown> {
        const headers = new HttpHeaders();

        const formData = new FormData();
        formData.append('email', email);

        return this.apiService.post('/signup/', formData, {
            headers: headers,
            withCredentials: true,
        });
    }

    // last thing to do
    changePassword(email: string, newPassword: string, re_password: string): Observable<unknown> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        //   headers.append('Content-Type', 'application/json; charset=UTF-8');
        const send = 'password=' + newPassword + '&email=' + email + '&e_password=' + re_password;
        return this.apiService.post('/changepassword', send, { headers: headers, withCredentials: true });
    }

    // check password
    receivedPassword(password: string): Observable<unknown> {
        const formData = new FormData();
        formData.append('password', password);

        return this.apiService.post('/recivedpassword', formData, {
            withCredentials: true,
        });
    }

    importUser(notinfile, userfile) {
        const headers = new HttpHeaders();
        const formData = new FormData();
        formData.append('notinfile', notinfile);
        formData.append('userfile', userfile);
        return this.apiService.post('/users/import', formData, {
            headers,
            withCredentials: true,
        });
    }

    setCurrentUser(user) {
        this.currentUserSubject.next(user);
    }

    private extractUserDataDemo(res) {
        if (!res) {
            this.router.navigate(['login']);
        } else {
            res.user['permissions-key'] = {
                tags: true,
                post_history: false,
                company_searches: false,
            };
            return res || {};
        }
    }

    private handleError(error: Record<string, string>) {
        const errMsg = error.message
            ? error.message
            : error.status
              ? `${error.status} - ${error.statusText}`
              : 'Server error';

        // if there is an Internet connection problem or user session has expired refresh the page
        if (errMsg.indexOf('Server error') > -1 || errMsg.indexOf('Unexpected end of JSON input') > -1) {
            // reset cookie
            this.utilsService.resetCookies();
            window.location.reload();
        }

        return throwError(errMsg);
    }
}
