import { createAction, props } from '@ngrx/store';

import { ChannelUnreadInfo, ChannelCategories } from '@app/data/models';

export enum ChannelsListActionTypes {
    GetChannelsList = '[Channels List] Get channels list',
    LoadChannelsList = '[Channels List] Load channels list',
    LoadChannelsListSuccess = '[Channels List] Load channels list success',
    LoadChannelsUnreadInfo = '[Channels List] Load channels unread info',
    LoadChannelsUnreadInfoSuccess = '[Channels List] Load channels unread info success',
    ResetChannelsList = '[Channels List] Reset channels list',
    UpdateChannelUnreadStatus = '[Channels List] Update channel unread status',
}

export const getChannelsList = createAction(ChannelsListActionTypes.GetChannelsList);
export const loadChannelsList = createAction(ChannelsListActionTypes.LoadChannelsList);
export const loadChannelsListSuccess = createAction(
    ChannelsListActionTypes.LoadChannelsListSuccess,
    props<{ channelsList: ChannelCategories[] }>(),
);
export const loadChannelsUnreadInfo = createAction(ChannelsListActionTypes.LoadChannelsUnreadInfo);
export const loadChannelsUnreadInfoSuccess = createAction(
    ChannelsListActionTypes.LoadChannelsUnreadInfoSuccess,
    props<{ channelsUnreadInfo: ChannelUnreadInfo[] }>(),
);

export const resetChannelsList = createAction(ChannelsListActionTypes.ResetChannelsList);

export const updateChannelUnreadStatus = createAction(
    ChannelsListActionTypes.UpdateChannelUnreadStatus,
    props<{ channelId: string; hasUnread: boolean }>(),
);
