import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { DateService } from '@app/core/services';
import { PostService } from '@app/data/services/post.service';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-post-action-buttons',
    templateUrl: './post-action-buttons.component.html',
    styleUrls: ['./post-action-buttons.component.scss'],
    standalone: false,
})
export class PostActionButtonsComponent implements OnInit, OnChanges {
    @Input() post;
    @Input() channel;
    @Input() curatorMode;
    @Input() hasAdminRights;
    @Input() isActiveSearch;
    @Input() isSelected: boolean;

    @Output() openAssignDialog = new EventEmitter<any>();
    @Output() openEvent = new EventEmitter<string>();
    @Output() deletePostEvent = new EventEmitter<any>();
    @Output() includeInNewsletter = new EventEmitter<any>();
    @Output() openCommentDialog = new EventEmitter<any>();
    @Output() showMetadataDialog = new EventEmitter<any>();
    @Output() addPostEvent = new EventEmitter<any>();
    @Output() editPostEvent = new EventEmitter<any>();

    form: FormGroup;
    showEditButton = false;
    metadataEnabled$: Observable<boolean>;

    private untilDestroy = untilDestroyed();

    constructor(
        public dateService: DateService,
        private formBuilder: FormBuilder,
        private postService: PostService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const isSelectedChanges = changes.isSelected;
        if (isSelectedChanges && this.form) {
            this.form.get('selected').setValue(isSelectedChanges.currentValue, { emitEvent: false });
        }
    }
    ngOnInit(): void {
        this.form = this.formBuilder.group({
            selected: this.post.data.is_selected,
        });

        this.form
            .get('selected')
            .valueChanges.pipe(
                this.untilDestroy(),
                tap((value: boolean) => {
                    this.includeInNewsletter.emit(this.post);
                }),
            )
            .subscribe();

        this.setShowEditButton();
    }

    openReaderModal(postId: string) {
        this.openEvent.emit(postId);
    }

    deletePost(post) {
        this.deletePostEvent.emit(post);
    }

    enabledChannelDistribution() {
        return this.postService.isAutoSelectionDisabled(this.channel);
    }

    showMetadata(post) {
        this.showMetadataDialog.emit(post);
    }

    addPost(post) {
        this.addPostEvent.emit(post);
    }

    editPost(post) {
        this.editPostEvent.emit(post);
    }

    private setShowEditButton() {
        this.showEditButton =
            !this.isActiveSearch &&
            this.hasAdminRights &&
            (this.post.type === 'news' ||
                this.post.type === 'social_media' ||
                this.post.type === 'forum' ||
                this.post.type === 'marketplace' ||
                this.post.type === 'whois' ||
                this.post.type === 'creditcard');
    }
}
