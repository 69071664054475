export const MatIconClassByType = {
    news: 'fal fa-globe',
    social_media: 'fad fa-users',
    marketplace: 'fal fa-shopping-cart',
    forum: 'fal fa-comments-alt',
    datadump: 'fal fa-file-invoice',
    creditcard: 'fal fa-credit-card',
    breach: 'fal fa-lock-alt',
    whois: 'fal fa-cloud',
    exposed_services: 'fal fa-server',
    darkweb: 'fal fa-spider-web',
    stealers_leaks: 'fas fa-unlock',
};

export const MatIconClassByTypeSolid = {
    news: 'fas fa-globe',
    social_media: 'fad fa-users',
    marketplace: 'fas fa-shopping-cart',
    forum: 'fas fa-comments-alt',
    datadump: 'fas fa-file-invoice',
    creditcard: 'fas fa-credit-card',
    breach: 'fas fa-lock-alt',
    whois: 'fas fa-cloud',
    exposed_services: 'fas fa-server',
    darkweb: 'fas fa-spider-web',
    stealers_leaks: 'fas fa-unlock',
};
