import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { map, switchMap, tap } from 'rxjs/operators';
import { AIAnalyticsService } from '../ai-analytics.service';
import { PromptsDTO, PromptVariables, ReportType } from '@app/data/models';
import { AIReportType, DEFAULT_CREDIT_RATE, DEFAULT_MAX_POSTS } from '../ai-analytics.interfaces';
import { tapResponse } from '@ngrx/operators';
import { AIApiService } from '@app/data/services';

export enum Statuses {
    initial = 'INITIAL',
    loading = 'LOADING',
    loaded = 'LOADED',
    failed = 'FAILED',
}

interface AIReportState {
    generateReportRequestStatus: Statuses;
    errorMessage: string;
    promptVariables: PromptVariables[];
    promptsRequestStatus: Statuses;
    reportTypes: AIReportType[];
}

const defaultState: AIReportState = {
    generateReportRequestStatus: Statuses.initial,
    errorMessage: '',
    promptVariables: null,
    promptsRequestStatus: Statuses.initial,
    reportTypes: [],
};

@Injectable()
export class AIEvaluationStore extends ComponentStore<AIReportState> {
    private aiService = inject(AIAnalyticsService);
    private aiApiService = inject(AIApiService);

    // Selectors
    readonly evaluationRequestLoading$ = this.select((state) => state.generateReportRequestStatus === Statuses.loading);
    readonly evaluationRequestLoaded$ = this.select((state) => state.generateReportRequestStatus === Statuses.loaded);
    readonly promptsRequestStatusLoaded$ = this.select((state) => state.promptsRequestStatus === Statuses.loaded);
    readonly promptsRequestStatusFailed$ = this.select((state) => state.promptsRequestStatus === Statuses.failed);
    readonly promptVariables$ = this.select((state) => state.promptVariables);
    readonly $reportTypes = this.selectSignal((state) => {
        const reportTypes = state.reportTypes;
        return {
            summary_report: reportTypes.find((rt) => rt.type === 'summary-report') || {
                max_posts: DEFAULT_MAX_POSTS,
                credit_rate: DEFAULT_CREDIT_RATE,
            },
            risk_evaluation: reportTypes.find((rt) => rt.type === 'risk-evaluation') || {
                max_posts: DEFAULT_MAX_POSTS,
                credit_rate: DEFAULT_CREDIT_RATE,
            },
        };
    });

    readonly vm$ = this.select(
        this.state$,
        this.evaluationRequestLoading$,
        this.evaluationRequestLoaded$,
        this.promptsRequestStatusLoaded$,
        this.promptsRequestStatusFailed$,
        this.promptVariables$,
        (
            state,
            evaluationRequestLoading,
            evaluationRequestLoaded,
            promptsRequestStatusLoaded,
            promptsRequestStatusFailed,
            promptVariables,
        ) => ({
            errorMessage: state.errorMessage,
            promptsRequestStatusLoaded,
            promptsRequestStatusFailed,
            evaluationRequestLoading,
            evaluationRequestLoaded,
            promptVariables,
        }),
    );

    // Updaters
    readonly resetErrorMessage = this.updater((state): AIReportState => ({ ...state, errorMessage: '' }));
    readonly setReportTypes = this.updater(
        (state, reportTypes: AIReportType[]): AIReportState => ({
            ...state,
            reportTypes,
        }),
    );

    // Effects
    readonly loadReportTypes = this.effect<void>(() =>
        this.aiApiService.getReportTypes().pipe(
            tapResponse({
                next: (reportTypes: AIReportType[]) => this.setReportTypes(reportTypes),
                error: (error) => console.error('Failed to load report types:', error),
            }),
        ),
    );

    readonly submitAIEvaluation = this.effect<{
        channelId: number;
        variables: object;
        num_posts: number;
    }>((evaluationParams$) =>
        evaluationParams$.pipe(
            tap(() => {
                this.patchState({ generateReportRequestStatus: Statuses.loading });
                this.resetErrorMessage();
            }),
            map((evaluationParams) => ({
                ...evaluationParams,
                variables: Object.keys(evaluationParams.variables)
                    .filter((key) => key !== 'num_posts')
                    .map((key) => this.aiService.setVariableFieldsForSave(evaluationParams.variables[key], key)),
                reportType: this.getReportType(evaluationParams.variables['report_type']?.value),
            })),
            switchMap((evaluationParams) =>
                this.aiService.submitAIEvaluation(evaluationParams).pipe(
                    tapResponse({
                        next: () => {
                            this.patchState({ generateReportRequestStatus: Statuses.loaded });
                        },
                        error: (err: { error: any }) => {
                            if (err.error?.message) this.patchState({ errorMessage: err.error?.message });
                            this.patchState({ generateReportRequestStatus: Statuses.initial });
                        },
                    }),
                ),
            ),
        ),
    );

    readonly getPrompts = this.effect<number>((channelId$) =>
        channelId$.pipe(
            tap(() => {
                this.patchState({ promptsRequestStatus: Statuses.loading });
            }),
            switchMap((channelId) =>
                this.aiService.getAIPrompts().pipe(
                    tapResponse({
                        next: (promptData: PromptsDTO[]) => {
                            const promptVariables = promptData.reduce(
                                (acc, prompt) => acc.concat(prompt.variables),
                                [],
                            );
                            const uniquePromptVariables = promptVariables.filter(
                                (value, idx, arr) => arr.findIndex((item) => item.key === value.key) === idx,
                            );
                            this.patchState({
                                promptsRequestStatus: Statuses.loaded,
                                promptVariables: uniquePromptVariables,
                            });
                        },
                        error: () => {
                            this.patchState({ promptsRequestStatus: Statuses.failed });
                        },
                    }),
                ),
            ),
        ),
    );

    private getReportType(reportType: string): ReportType {
        switch (reportType) {
            case 'summary report':
                return ReportType.summary_report;
            case 'digital risk report':
                return ReportType.digital_report;
            default:
                return ReportType.summary_report;
        }
    }

    constructor() {
        super(defaultState);
        this.loadReportTypes();
    }
}
