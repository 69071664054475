<div class="modal-component">
    <div class="modal-header">
        <div class="header_details">
            @if (tagCategory) {
                <div class="title" data-testid="tag-category-form-title">
                    Edit <b>{{ tagCategory.name }}</b> category
                </div>
            } @else {
                <div class="title" data-testid="tag-category-form-title">Add <b>Category</b></div>
            }
        </div>
    </div>
    <div class="modal-body">
        <ng-container *msLoadingSpinner="isLoading; color: 'accent'">
            @if (form) {
                <div class="info">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
                        <mat-form-field class="form-group app-mat-form-field" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="name"
                                maxlength="48"
                                placeholder="Name"
                                data-testid="tag-category-name-input"
                                required
                            />
                            @if (
                                (form.get('name').dirty || form.get('name').touched) &&
                                form.invalid &&
                                form.get('name').hasError('required')
                            ) {
                                <mat-error class="error-msg" data-testid="invalid-tag-category-name-error">
                                    Name is required
                                </mat-error>
                            }
                        </mat-form-field>

                        <div class="form_btns">
                            @if (tagCategory) {
                                <button
                                    class="delete btn"
                                    (click)="deleteTagCategory()"
                                    [disabled]="isDeleting"
                                    type="button"
                                    data-testid="delete-tag-category-button"
                                    mat-flat-button
                                >
                                    @if (isDeleting) {
                                        <span><mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner></span>
                                    } @else {
                                        <ng-container>
                                            <mat-icon class="size-auto"><i class="fal fa-trash-alt"></i></mat-icon>
                                            Delete
                                        </ng-container>
                                    }
                                </button>
                            }

                            <div class="right-side-group" [class.right]="tagCategory">
                                <button class="cancel-button btn" mat-flat-button type="button" (click)="close()">
                                    Cancel
                                </button>

                                <app-submit-button-tooltip
                                    [type]="form.invalid || isSubmitting ? 'button' : 'submit'"
                                    [form]="form"
                                    [isSubmitting]="isSubmitting"
                                    [buttonText]="tagCategory ? 'Save' : 'Add'"
                                    [nativeElement]="el.nativeElement"
                                    dataTestid="submit-tag-category-form-button"
                                ></app-submit-button-tooltip>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </ng-container>
    </div>
</div>
