import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { Component, OnInit, inject } from '@angular/core';
import { AppStateService } from '@app/core/services/app-state.service';
import { Observable } from 'rxjs';
import { ScoreWording } from '@app/data/models';
import { tap } from 'rxjs/operators';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';

@Component({
    selector: 'app-score-toggle',
    templateUrl: './score-toggle.component.html',
    styleUrls: ['./score-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ScoreToggleComponent,
            multi: true,
        },
    ],
    standalone: false,
})
export class ScoreToggleComponent implements OnInit, ControlValueAccessor {
    scoreWording$: Observable<ScoreWording>;
    scoreControl = new FormControl<number>(-1);
    private appStateService = inject(AppStateService);
    private untilDestroy = untilDestroyed();

    onChange: (value: number) => void = () => {};
    onTouched: () => void = () => {};

    ngOnInit(): void {
        this.scoreWording$ = this.appStateService.siloScoreWording$;
        this.scoreControl.valueChanges
            .pipe(
                this.untilDestroy(),
                tap((newValue) => {
                    this.onChange(newValue);
                    this.onTouched();
                }),
            )
            .subscribe();
    }

    writeValue(obj: any): void {
        this.scoreControl.setValue(obj, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.scoreControl.disable();
        } else {
            this.scoreControl.enable();
        }
    }
}
