<ng-container *msLoadingSpinner="gridLoading; color: 'accent'">
    <div class="table-wrapper">
        <form [formGroup]="form">
            <div class="flex-row flex search-wrapper">
                <div class="form-group">
                    <div class="checkbox-wrapper flex-row flex">
                        @if (showOnlySubscribedToggle) {
                            <mat-checkbox formControlName="onlySubscribed" (change)="changeGridParams()">
                                Only subscribed
                            </mat-checkbox>
                        }
                        @if (!activeUsersOnly) {
                            <mat-checkbox formControlName="onlyActive" (change)="changeGridParams()" color="primary">
                                Only active
                            </mat-checkbox>
                        }
                    </div>
                </div>

                <div class="form-group search-group">
                    <mat-icon class="search-icon size-auto" matPrefix=""><i class="far fa-search"></i></mat-icon>
                    <input
                        class="form-control"
                        type="text"
                        formControlName="search"
                        placeholder="Search"
                        (keyup.enter)="onClickEnterInSearch()"
                    />
                </div>
            </div>
        </form>

        <div class="mat-elevation-z8">
            <div *msLoadingSpinner="gridLoading; color: 'accent'">
                <div class="table-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <!-- First name Column -->
                        <ng-container matColumnDef="firstName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'First name' | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">{{ row.first_name }}</td>
                        </ng-container>

                        <!-- Last name Column -->
                        <ng-container matColumnDef="lastName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Last name' | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">{{ row.last_name }}</td>
                        </ng-container>

                        <!-- Email Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">{{ row.email }}</td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">{{ row.is_active ? 'Active' : 'Inactive' }}</td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="company">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Company' | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">{{ row.company }}</td>
                        </ng-container>

                        <!-- Role Column -->
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">
                                @if (disableMe && disableMe.id != row.id) {
                                    <mat-form-field class="role-field">
                                        <mat-select
                                            [(ngModel)]="row['role']"
                                            [value]="row['role']"
                                            (selectionChange)="onRoleChange(row)"
                                        >
                                            <mat-option value="curator">Curator</mat-option>
                                            <mat-option value="reader">Reader</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                }
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ actionName | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">
                                @if (disableMe && disableMe.id != row.id) {
                                    <div>
                                        <!--<svg-icon class="crown-icon" src="/assets/images/crown.svg" matTooltip="You are the owner of this tab"></svg-icon>-->
                                        <mat-slide-toggle
                                            [disabled]="
                                                (!showChannelSubscriptionStatus &&
                                                    !row['role'] &&
                                                    !actionNotDisabled) ||
                                                (showChannelSubscriptionStatus && !channelId)
                                            "
                                            [checked]="isAdded(row)"
                                            (toggleChange)="toggleUser(row)"
                                        >
                                        </mat-slide-toggle>
                                    </div>
                                }
                            </td>
                        </ng-container>

                        <!-- Admin Column -->
                        <ng-container matColumnDef="admin">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Silo admin' | uppercase }}</th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox [checked]="row.is_admin" (change)="onToggleIsAdmin($event, row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onSelect(row)"></tr>
                    </table>
                </div>
                <mat-paginator
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    [length]="usersCount"
                    (page)="changePage($event)"
                ></mat-paginator>
            </div>
        </div>
    </div>
</ng-container>
