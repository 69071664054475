import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStateService } from '@app/core/services/app-state.service';
import { ChannelService } from '@app/data/services/channel.service';
import { Observable, throwError } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';

@Component({
    selector: 'app-post-score',
    templateUrl: './post-score.component.html',
    styleUrls: ['./post-score.component.scss'],
    standalone: false,
})
export class PostScoreComponent implements OnInit {
    initialScoreValue: number | null;
    scoreControl = new FormControl<number>(null);
    failureMessage: string;
    isSubmitting = false;
    title = '';

    private dialogRef = inject(MatDialogRef<PostScoreComponent>);
    private postData = inject(MAT_DIALOG_DATA);
    private channelService = inject(ChannelService);
    private appStateService = inject(AppStateService);

    ngOnInit(): void {
        this.initialScoreValue = this.postData.post.data.score?.manual_score;
        this.scoreControl.setValue(this.initialScoreValue);
        this.setTitle();
    }

    save() {
        this.isSubmitting = true;
        this.getServerRequest()
            .pipe(
                map((resp: any) => {
                    if (resp.status === 'fail') throw new Error(resp.message);
                    return resp;
                }),
                catchError((err) => {
                    this.failureMessage = err.message || err.error;
                    return throwError(err);
                }),
                finalize(() => {
                    this.isSubmitting = true;
                }),
            )
            .subscribe(() => {
                this.dialogRef.close({ score: this.scoreControl.value });
            });
    }

    private setTitle() {
        this.appStateService.siloScoreWording$.subscribe((scoreWording) => {
            const title = `manual ${scoreWording.word}`;
            if (this.initialScoreValue) {
                this.title = `Edit ${title}`;
            } else {
                this.title = `Add ${title}`;
            }
        });
    }

    private getServerRequest(): Observable<any> {
        if (this.initialScoreValue === null)
            return this.channelService.addPostScore(this.postData.post.data.id, this.scoreControl.value);
        if (this.scoreControl.value === null)
            return this.channelService.deletePostScore(this.postData.post.data.id, this.initialScoreValue);
        return this.channelService.updatePostScore(this.postData.post.data.id, this.scoreControl.value);
    }
}
