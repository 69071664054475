@if (!post.isExpanded) {
    <div [ngClass]="post.type" class="image_wrapper">
        @if (post.data.source_icon) {
            <div>
                <img class="label_image" [src]="post.data.source_icon" />
            </div>
        } @else {
            <mat-icon><i [ngClass]="matIconClassByType[post.type]"></i></mat-icon>
        }
        @if (post.data.score?.dominant_score != null) {
            <app-post-score-icon class="score-icon" [postData]="post.data" />
        }
    </div>
} @else {
    @if (post.isExpanded) {
        <img mat-list-avatar class="arrow" src="/assets/images/arrow_down.png" />
    }
}
