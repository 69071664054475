<div class="post-wrapper" [ngClass]="post.type">
    @if (postDescription) {
        <div class="post_content">
            {{ postDescription }}
        </div>
    }
    <div class="row-content post_brief_actions card_actions_content">
        <div class="dates_actions">
            <div class="dates_actions_content">
                <div class="dates_action_left">
                    @switch (post.type) {
                        @case (postTypes.creditcard) {
                            <app-credit-card-body [post]="post"></app-credit-card-body>
                        }
                        @case (postTypes.databreach) {
                            <app-breach-body [post]="post"></app-breach-body>
                        }
                        @case (postTypes.leaks) {
                            <app-leaks-body [post]="post"></app-leaks-body>
                        }
                        @case (postTypes.darkweb) {
                            <app-darkweb-body [post]="post" [curatorMode]="!readerMode"></app-darkweb-body>
                        }
                        @case (postTypes.exposedServices) {
                            <app-shodan-body [post]="post"></app-shodan-body>
                        }
                        @case (postTypes.whois) {
                            <app-whois-body [post]="post"></app-whois-body>
                        }
                        @default {
                            <app-forum-body [post]="post" [curatorMode]="!readerMode"></app-forum-body>
                        }
                    }
                </div>
                <div class="dates_action_right">
                    @if ((post.data.analyst_comment || !readerMode) && !isActiveSearch) {
                        <div class="post_analyst_comment info">
                            <span class="label">Analyst comment: </span>
                            @if (post.data.analyst_comment) {
                                <span class="comment">{{ post.data.analyst_comment }}</span>
                            }
                            <span class="edit" (click)="openCommentDialog(post)">{{
                                post.data.analyst_comment ? 'Edit' : 'Add'
                            }}</span>
                        </div>
                    }
                    @if (searchParams?.provider !== 'external' && !readerMode) {
                        <app-post-manual-score
                            [post]="post"
                            (openScoreDialogEvent)="onOpenScoreDialog(post)"
                        ></app-post-manual-score>
                    }
                    @if (!isActiveSearch) {
                        <app-post-ai-score
                            [postData]="post.data"
                            (updateAIScore)="onUpdateAiScore($event)"
                        ></app-post-ai-score>
                    }
                </div>
            </div>

            <div class="post-tags-searches">
                @if (!readerMode) {
                    <div class="post_tags info">
                        @if (post.data?.searches?.length > 0) {
                            <div class="form-group selectedTags">
                                <span class="label">Search:</span>
                                <ul class="selected_tags_list">
                                    @for (search of post.data.searches; track search) {
                                        @if (search.name) {
                                            <li>
                                                <app-search-item
                                                    (click)="onEditSearch(search)"
                                                    [search]="search"
                                                    [searchType]="post.type"
                                                    [editable]="true"
                                                ></app-search-item>
                                            </li>
                                        }
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                }
                @if (!readerMode && (!isActiveSearch || post.provider === 'internal')) {
                    <div class="post_tags">
                        <div class="form-group selectedTags">
                            <span class="label">Tags:</span>
                            @if (post.tags && post.tags.length > 0) {
                                <ul class="selected_tags_list">
                                    @for (tag of post.tags; track tag) {
                                        <li>
                                            <app-tag-item [tag]="tag"></app-tag-item>
                                        </li>
                                    }
                                </ul>
                            }
                            @if (hasAdminRights) {
                                <span class="edit" (click)="openAssignDialog(post)">Edit</span>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>

    <app-post-action-buttons
        [post]="post"
        [channel]="channel"
        [curatorMode]="!readerMode"
        [hasAdminRights]="hasAdminRights"
        [isActiveSearch]="isActiveSearch"
        [isSelected]="post.data?.is_selected"
        (openAssignDialog)="openAssignDialog($event)"
        (openEvent)="onOpenInReader($event)"
        (deletePostEvent)="deletePost($event)"
        (includeInNewsletter)="onIncludeInNewsletter(post)"
        (openCommentDialog)="openCommentDialog($event)"
        (showMetadataDialog)="showMetadata($event)"
        (editPostEvent)="onEditPost($event)"
        (addPostEvent)="onAddPost($event)"
    ></app-post-action-buttons>
</div>
