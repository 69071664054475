export var credentials = {
    host: 'http://127.0.0.1:8000/ms4/index.php/api/',
    sso_enabled: false,
    saga_api_url: 'http://127.0.0.1:8000/ms4/index.php/api/',
    start_page: 'channels',
    provider: {},
    login_title: 'MarketScape',
    mode: 'default',
    items_to_hide: { create_channels: false, created_by: false, dashboard: false },
    include_dummy_data: false,
    show_read_and_unread_posts: true,
    show_edit_category: true,
    show_edit_collection: true,
    show_edit_channel: true,
    host_name: '',
    logout_redirect_url: '/',
    maps_api_key: 'AIzaSyDxnDdgusWsVWd99ZxCnO0eK7ekBbU640g',
    show_create_account: false,
    sentry_dsn: '',
    sentry_env: '',
    page_title: '',
    custom_assets_id: '',
    matomo_url: '//matomo.internal.staging.marketscape.com',
    matomo_site_id: '1',
    matomo_enabled: 'false',
    zendesk_enabled: 'false',
    pendo_api_key: 'fd8bd663-e8f8-4409-6692-dfef9082eb9d',
};
