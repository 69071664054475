import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export enum DialogNames {
    Confirmation,
    Channel,
    Collection,
    Category,
    DeleteSilo,
    SearchCategory,
    ShareTab,
    ExportChannel,
    Metadata,
    Chart,
    SearchPreview,
    Tag,
    Reader,
    Post,
    ChangeCategory,
    SearchFormSimple,
    SearchForm,
    PostTags,
    DashboardTabForm,
    PostScore,
    ScoreEvaluation,
    AIReport,
    PostEditAIScore,
    ChangeBalance,
    StartSubscription,
    StripePayment,
    ChangeSubscription,
    EditSubscription,
    ProviderSettings,
    AllowedQueryForm,
    Organisation,
    OrganisationSiloForm,
    OrganisationUserForm,
    OrganisationSettings,
}

const defaultDialogOptions = {
    panelClass: 'modal-sm',
    autoFocus: false,
    restoreFocus: false,
    data: {},
};

const DialogNameToOptions = {
    [DialogNames.Channel]: { panelClass: ['modal-lg', 'modal-h90'] },
    [DialogNames.ShareTab]: { panelClass: 'modal-lg' },
    [DialogNames.Metadata]: { panelClass: 'modal-lg' },
    [DialogNames.Chart]: { panelClass: ['modal-lg', 'modal-h90'] },
    [DialogNames.Reader]: { panelClass: 'modal-lg' },
    [DialogNames.Post]: { panelClass: 'modal-lg' },
    [DialogNames.PostTags]: { panelClass: 'modal-lg' },
    [DialogNames.SearchForm]: { panelClass: 'modal-lg' },
    [DialogNames.StripePayment]: { panelClass: 'modal-lg' },
    [DialogNames.ChangeSubscription]: { panelClass: 'modal-lg' },
    [DialogNames.StartSubscription]: { panelClass: 'modal-lg' },
    [DialogNames.Organisation]: { panelClass: ['modal-lg'] },
    [DialogNames.OrganisationSiloForm]: { panelClass: ['modal-lg'] },
    [DialogNames.OrganisationUserForm]: { panelClass: ['modal-lg'] },
    [DialogNames.DeleteSilo]: { panelClass: ['modal-sm', 'delete-silo-modal'] },
};

interface DialogOption {
    component: ComponentType<unknown>;
    options: MatDialogConfig<any>;
}

@Injectable({
    providedIn: 'root',
})
export class ModalsService {
    constructor(private dialog: MatDialog) {}

    openDialog(
        dialogName: DialogNames,
        component: ComponentType<unknown>,
        dialogData: any = {},
        dialogOptions?: MatDialogConfig<any>,
    ) {
        const dialogParams = this.prepareDialogParams(component, dialogName, dialogData, dialogOptions);
        return this.openDialogWithParams(dialogParams);
    }

    closeAll() {
        this.dialog.closeAll();
    }

    private prepareDialogParams(
        component: ComponentType<unknown>,
        dialogName: DialogNames,
        dialogData: any,
        dialogOptions: any = {},
    ): DialogOption {
        const modalSpecificOptions = DialogNameToOptions[dialogName] || {};
        const additionalOptions = { ...modalSpecificOptions, ...dialogOptions };
        const options: MatDialogConfig<any> = { ...defaultDialogOptions, ...additionalOptions };
        options.data = { ...dialogData };
        return { component, options };
    }

    private openDialogWithParams(dialogParams: DialogOption): MatDialogRef<any> {
        return this.dialog.open(dialogParams.component, dialogParams.options);
    }
}
