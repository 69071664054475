<div class="post-wrapper">
    @if (post.data.images && post.data.images?.length) {
        <app-product-images-slider class="app-product-images-slider" [postData]="post.data"></app-product-images-slider>
    }
    @if (!post.data.images?.length) {
        <img class="card_image" [src]="cardImg" />
    }
    <div class="dates_action_left">
        <div class="post_dates">
            @if (post.data.publish_date || post.data.published_at) {
                <div class="date">
                    <span class="label">Date of Origin: </span>
                    <span
                        >{{ _DateService.getDateDiff('' + (post.data.publish_date || post.data.published_at)) }}
                        &nbsp; / &nbsp;
                        {{
                            _DateService.setDateISO8601(post.data.publish_date || post.data.published_at)
                                | date: _DateService.getDateFormat()
                        }}</span
                    >
                </div>
            }
            @if (post.data.publish_date || post.data.published_at) {
                <div class="last_action">
                    <span class="label">Last action: </span>
                    <span
                        >{{ _DateService.getDateDiff('' + (post.data.publish_date || post.data.published_at)) }}
                        &nbsp; / &nbsp;
                        {{
                            _DateService.setDateISO8601(post.data.publish_date || post.data.published_at)
                                | date: _DateService.getDateFormat()
                        }}</span
                    >
                </div>
            }
        </div>
        @if (post.data.source) {
            <div class="post_source">
                <span class="label">Source: </span>
                <span>{{ post.data.source }}</span>
            </div>
        }
        @if (post.data.type) {
            <div class="post_source">
                <span class="label">Type: </span>
                <span>{{ post.data.type }}</span>
            </div>
        }
        @if (post.data.expM) {
            <div class="post_source">
                <span class="label">Exp: </span>
                <span>{{ post.data.expM }}/{{ post.data.expY }}</span>
            </div>
        }
        @if (post.data.name) {
            <div class="post_source">
                <span class="label">Name: </span>
                <span>{{ post.data.name }}</span>
            </div>
        }
        @if (post.data.bin) {
            <div class="post_source">
                <span class="label">Bin: </span>
                <span>{{ post.data.bin }}</span>
            </div>
        }
        @if (post.data.mark) {
            <div class="post_source">
                <span class="label">Mark: </span>
                <span>{{ post.data.mark }}</span>
            </div>
        }
        @if (post.data.city) {
            <div class="post_source">
                <span class="label">City: </span>
                <span>{{ post.data.city }}</span>
            </div>
        }
        @if (post.data.zip) {
            <div class="post_source">
                <span class="label">Zip: </span>
                <span>{{ post.data.zip }}</span>
            </div>
        }
    </div>
</div>
