import { Component, Input } from '@angular/core';

import { DateService } from '@app/core/services';

@Component({
    selector: 'app-forum-body',
    templateUrl: './forum-body.component.html',
    styleUrls: ['./forum-body.component.scss'],
    standalone: false,
})
export class ForumBodyComponent {
    @Input() post;
    @Input() curatorMode: boolean;

    constructor(public dateService: DateService) {}
}
