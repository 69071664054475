import { Component, Input, inject } from '@angular/core';

import { DateService } from '@app/core/services';

@Component({
    selector: 'app-darkweb-body',
    templateUrl: './darkweb-body.component.html',
    styleUrls: ['./darkweb-body.component.scss'],
    standalone: false,
})
export class DarkwebBodyComponent {
    @Input() post;
    @Input() curatorMode: boolean;
    public dateService = inject(DateService);
}
