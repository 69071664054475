import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { SpinnerWrapperComponent } from '../components/spinner-wrapper/spinner-wrapper.component';

interface MatSpinnerParameters {
    diameter?: number;
    color?: string;
    strokeWidth?: number;
}
@Directive({
    selector: '[msLoadingSpinner]',
    standalone: false,
})
export class MSLoadingSpinnerDirective {
    private spinnerWrapperComponent: ComponentRef<SpinnerWrapperComponent>;
    private loadingFactory: ComponentFactory<SpinnerWrapperComponent>;
    private isLoading: boolean;
    private matSpinnerParameters: MatSpinnerParameters = {};

    @Input() set msLoadingSpinner(isLoading: boolean) {
        this.viewContainer.clear();
        this.isLoading = isLoading;

        if (!isLoading) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.spinnerWrapperComponent = this.viewContainer.createComponent(this.loadingFactory);
            this.setMatSpinnerParameters();
        }
    }

    @Input() set msLoadingSpinnerDiameter(diameter: number) {
        if (this.isLoading) {
            this.spinnerWrapperComponent.instance.diameter = diameter;
        }
        this.matSpinnerParameters.diameter = diameter;
    }

    @Input() set msLoadingSpinnerStrokeWidth(strokeWidth: number) {
        if (this.isLoading) {
            this.spinnerWrapperComponent.instance.strokeWidth = strokeWidth;
        }
        this.matSpinnerParameters.strokeWidth = strokeWidth;
    }

    @Input() set msLoadingSpinnerColor(color: string) {
        if (this.isLoading) {
            this.spinnerWrapperComponent.instance.color = color;
        }
        this.matSpinnerParameters.color = color;
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {
        this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(SpinnerWrapperComponent);
    }

    private setMatSpinnerParameters() {
        for (const parameterName in this.matSpinnerParameters) {
            if (this.matSpinnerParameters[parameterName]) {
                this.spinnerWrapperComponent.instance[parameterName] = this.matSpinnerParameters[parameterName];
            }
        }
    }
}
